import { useCallback, useState } from 'react';

import CardsView from '../../../common/CardsView/CardsView';
import { Grid } from '@mui/material';
import Stats from './Stats/Stats';

const CP_APPOINTMENTS = () => {
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  const handleSelectedIndex = useCallback((index) => {
    setSelectedCardIndex(index);
  }, []);

  return (
    <>
      <Grid container display="flex" flexDirection="column" spacing={5}>
        <Grid item>
          <CardsView
            cardsData={[
              {
                id: 1,
                title: 'Branch Wise',
                image: '/Chat_Table-1.png'
              },
              {
                id: 2,
                title: 'Department Wise',
                image: '/Chat_Table-2.png'
              },
              {
                id: 3,
                title: 'Consultant Wise',
                image: '/Chat_Table-3.png'
              },
              {
                id: 4,
                title: 'Age/Gender Wise',
                image: '/Chat_Table-4.png'
              },
              {
                id: 5,
                title: 'Date Range',
                image: '/Chat_Table.png'
              },
            ]}
            handleSelectedIndex={handleSelectedIndex}
            selectedIndex={selectedCardIndex}
          />
        </Grid>
        {selectedCardIndex === 0 && (
          <Grid item container>
            <Stats
              title="Branch"
            />
          </Grid>
        )}
        {selectedCardIndex === 1 && (
          <Grid item container>
            <Stats
              title="Department"
            />
          </Grid>
        )}
        {selectedCardIndex === 2 && (
          <Grid item container>
            <Stats
              title="Consultant"
            />
          </Grid>
        )}
        {selectedCardIndex === 3 && (
          <Grid item container>
            <Stats
              title="Age/Gender"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CP_APPOINTMENTS;
