import {
  BRANCHES_LIST,
  DEPARTMENTS_LIST,
  DOCTORS_LIST,
  STATUS_LIST,
} from '../constants';

import { apiSlice } from './apiSlice';
import { buildUrlUsingParams } from '../utils/urlformat';
import { createEntityAdapter } from '@reduxjs/toolkit';

const filtersAdapter = createEntityAdapter();

const initialState = filtersAdapter.getInitialState();

export const filtersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBranches: builder.query({
      query: () => BRANCHES_LIST,
    }),
    getDepartments: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(DEPARTMENTS_LIST, payload);
      },
    }),
    getDoctors: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(DOCTORS_LIST, payload);
      },
    }),
    getStatuses: builder.query({
      query: () => STATUS_LIST,
    }),
  }),
});

export const {
  useGetBranchesQuery,
  useGetDepartmentsQuery,
  useGetDoctorsQuery,
  useGetStatusesQuery,
} = filtersApiSlice;
