import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../common/Loader/Loader';
import { authSelector } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

const RouteNavigator = () => {
  const { isLoading, isSuccess, isError, error, isAuthenticated, user } =
    useSelector(authSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && user) {
      navigate('/app/appointments', { replace: true });
    } else {
      navigate('/login', { replace: true });
    }
  }, []);

  return (
    <>
      <Loader />
    </>
  );
};

export default RouteNavigator;
