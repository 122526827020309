import CountCard from '../CountCard/CountCard';
import { Grid } from '@mui/material';
import React from 'react';

const CountsView = ({ data = [] }) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      {data.map((item, index) => (
        <Grid
          item
          key={index}
          xs={12}
          sm={6}
          md={3}
          sx={{
            my: (theme) => theme.spacing(1.5),
          }}
        >
          <CountCard
            title={item.title}
            count={item.count}
            color={item.color}
            textColor={item.textColor}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CountsView;
