import { memo, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Skeleton } from '@mui/material';
import { graphColors } from '../../../theme/colors';

const BarChart = memo(
  ({
    options = {},
    data = [],
    height = 350,
    showToolbar = true,
    enableZoom = true,
    isHorizontal = false,
    totalEnabled = true,
    dataLabelsEnabled = false,
    xAxisType = 'text',
    xKey = 'x',
    yKey = 'y',
    borderRadius = 2,
    chartColors = graphColors,
    onClick
  }) => {
    const chartSeries = useMemo(() => {
      return [
        {
          name: yKey,
          data: data.map((item) => item[yKey]),
        },
      ];
    }, [data, yKey]);

    const chartOptions = useMemo(() => {
      return {
        chart: {
          type: 'bar',
          height,
          toolbar: {
            show: showToolbar,
          },
          events: {
            dataPointSelection: function (event, chartContext, config) {
              const selectedBar = config.dataPointIndex;
              onClick(selectedBar)
            }
          },
          zoom: {
            enabled: enableZoom,
          },
          ...options,
        },
        plotOptions: {
          bar: {
            horizontal: isHorizontal,
            // borderRadius,
            dataLabels: {
              total: {
                enabled: totalEnabled,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                },
              },
            },
          },
          xaxis: {
            type: xAxisType,
            categories: data.map((item) => item[xKey]),
            tickPlacement: 'on'
          },
          fill: {
            opacity: 1,
          },
        },
        dataLabels: {
          enabled: dataLabelsEnabled,
        },
        xaxis: {
          categories: data.map((item) => item[xKey]),
          tickPlacement: 'on'
        },
        colors: chartColors,
      };
    }, [
      options,
      height,
      showToolbar,
      enableZoom,
      isHorizontal,
      totalEnabled,
      dataLabelsEnabled,
      xAxisType,
      data,
      xKey,
      borderRadius,
      chartColors,
    ]);

    const [display, setDisplay] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDisplay(true);
      }, 1);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    return (
      <div style={{ width: '100%' }}>
        {display ? (
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={height}
          />
        ) : (
          <Skeleton variant="rectangular" animation="wave" height={height} />
        )}
      </div>
    );
  },
);

BarChart.propTypes = {
  data: PropTypes.array.isRequired,
  xKey: PropTypes.string.isRequired,
  yKey: PropTypes.string.isRequired,
  options: PropTypes.object,
  height: PropTypes.number,
  showToolbar: PropTypes.bool,
  enableZoom: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  totalEnabled: PropTypes.bool,
  xAxisType: PropTypes.string,
  borderRadius: PropTypes.number,
};

export default BarChart;
