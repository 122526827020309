import { useCallback, useState } from 'react';

import Activity from './Activity/Activity';
import CardsView from '../../common/CardsView/CardsView';
import Details from './Details/Details';
import Forecast from './Forecast/Forecast';
import { Grid } from '@mui/material';
import Stats from './Stats/Stats';

const Doctors = () => {
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  const handleSelectedIndex = useCallback((index) => {
    setSelectedCardIndex(index);
  }, []);

  return (
    <>
      <Grid container display="flex" flexDirection="column" spacing={5}>
        <Grid item>
          <CardsView
            cardsData={[
              {
                id: 1,
                title: 'Stats',
                image: '/card1.png',
              },
              {
                id: 2,
                title: 'Activity',
                image: '/card2.png',
              },
              {
                id: 3,
                title: 'Details',
                image: '/card3.png',
              },
              {
                id: 4,
                title: 'Forecast',
                image: '/card1.png',
              },
            ]}
            handleSelectedIndex={handleSelectedIndex}
            selectedIndex={selectedCardIndex}
          />
        </Grid>
        {selectedCardIndex === 0 && (
          <Grid item container>
            <Stats />
          </Grid>
        )}
        {selectedCardIndex === 1 && (
          <Grid item container>
            <Activity />
          </Grid>
        )}
        {selectedCardIndex === 2 && (
          <Grid item container>
            <Details />
          </Grid>
        )}
        {selectedCardIndex === 3 && (
          <Grid item container>
            <Forecast />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Doctors;
