import FilterC from '../FilterC/FilterC';
import { setRevenueStatus } from '../../features/filters/filtersSlice';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

const data = [
    {
        id: 1,
        label: 'Settled',
        value: 'Settled'
    },
    {
        id: 2,
        label: 'Cancelled',
        value: 'Cancelled'
    },
]

const RevenueStatusFilter = ({
    selectedOption
}) => {
    const dispatch = useDispatch();

    const memoizedData = useMemo(() => {
        return data || [];
    }, [data]);

    const handleSelection = (option) => {
        dispatch(setRevenueStatus(option));
    }

    return (
        <FilterC
            heading="Status"
            selectTitle="Select Status"
            options={memoizedData}
            handleSelection={handleSelection}
            selectedOption={selectedOption}
        />
    )
}

export default RevenueStatusFilter;