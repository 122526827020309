import ContentCard from '../../../common/ContentCard/ContentCard';
import DataTable from '../../../common/DataTable/DataTable';
import DateRange from '../../../common/DateRange/DateRange';
import { Grid } from '@mui/material';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { useGetClaimDetailsQuery } from '../../../api/claimApiSlice';
import { useState } from 'react';

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const Details = () => {
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, 'years').format('DD-MM-YYYY'),
    endDate: moment().format('DD-MM-YYYY'),
  });
  // eslint-disable-next-line no-unused-vars
  const { data, error, isLoading, isFetching } = useGetClaimDetailsQuery(dateRange)

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  return (
    <Grid container display="flex" flexDirection="column" spacing={5}>
      <Grid item container justifyContent="center">
        <DateRange
          onDateRangeChange={handleDateRangeChange}
        />
      </Grid>
      <Grid item container>
        <StyledContentCard>
          <Grid item xs={12} md={12}>
            <DataTable
              gridTitle="Claim Details"
              columnDefs={[
                {
                  headerName: 'Claim Id',
                  field: 'claimId',
                  filter: true,
                },
                {
                  headerName: 'Invoice No',
                  field: 'invoiceNo',
                  filter: true,
                },
                {
                  headerName: 'Branch Name',
                  field: 'branchName',
                  filter: true,
                },
                {
                  headerName: 'Department Name',
                  field: 'deptName',
                  filter: true,
                },
                {
                  headerName: 'Service Name',
                  field: 'serviceName',
                  filter: true,
                },
                { headerName: 'Claim Amount', field: 'claimAmount', filter: true, },
                { headerName: 'Rejected Amount', field: 'rejectAmount', filter: true, },
                { headerName: 'Approved Amount', field: 'approvedAmount', filter: true, },
                { headerName: 'Denial Code', field: 'denialCode', filter: true, },
                { headerName: 'Invoice Claim Status', field: 'invoiceClaimStatus', filter: true, },
              ]}
              data={data && data.length > 0 ? data : []}
              pageSize={15}
              height={500}
              isLoading={isFetching}
            />
          </Grid>
        </StyledContentCard>
      </Grid>
    </Grid>
  );
};

export default Details;
