import FilterC from '../FilterC/FilterC';
import { setStatus } from '../../features/filters/filtersSlice';
import { useDispatch } from 'react-redux';
import { useGetStatusesQuery } from '../../api/filterApiSlice';
import { useMemo } from 'react';

const StatusFilter = ({
    selectedOption
}) => {
    const dispatch = useDispatch();

    const {
        data,
    } = useGetStatusesQuery();

    const memoizedData = useMemo(() => {
        return data || [];
    }, [data]);

    const handleSelection = (option) => {
        dispatch(setStatus(option));
    }

    return (
        <FilterC
            heading="Status"
            selectTitle="Select Status"
            options={memoizedData}
            handleSelection={handleSelection}
            selectedOption={selectedOption}
        />
    )
}

export default StatusFilter;