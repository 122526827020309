import { filtersSelector, setConsultant } from '../../features/filters/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';

import FilterC from '../FilterC/FilterC';
import { trimSpaces } from '../../utils';
import { useGetDoctorsQuery } from '../../api/filterApiSlice';
import { useMemo } from 'react';

const ConsultantsFilter = ({
    selectedOption
}) => {
    const dispatch = useDispatch();
    const { selectedBranch, selectedDepartment } = useSelector(filtersSelector);

    const { data, isLoading, isError } = useGetDoctorsQuery({
        branchName: selectedBranch ? trimSpaces(selectedBranch.label) : '',
        departmentName: selectedDepartment ? trimSpaces(selectedDepartment.label) : '',
    });

    const memoizedData = useMemo(() => {
        return data || [];
    }, [data]);

    const handleSelection = (option) => {
        dispatch(setConsultant(option));
    };

    return (
        <FilterC
            heading="Consultant"
            selectTitle="Select Consultant"
            options={memoizedData}
            handleSelection={handleSelection}
            isLoading={isLoading}
            isError={isError}
            selectedOption={selectedOption}
        />
    );
};

export default ConsultantsFilter;
