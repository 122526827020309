import { Grid, Skeleton, Typography } from '@mui/material';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { defaultEndDate, defaultStartDate } from '../../../utils/date-time';
import { filtersSelector, resetIsFilterApplied } from '../../../features/filters/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetPatientActivityQuery, useLazyGetPatientDetailsQuery } from '../../../api/patientsApiSlice';

import ContentCard from '../../../common/ContentCard/ContentCard';
import DataTable from '../../../common/DataTable/DataTable';
import PatientsFilter from '../PatientsFilter/PatientsFilter';
import { dateFilterParam } from '../../../utils';
import { formatPieChartData } from '../../../utils';
import { styled } from '@mui/material/styles';
import { trimSpaces } from '../../../utils';
import useSelectedFiltersOptions from '../../../hooks/useSelectedFiltersOptions';

const LineChart = lazy(() => import('../../../common/Charts/LineChart/LineChart'));
const BarChart = lazy(() => import('../../../common/Charts/BarChart/BarChart'));
const PieChart = lazy(() => import('../../../common/Charts/PieChart/PieChart'));
const TreeMap = lazy(() => import('../../../common/Charts/TreeMap/TreeMap'));
const NivoPie = lazy(() => import('../../../common/Charts/PieChart/NivoPie'));


const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const StyledInnerContentCard = styled(ContentCard)({
  borderRadius: '20px',
  margin: '15px 0px',
});

const Heading = styled(Typography)({
  fontWeight: 500,
  fontSize: 25,
  color: '#000000',
});

const Activity = () => {
  const dispatch = useDispatch();
  const {
    selectedBranch,
    selectedDepartment,
    selectedConsultant,
    selectedPatientType,
    selectedStartDate,
    selectedEndDate
  } = useSelector(filtersSelector);

  const options = useMemo(() => {
    const {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    } = {
      branchName: selectedBranch ? trimSpaces(selectedBranch.label) : '',
      departmentName: selectedDepartment ? trimSpaces(selectedDepartment.label) : '',
      consultantName: selectedConsultant ? trimSpaces(selectedConsultant.label) : '',
      status: selectedPatientType ? trimSpaces(selectedPatientType.label) : '',
      startDate: selectedStartDate.format('DD-MM-YYYY'),
      endDate: selectedEndDate.format('DD-MM-YYYY'),
    };

    return {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    };
  }, [selectedBranch, selectedDepartment, selectedConsultant, selectedPatientType, selectedStartDate, selectedEndDate]);

  const [showDepartmentDetails, setShowDepartmentDetails] = useState(false)
  const [showDetailsByInsurance, setShowDetailsByInsurance] = useState(false)
  const [showDetailsByPaymentType, setShowDetailsByPaymentType] = useState(false)

  /**
   * TODO: later handle error and isLoading..
   */
  const [getPatientActivity, { data }] = useLazyGetPatientActivityQuery();

  const [
    getPatientDetails,
    { data: patientDetails,
      error: patientDetailsError,
      isLoading: patientDetailsLoading,
      isFetching: patientDetailsFetching
    },
  ] = useLazyGetPatientDetailsQuery();

  const optionsFromHook = useSelectedFiltersOptions(selectedBranch,
    selectedDepartment,
    selectedConsultant,
    selectedPatientType,
    selectedStartDate,
    selectedEndDate);

  useEffect(() => {
    getPatientActivity(optionsFromHook);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterApplied = () => {
    getPatientActivity(optionsFromHook);
    dispatch(resetIsFilterApplied());
  };

  const onResetFilter = () => {
    getPatientActivity({
      startDate: defaultStartDate.format('DD-MM-YYYY'),
      endDate: defaultEndDate.format('DD-MM-YYYY'),
    });
  };

  const handleDepartmentBarClick = (selectedBar) => {
    setShowDepartmentDetails(true);
    const selectedDepartmentName = data?.patientsDeptActivities && data?.patientsDeptActivities[selectedBar]?.['deptName'];
    getPatientDetails({
      ...options,
      departmentName: selectedDepartmentName,
    });
  }

  const handlePatientsWithInsuranceClick = (selectedSlice) => {
    setShowDetailsByInsurance(true);
    const selectedStatusName = data?.patientsDeptActivities
      && data?.patientsDeptActivities
      [selectedSlice]?.['Status'];
    getPatientDetails({
      ...options,
      status: selectedStatusName,
    });
  }

  const handlePatientsByPaymentTypeClick = (selectedSlice) => {
    setShowDetailsByPaymentType(true);
    getPatientDetails({
      ...options,
      status: selectedSlice,
    });
  }

  return (
    <StyledContentCard>
      <Grid item xs={12} md={10}>
        {/* Branch Wise Patients */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center"
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Branch Wise Patients</Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense
                fallback={
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={400}
                  />
                }
              >
                <LineChart
                  data={data?.patientsBranchWiseActivity?.branchHashMapList || []}
                  xKey="x"
                  yKeys={
                    data?.patientsBranchWiseActivity?.branches || []
                  }
                  height={400}
                  curveType="smooth"
                  strokeWidth={2}
                  markerSize={6}
                  gridLineColor="#ccc"
                />
              </Suspense>
            </Grid>
          </StyledInnerContentCard>
        </Grid>

        {/* Department Wise Patients */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center"
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Department Wise Patients</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={400}
                />
              }>
                <BarChart
                  data={
                    data?.patientsDeptActivities || []
                  }
                  isHorizontal={false}
                  height={400}
                  xKey="deptName"
                  yKey="totalPatients"
                  onClick={(selectedBar) => handleDepartmentBarClick(selectedBar)}
                />
              </Suspense>
            </Grid>
            {
              showDepartmentDetails &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="Patients Details"
                  columnDefs={[
                    {
                      headerName: 'Patient Name',
                      field: 'patientName',
                      filter: true,
                    },
                    {
                      headerName: 'Nationality',
                      field: 'nationality',
                      filter: true,
                    },
                    {
                      headerName: 'Gender',
                      field: 'gender',
                      filter: true,
                    },
                    {
                      headerName: 'DOB',
                      field: 'dob',
                      filter: 'agDateColumnFilter',
                      filterParams: dateFilterParam,
                    },
                    {
                      headerName: 'Marital Status',
                      field: 'martialStatus',
                      filter: true,
                    },
                    {
                      headerName: 'Sponsor Name',
                      field: 'sponsorName',
                      filter: true,
                    },
                  ]}
                  data={patientDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={patientDetailsLoading || patientDetailsFetching}
                  origin={'appointments_activity'}
                  handleClose={() => {
                    setShowDepartmentDetails(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>

        {/* Consultant Wise Patients */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center"
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Consultant Wise Patients</Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense
                fallback={
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={400}
                  />
                }
              >
                <TreeMap
                  data={
                    data?.patientsConsultantActivities || []
                  }
                  height={'400px'}
                  xKey="consultantName"
                  yKey="totalPatients"
                  onClick={(selectedBar) =>
                    console.log("selectedBar ::", selectedBar)
                  }
                />
              </Suspense>
            </Grid>
          </StyledInnerContentCard>
        </Grid>

        {/* Patients With Insurance Providers */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center"
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Patients With Insurance Providers</Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={400}
                />
              }>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <PieChart
                    data={data?.patientsWithSponsorActivities || []}
                    height={400}
                    xKey="sponsorName"
                    yKey="totalPatients"
                    showLegend={false}
                    onClick={(selectedSlice) => handlePatientsWithInsuranceClick(selectedSlice)}
                  />
                </div>
              </Suspense>
            </Grid>
            {
              showDetailsByInsurance &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="Patients Details"
                  columnDefs={[
                    {
                      headerName: 'Patient Name',
                      field: 'patientName',
                      filter: true,
                    },
                    {
                      headerName: 'Nationality',
                      field: 'nationality',
                      filter: true,
                    },
                    {
                      headerName: 'Gender',
                      field: 'gender',
                      filter: true,
                    },
                    {
                      headerName: 'DOB',
                      field: 'dob',
                      filter: 'agDateColumnFilter',
                      filterParams: dateFilterParam,
                    },
                    {
                      headerName: 'Marital Status',
                      field: 'martialStatus',
                      filter: true,
                    },
                    {
                      headerName: 'Sponsor Name',
                      field: 'sponsorName',
                      filter: true,
                    },
                  ]}
                  data={patientDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={patientDetailsLoading || patientDetailsFetching}
                  origin={'appointments_activity'}
                  handleClose={() => {
                    setShowDetailsByInsurance(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>

        {/* Patients By Insurance Providers */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center"
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Patients By Insurance Providers</Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={400}
                />
              }>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <NivoPie
                    data={formatPieChartData(
                      data?.patientsBySponsorActivities || [],
                      'paymentType',
                      'patientsByPaymentType',
                    )}
                    height={400}
                    xKey="paymentType"
                    yKey="patientsByPaymentType"
                    onClick={(selectedSlice) => handlePatientsByPaymentTypeClick(selectedSlice)}
                  />
                </div>
              </Suspense>
            </Grid>
            {
              showDetailsByPaymentType &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="Patients Details"
                  columnDefs={[
                    {
                      headerName: 'Patient Name',
                      field: 'patientName',
                      filter: true,
                    },
                    {
                      headerName: 'Nationality',
                      field: 'nationality',
                      filter: true,
                    },
                    {
                      headerName: 'Gender',
                      field: 'gender',
                      filter: true,
                    },
                    {
                      headerName: 'DOB',
                      field: 'dob',
                      filter: 'agDateColumnFilter',
                      filterParams: dateFilterParam,
                    },
                    {
                      headerName: 'Marital Status',
                      field: 'martialStatus',
                      filter: true,
                    },
                    {
                      headerName: 'Sponsor Name',
                      field: 'sponsorName',
                      filter: true,
                    },
                  ]}
                  data={patientDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={patientDetailsLoading || patientDetailsFetching}
                  origin={'appointments_activity'}
                  handleClose={() => {
                    setShowDetailsByPaymentType(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
      >
        <PatientsFilter
          onFilterApplied={onFilterApplied}
          onResetFilter={onResetFilter}
        />
      </Grid>
    </StyledContentCard>
  );
};

export default Activity;
