const colors = {
  primary: {
    100: '#dae9ff',
    200: '#b4d3ff',
    300: '#8fbeff',
    400: '#69a8ff',
    500: '#4492ff',
    600: '#3675cc',
    700: '#295899',
    800: '#1b3a66',
    900: '#0e1d33',
  },
  secondary: {
    100: '#fff0cc',
    200: '#ffe099',
    300: '#ffd166',
    400: '#ffc133',
    500: '#ffb200',
    600: '#cc8e00',
    700: '#996b00',
    800: '#664700',
    900: '#332400',
  },
  error: {
    100: '#ffdadd',
    200: '#ffb6bc',
    300: '#ff919a',
    400: '#ff6d79',
    500: '#ff4857',
    600: '#cc3a46',
    700: '#992b34',
    800: '#661d23',
    900: '#330e11',
  },
  success: {
    100: '#cfe6e3',
    200: '#9fcdc7',
    300: '#70b5aa',
    400: '#409c8e',
    500: '#108372',
    600: '#0d695b',
    700: '#0a4f44',
    800: '#06342e',
    900: '#031a17',
  },
  warning: {
    100: '#ffe0d3',
    200: '#ffc1a8',
    300: '#ffa27c',
    400: '#ff8351',
    500: '#ff6425',
    600: '#cc501e',
    700: '#993c16',
    800: '#66280f',
    900: '#331407',
  },
  info: {
    100: '#d9e8ff',
    200: '#b3d1ff',
    300: '#8dbbff',
    400: '#67a4ff',
    500: '#408dff',
    600: '#3470cc',
    700: '#275399',
    800: '#1a3666',
    900: '#0d1933',
  },
  white: {
    100: '#ffffff',
    200: '#ffffff',
    300: '#ffffff',
    400: '#ffffff',
    500: '#ffffff',
    600: '#cccccc',
    700: '#999999',
    800: '#666666',
    900: '#333333',
  },
  black: {
    100: '#000000',
    200: '#000000',
    300: '#000000',
    400: '#000000',
    500: '#000000',
    600: '#000000',
    700: '#000000',
  },
  gray: {
    100: '#f2f2f2',
    200: '#e6e6e6',
    300: '#d9d9d9',
    400: '#cccccc',
    500: '#bfbfbf',
    600: '#999999',
    700: '#737373',
    800: '#4d4d4d',
    900: '#262626',
  },
};

const graphColors = [
  '#4492ff',
  '#FF4857',
  '#FFB200',
  '#108372',
  '#ff6425',
  '#008FFB',
  '#FF4560',
  '#775DD0',
];

export { colors, graphColors };
