import { setStatus, setVisitType } from '../../features/filters/filtersSlice';

import FilterC from '../FilterC/FilterC';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

const data = [
    {
        id: 1,
        label: 'First Visit',
        value: 'First Visit'
    },
    {
        id: 2,
        label: 'Followup Visit',
        value: 'Followup Visit'
    },
    {
        id: 3,
        label: 'Revisit',
        value: 'Revisit'
    },
]

const VisitTypeFilter = ({
    selectedOption
}) => {
    const dispatch = useDispatch();

    const memoizedData = useMemo(() => {
        return data || [];
    }, [data]);

    const handleSelection = (option) => {
        dispatch(setVisitType(option));
    }

    return (
        <FilterC
            heading="Visit Type"
            selectTitle="Select Visit Type"
            options={memoizedData}
            handleSelection={handleSelection}
            selectedOption={selectedOption}
        />
    )
}

export default VisitTypeFilter;