import ContentCard from '../../../common/ContentCard/ContentCard';
import DataTable from '../../../common/DataTable/DataTable';
import DateRange from '../../../common/DateRange/DateRange';
import { Grid } from '@mui/material';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { useGetRevenueDetailsQuery } from '../../../api/revenueApiSlice';
import { useState } from 'react';

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const Details = () => {
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, 'years').format('DD-MM-YYYY'),
    endDate: moment().format('DD-MM-YYYY'),
  });
  // eslint-disable-next-line no-unused-vars
  const { data, error, isLoading, isFetching } = useGetRevenueDetailsQuery(dateRange);

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  return (
    <Grid container display="flex" flexDirection="column" spacing={5}>
      <Grid item container justifyContent="center">
        <DateRange
          onDateRangeChange={handleDateRangeChange}
        />
      </Grid>
      <Grid item container>
        <StyledContentCard>
          <Grid item xs={12} md={12}>
            <DataTable
              gridTitle="Revenue Details"
              columnDefs={[
                {
                  headerName: 'Invoice Id',
                  field: 'masterInvoiceId',
                  filter: true,
                },
                // {
                //   headerName: 'Booking Date',
                //   field: 'bookingDate',
                //   filter: 'agDateColumnFilter',
                //   filterParams: dateFilterParam,
                // },
                {
                  headerName: 'Branch Name',
                  field: 'branchName',
                  filter: true,
                },
                {
                  headerName: 'Department Name',
                  field: 'deptName',
                  filter: true,
                },
                {
                  headerName: 'Service Name',
                  field: 'serviceName',
                  filter: true,
                },
                {
                  headerName: 'Sponsor',
                  field: 'sponsorName',
                  filter: true,
                },
                { headerName: 'Invoice Amount', field: 'invoiceAmount', filter: true, },
                { headerName: 'Discount', field: 'discount', filter: true, },
                { headerName: 'Net Amount', field: 'netAmount', filter: true, },
                { headerName: 'Invoice Status', field: 'invoiceStatus', filter: true, },
                { headerName: 'Employee Name', field: 'employeeName', filter: true, },
              ]}
              data={data && data.length > 0 ? data : []}
              pageSize={15}
              height={500}
              isLoading={isFetching}
            />
          </Grid>
        </StyledContentCard>
      </Grid>
    </Grid>
  );
};

export default Details;
