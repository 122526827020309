import { memo, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Skeleton } from '@mui/material';
import { graphColors } from '../../../theme/colors';

const StackedColumn = memo(
  ({
    options = {},
    series = [],
    height = 350,
    isStacked = true,
    showToolbar = true,
    enableZoom = true,
    isHorizontal = false,
    totalEnabled = true,
    xAxisType = 'text',
    categories = [],
    borderRadius = 10,
  }) => {
    const chartSeries = useMemo(() => {
      return series;
    }, [series]);

    const chartOptions = useMemo(() => {
      return {
        chart: {
          type: 'bar',
          height,
          stacked: isStacked,
          toolbar: {
            show: showToolbar,
          },
          zoom: {
            enabled: enableZoom,
          },
          ...options,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: isHorizontal,
            borderRadius,
            dataLabels: {
              total: {
                enabled: totalEnabled,
                style: {
                  fontSize: '13px',
                  fontWeight: 600,
                },
              },
            },
          },
          xaxis: {
            type: xAxisType,
            categories,
          },
          fill: {
            opacity: 1,
          },
        },
        colors: graphColors,
      };
    }, [
      options,
      height,
      isStacked,
      showToolbar,
      enableZoom,
      isHorizontal,
      totalEnabled,
      xAxisType,
      categories,
      borderRadius,
    ]);

    const [display, setDisplay] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDisplay(true);
      }, 1);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    return (
      <div style={{ width: '100%' }}>
        {display ? (
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={height}
          />
        ) : (
          <Skeleton variant="rectangular" animation="wave" height={height} />
        )}
      </div>
    );
  },
);

StackedColumn.propTypes = {
  options: PropTypes.object,
  series: PropTypes.array.isRequired,
  height: PropTypes.number,
  isStacked: PropTypes.bool,
  showToolbar: PropTypes.bool,
  enableZoom: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  totalEnabled: PropTypes.bool,
  xAxisType: PropTypes.string,
  categories: PropTypes.array,
  borderRadius: PropTypes.number,
};

export default StackedColumn;
