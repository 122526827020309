import { Button, Grid, Stack, Typography, styled } from '@mui/material';
import React, { useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ContentCard from '../ContentCard/ContentCard';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const StyledDatePicker = styled(DatePicker)(({ styles }) => ({
  borderRadius: 10,
  '& .MuiOutlinedInput-root': {
    height: 42,
  },
}));

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const DateFilter = () => {
  const [startDate, setStartDate] = useState(dayjs());

  const [endDate, setEndDate] = useState(dayjs());

  return (
    // <StyledContentCard>
    <Stack
      direction="row"
      spacing={2}
      sx={{
        margin: '1rem',
        backgroundColor: '#F0F0F0',
        padding: '1rem',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px #DADADA',
      }}
    >
      <Grid
        display="flex"
        flexDirection="row"
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: '600',
              color: '#000000',
              fontSize: '1.2rem',
              mx: '0.5rem',
            }}
          >
            From:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledDatePicker
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
          </LocalizationProvider>
          <Typography
            variant="h6"
            sx={{
              fontWeight: '600',
              color: '#000000',
              fontSize: '1.2rem',
              mx: '0.5rem',
            }}
          >
            To:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledDatePicker
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            sx={{
              marginLeft: '1rem',
              backgroundColor: '#007FFF',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#0062CC',
              },
            }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Stack>
    // </StyledContentCard>
  );
};

export default DateFilter;
