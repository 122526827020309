import { CLAIMS_ACTIVITY, CLAIMS_DETAILS, CLAIMS_STATS } from '../constants';

import { apiSlice } from './apiSlice';
import { buildUrlUsingParams } from '../utils/urlformat';
import { createEntityAdapter } from '@reduxjs/toolkit';

const claimAdapter = createEntityAdapter();

const initialState = claimAdapter.getInitialState();

export const claimApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClaimStats: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(CLAIMS_STATS, payload);
      },
    }),
    getClaimActivity: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(CLAIMS_ACTIVITY, payload);
      },
    }),
    getClaimDetails: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(CLAIMS_DETAILS, payload);
      },
    }),
  }),
});

export const {
  useGetClaimStatsQuery,
  useGetClaimActivityQuery,
  useLazyGetClaimStatsQuery,
  useLazyGetClaimActivityQuery,
  useLazyGetClaimDetailsQuery,
  useGetClaimDetailsQuery,
} = claimApiSlice;
