import { CheckRenderer, dateFilterParam, extractObjectFromArray } from '../../../utils';
import { Grid, Typography } from '@mui/material';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { TOTAL_CLAIMED, TOTAL_RECONCILED, TOTAL_REJECTED } from '../../../constants';
import { defaultEndDate, defaultStartDate, getStartAndEndDates } from '../../../utils/date-time';
import { filtersSelector, resetIsFilterApplied } from '../../../features/filters/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetClaimDetailsQuery, useLazyGetClaimStatsQuery } from '../../../api/claimApiSlice';

import ClaimFilter from '../ClaimFilter/ClaimFilter';
import ContentCard from '../../../common/ContentCard/ContentCard';
import CountsView from '../../../common/CountsView/CountsView';
import DataTable from '../../../common/DataTable/DataTable';
import SkeletonLoader from '../../../common/SkeletonLoader/SkeletonLoader';
import { colors } from '../../../theme/colors';
import { styled } from '@mui/material/styles';
import { trimSpaces } from '../../../utils';

const StackChart = lazy(() =>
  import('../../../common/Charts/BarChart/StackChart'),
);

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const StyledInnerContentCard = styled(ContentCard)({
  borderRadius: '20px',
  margin: '15px 0px',
});

const Heading = styled(Typography)({
  fontWeight: 500,
  fontSize: 25,
  color: '#000000',
});

const Stats = () => {
  const dispatch = useDispatch();
  const {
    selectedBranch,
    selectedDepartment,
    selectedClaimStatus,
    selectedStartDate,
    selectedEndDate
  } = useSelector(filtersSelector);

  const [showDetails, setShowDetails] = useState(false);

  const options = useMemo(() => {
    const {
      branchName,
      departmentName,
      status,
      startDate,
      endDate,
    } = {
      branchName: selectedBranch ? trimSpaces(selectedBranch.label) : '',
      departmentName: selectedDepartment ? trimSpaces(selectedDepartment.label) : '',
      status: selectedClaimStatus ? trimSpaces(selectedClaimStatus.label) : '',
      startDate: selectedStartDate.format('DD-MM-YYYY'),
      endDate: selectedEndDate.format('DD-MM-YYYY'),
    };

    return {
      branchName,
      departmentName,
      status,
      startDate,
      endDate,
    };
  }, [selectedBranch, selectedDepartment, selectedClaimStatus, selectedStartDate, selectedEndDate]);

  const [getClaimStats, { data }] = useLazyGetClaimStatsQuery();

  useEffect(() => {
    getClaimStats(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterApplied = () => {
    getClaimStats(options);
    dispatch(resetIsFilterApplied());
  };

  const [
    getClaimDetails,
    { data: claimDetails,
      error: claimDetailsError,
      isLoading: claimDetailsLoading,
      isFetching: claimDetailsFetching
    },
  ] = useLazyGetClaimDetailsQuery();

  const onResetFilter = () => {
    getClaimStats({
      startDate: defaultStartDate.format('DD-MM-YYYY'),
      endDate: defaultEndDate.format('DD-MM-YYYY'),
    });
  };

  const handleStatsClick = (selectedBar) => {
    setShowDetails(true);
    const selectedMonth = extractObjectFromArray(data?.claimMonthStats, selectedBar);

    const {
      startDate: selectedBarStartDate,
      endDate: selectedBarEndDate
    } = getStartAndEndDates(selectedMonth?.month || '');

    getClaimDetails({
      ...options,
      startDate: selectedBarStartDate,
      endDate: selectedBarEndDate,
    });
  };

  return (
    <StyledContentCard>
      <Grid item xs={12} md={10}>
        <StyledInnerContentCard>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Claim Stats</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
                py: (theme) => theme.spacing(2),
              }}
            >
              <Grid container>
                <CountsView
                  data={[
                    {
                      title: 'Claimed',
                      count: data?.claimStats[
                        TOTAL_CLAIMED
                      ] || 0,
                      color: colors.primary[500],
                    },
                    {
                      title: 'Reconciled',
                      count: data?.claimStats[
                        TOTAL_RECONCILED
                      ] || 0,
                      color: colors.error[500],
                    },
                    {
                      title: 'Rejected',
                      count: data?.claimStats[
                        TOTAL_REJECTED
                      ] || 0,
                      color: colors.secondary[500],
                    },
                  ]}
                />
              </Grid>
              <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                sx={{
                  mt: (theme) => theme.spacing(3),
                }}
              >
                <Suspense fallback={
                  <SkeletonLoader
                    skeletons={[
                      {
                        variant: 'rectangular',
                        height: 400,
                        animation: 'wave',
                      },
                    ]}
                  />
                }>
                  <StackChart
                    data={
                      data?.claimMonthStats || []
                    }
                    xKey={'month'}
                    yKeys={[
                      TOTAL_CLAIMED,
                      TOTAL_RECONCILED,
                      TOTAL_REJECTED,
                    ]}
                    chartColors={[
                      colors.primary[500],
                      colors.error[500],
                      colors.secondary[500],
                    ]}
                    onClick={(selectedBar) => handleStatsClick(selectedBar)}
                  />
                </Suspense>
              </Grid>
            </Grid>
          </Grid>
          {
            showDetails &&
            (<Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <DataTable
                gridTitle="Claim Details"
                columnDefs={[
                  {
                    headerName: 'Claim Id',
                    field: 'claimId',
                    filter: true,
                  },
                  {
                    headerName: 'Invoice No',
                    field: 'invoiceNo',
                    filter: true,
                  },
                  {
                    headerName: 'Branch Name',
                    field: 'branchName',
                    filter: true,
                  },
                  {
                    headerName: 'Department Name',
                    field: 'deptName',
                    filter: true,
                  },
                  {
                    headerName: 'Service Name',
                    field: 'serviceName',
                    filter: true,
                  },
                  { headerName: 'Claim Amount', field: 'claimAmount', filter: true, },
                  { headerName: 'Rejected Amount', field: 'rejectAmount', filter: true, },
                  { headerName: 'Approved Amount', field: 'approvedAmount', filter: true, },
                  { headerName: 'Denial Code', field: 'denialCode', filter: true, },
                  { headerName: 'Invoice Claim Status', field: 'invoiceClaimStatus', filter: true, },
                ]}
                data={claimDetails || []}
                pageSize={15}
                height={300}
                isLoading={claimDetailsLoading || claimDetailsFetching}
                origin={'claim_stats'}
                handleClose={() => {
                  setShowDetails(false);
                }}
              />
            </Grid>)
          }
        </StyledInnerContentCard>
      </Grid>
      <Grid item xs={12} md={2}>
        <ClaimFilter
          onFilterApplied={onFilterApplied}
          onResetFilter={onResetFilter}
        />
      </Grid>
    </StyledContentCard>
  );
};

export default Stats;
