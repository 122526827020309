import { BOOKED_APPOINTMENTS, CANCELLED_APPOINTMENTS, CONFIRMED_APPOINTMENTS, NO_SHOW_APPOINTMENTS, TOTAL_APPOINTMENTS, WAITING_APPOINTMENTS } from '../../../constants';
import { Grid, Typography } from '@mui/material';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { dateFilterParam, extractObjectFromArray, trimSpaces } from '../../../utils';
import { defaultEndDate, defaultStartDate, getStartAndEndDates } from '../../../utils/date-time';
import { filtersSelector, resetFilters, resetIsFilterApplied } from '../../../features/filters/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetAppointmentDetailsQuery, useLazyGetAppointmentStatsQuery } from '../../../api/appointmentApiSlice';

import AppointmentsFilter from '../AppointmentsFilter/AppointmentsFilter';
import ContentCard from '../../../common/ContentCard/ContentCard';
import CountsView from '../../../common/CountsView/CountsView';
import DataTable from '../../../common/DataTable/DataTable';
import SkeletonLoader from '../../../common/SkeletonLoader/SkeletonLoader';
import { colors } from '../../../theme/colors';
import { styled } from '@mui/material/styles';
import useSelectedFiltersOptions from '../../../hooks/useSelectedFiltersOptions';

const StackChart = lazy(() =>
  import('../../../common/Charts/BarChart/StackChart'),
);

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const StyledInnerContentCard = styled(ContentCard)({
  borderRadius: '20px',
  margin: '15px 0px',
});

const Heading = styled(Typography)({
  fontWeight: 500,
  fontSize: 25,
  color: '#000000',
});

const StyledAppointmentsFilter = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: '50px',
  zIndex: 1,
}));

const Stats = () => {
  const dispatch = useDispatch();
  const {
    selectedBranch,
    selectedDepartment,
    selectedConsultant,
    selectedStatus,
    selectedStartDate,
    selectedEndDate
  } = useSelector(filtersSelector);

  const [showDetails, setShowDetails] = useState(false);

  const options = useMemo(() => {
    const {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    } = {
      branchName: selectedBranch ? trimSpaces(selectedBranch.label) : '',
      departmentName: selectedDepartment ? trimSpaces(selectedDepartment.label) : '',
      consultantName: selectedConsultant ? trimSpaces(selectedConsultant.label) : '',
      status: selectedStatus ? trimSpaces(selectedStatus.label) : '',
      startDate: selectedStartDate.format('DD-MM-YYYY'),
      endDate: selectedEndDate.format('DD-MM-YYYY'),
    };

    return {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    };
  }, [selectedBranch, selectedDepartment, selectedConsultant, selectedStatus, selectedStartDate, selectedEndDate]);

  // eslint-disable-next-line no-unused-vars
  const [getAppointmentStats, { data, error, isLoading }] = useLazyGetAppointmentStatsQuery();

  const [
    getAppointmentDetails,
    { data: appointmentDetails,
      error: appointmentDetailsError,
      isLoading: appointmentDetailsLoading,
      isFetching: appointmentDetailsFetching
    },
  ] = useLazyGetAppointmentDetailsQuery();

  const optionsFromHook = useSelectedFiltersOptions(selectedBranch,
    selectedDepartment,
    selectedConsultant,
    selectedStatus,
    selectedStartDate,
    selectedEndDate);

  useEffect(() => {
    getAppointmentStats(optionsFromHook);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterApplied = () => {
    getAppointmentStats(optionsFromHook);
    dispatch(resetIsFilterApplied());
  };

  const onResetFilter = () => {
    getAppointmentStats({
      startDate: defaultStartDate.format('DD-MM-YYYY'),
      endDate: defaultEndDate.format('DD-MM-YYYY'),
    });
  };

  const handleStatsClick = (selectedBar) => {
    setShowDetails(true);
    const selectedMonth = extractObjectFromArray(data?.appointmentMonthStats, selectedBar);

    const {
      startDate: selectedBarStartDate,
      endDate: selectedBarEndDate
    } = getStartAndEndDates(selectedMonth?.Month || '');

    getAppointmentDetails({
      ...options,
      startDate: selectedBarStartDate,
      endDate: selectedBarEndDate,
    });
  };

  // Reset Filters on unmount
  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContentCard>
      <Grid item xs={12} md={10}>
        <StyledInnerContentCard>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Appointment Stats</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
                py: (theme) => theme.spacing(2),
              }}
            >
              <Grid container>
                <CountsView
                  data={[
                    {
                      title: 'Booked',
                      count: data?.appointmentStats[
                        BOOKED_APPOINTMENTS
                      ] || 0,
                      color: colors.success[500],
                      textColor: colors.white[100]
                    },
                    {
                      title: 'No Show',
                      count: data?.appointmentStats[
                        NO_SHOW_APPOINTMENTS
                      ] || 0,
                      color: colors.secondary[500],
                    },
                    {
                      title: 'Cancelled',
                      count: data?.appointmentStats[
                        CANCELLED_APPOINTMENTS
                      ] || 0,
                      color: colors.error[500],
                    },
                    {
                      title: 'Waiting',
                      count: data?.appointmentStats[
                        WAITING_APPOINTMENTS
                      ] || 0,
                      color: '#9C27B0',
                      textColor: colors.white[100]
                    },
                    {
                      title: 'Reschedule',
                      count: data?.appointmentStats[
                        'Reschedule'
                      ] || 0,
                      color: '#607D8B',
                      textColor: colors.white[100]
                    },
                    {
                      title: 'Confirmed',
                      count: data?.appointmentStats[
                        CONFIRMED_APPOINTMENTS
                      ] || 0,
                      color: '#78d457',
                      textColor: colors.white[100]
                    },
                    {
                      title: 'Total',
                      count: data?.appointmentStats[
                        TOTAL_APPOINTMENTS
                      ] || 0,
                      color: colors.primary[500],
                    },
                  ]}
                />
              </Grid>
              <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                sx={{
                  mt: (theme) => theme.spacing(3),
                }}
              >
                <Suspense fallback={
                  <SkeletonLoader
                    skeletons={[
                      {
                        variant: 'rectangular',
                        height: 400,
                        animation: 'wave',
                      },
                    ]}
                  />
                }>
                  <StackChart
                    data={
                      data?.appointmentMonthStats || []
                    }
                    xKey={'Month'}
                    yKeys={['Booked', 'No Show', 'Cancelled', 'Checked Out', 'Waiting', 'Reschedule', 'Confirmed', 'Not Available', 'Invoiced']}
                    chartColors={[
                      colors.success[500],
                      colors.secondary[500],
                      colors.error[500],
                      '#07c1ff',
                      '#9C27B0',
                      '#607D8B',
                      '#78d457',
                      '#ff0084',
                      '#9E9E9E',
                    ]}
                    onClick={(selectedBar) => handleStatsClick(selectedBar)}
                  />
                </Suspense>
              </Grid>
            </Grid>
          </Grid>
          {
            showDetails &&
            (<Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <DataTable
                gridTitle="Appointments"
                columnDefs={[
                  {
                    headerName: 'Booking Date',
                    field: 'bookingDate',
                    filter: 'agDateColumnFilter',
                    filterParams: dateFilterParam,
                  },
                  {
                    headerName: 'Branch Name',
                    field: 'branchName',
                    filter: true,
                  },
                  {
                    headerName: 'Department Name',
                    field: 'departmentName',
                    filter: true,
                  },
                  { headerName: 'Doctor Name', field: 'doctorName', filter: true, },
                  { headerName: 'Appointment Status', field: 'appointmentStatus', filter: true, },
                  { headerName: 'Created By User Name', field: 'createdByUserName', filter: true, },
                ]}
                data={appointmentDetails || []}
                pageSize={15}
                height={300}
                isLoading={appointmentDetailsFetching}
                origin={'appointments_stats'}
                handleClose={() => {
                  setShowDetails(false);
                }}
              />
            </Grid>)
          }
        </StyledInnerContentCard>
      </Grid>
      <Grid item xs={12} md={2}>
        <AppointmentsFilter
          onFilterApplied={onFilterApplied}
          onResetFilter={onResetFilter}
        />
      </Grid>
    </StyledContentCard>
  );
};

export default Stats;
