import {
  APPOINTMENTS_ACTIVITY,
  APPOINTMENTS_DETAILS,
  APPOINTMENTS_STATS,
} from '../constants';
import {
  buildAppointmentsDetailsUrl,
  buildUrlUsingParams,
} from '../utils/urlformat';

import { apiSlice } from './apiSlice';
import { createEntityAdapter } from '@reduxjs/toolkit';

const appointmentsAdapter = createEntityAdapter();

const initialState = appointmentsAdapter.getInitialState();

// Define a service using a base URL and expected endpoints
export const appointmentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppointmentStats: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(APPOINTMENTS_STATS, payload);
      },
    }),
    getAppointmentActivity: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(APPOINTMENTS_ACTIVITY, payload);
      },
    }),
    getAppointmentDetails: builder.query({
      query: (payload) => {
        const {
          startDate,
          endDate,
          branchName,
          departmentName,
          consultantName,
          userName,
          status,
        } = payload;
        return buildAppointmentsDetailsUrl(
          branchName,
          departmentName,
          consultantName,
          userName,
          status,
          startDate,
          endDate,
        );
      },
    }),
  }),
});

export const {
  // useGetAppointmentStatsQuery,
  useLazyGetAppointmentStatsQuery,
  useGetAppointmentActivityQuery,
  useLazyGetAppointmentActivityQuery,
  useGetAppointmentDetailsQuery,
  useLazyGetAppointmentDetailsQuery,
} = appointmentsApiSlice;
