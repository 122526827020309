import * as React from 'react';

import {
  AppBar,
  Button,
  Divider,
  Grid,
  InputBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { PowerSettingsNew } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';

const LogoImg = styled('img')({
  width: '100%',
  maxWidth: '120px',
  height: 'auto',
  filter: 'grayscale(100%) brightness(200%)',
});

const SearchBar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '8px',
  height: '48px',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  },
});

const SearchIconWrapper = styled('div')({
  marginLeft: 'auto',
  marginRight: '24px',
  marginTop: '9px',
  opacity: '0.5',
});

const SearchInput = styled(InputBase)({
  marginLeft: '16px',
  flex: 1,
  color: 'inherit',
  '&::placeholder': {
    fontSize: '16px',
    opacity: 1,
  },
  '& input': {
    fontSize: '18px',
  },
});

const StyledButton = styled(Button)({
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '8px',
  marginLeft: '16px',
  height: '40px',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  },
  boxShadow: 'none',
  color: '#B7B7B7',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '27px',
});

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: '#015092',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.03)',
        backdropFilter: 'blur(2px)',
      }}
    >
      <Toolbar>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} md={3}>
            <LogoImg src="/logo.png" alt="logo" />
          </Grid>
          <Grid item xs={6} md={4}>
            <SearchBar>
              <SearchInput
                placeholder="Search Here"
                inputProps={{ 'aria-label': 'search' }}
              />
              <SearchIconWrapper>
                <SearchIcon color="inherit" sx={{ fontSize: 32 }} />
              </SearchIconWrapper>
            </SearchBar>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <StyledButton variant="contained" color="primary">
                  Ad Hoc Report
                </StyledButton>
              </Grid>
              <Grid item>
                <div
                  onClick={handleMenuOpen}
                  style={{
                    color: 'inherit',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderRadius: '50%',
                      height: '40px',
                      width: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <PersonOutlineIcon
                      sx={{ fontSize: 20, color: '#B7B7B7' }}
                    />
                  </div>
                  <Typography
                    variant="h6"
                    sx={{
                      ml: 0.5,
                      fontWeight: 400,
                      color: '#B7B7B7',
                      fontSize: '17px',
                    }}
                  >
                    Demo
                  </Typography>
                  <ArrowDropDownIcon sx={{ fontSize: 20, color: '#B7B7B7' }} />
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  PaperProps={{
                    sx: {
                      backgroundColor: '#f7f7f7',
                      borderRadius: '8px',
                      width: '180px',
                      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)', // Add a box shadow
                      '& .MuiList-root': {
                        padding: 0,
                      },
                    },
                  }}
                  ListProps={{
                    sx: {
                      '& .MuiListItem-root:hover': {
                        backgroundColor: '#ccc',
                        color: '#fff',
                      },
                    },
                  }}
                >
                  <MenuItem onClick={handleMenuClose}>
                    <ListItemIcon>
                      <PersonOutlineIcon
                        sx={{ fontSize: 20, color: '#015092' }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Profile"
                      sx={{
                        fontSize: '0.7rem',
                        color: '#015092',
                      }}
                    />
                  </MenuItem>
                  <Divider sx={{ margin: '1px 0' }} />
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      window.location.href = '/';
                    }}
                  >
                    <ListItemIcon>
                      <PowerSettingsNew
                        sx={{ fontSize: 20, color: '#015092' }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Logout"
                      sx={{
                        fontSize: '0.7rem',
                        color: '#015092',
                      }}
                    />
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
