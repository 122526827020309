import { CheckRenderer, dateFilterParam, extractObjectFromArray } from '../../../utils';
import { Grid, Typography } from '@mui/material';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { TOTAL_FIRST_TIME_VISITS, TOTAL_FOLLOWS_UP_VISITS, TOTAL_REVISITS, TOTAL_VISITS } from '../../../constants';
import { defaultEndDate, defaultStartDate, getStartAndEndDates } from '../../../utils/date-time';
import { filtersSelector, resetFilters, resetIsFilterApplied } from '../../../features/filters/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetFootFallDetailsQuery, useLazyGetFootFallStatsQuery } from '../../../api/footFallApiSlice';

import ContentCard from '../../../common/ContentCard/ContentCard';
import CountsView from '../../../common/CountsView/CountsView';
import DataTable from '../../../common/DataTable/DataTable';
import FootFallFilter from '../FootFallFilter/FootFallFilter';
import SkeletonLoader from '../../../common/SkeletonLoader/SkeletonLoader';
import { colors } from '../../../theme/colors';
import { styled } from '@mui/material/styles';
import { trimSpaces } from '../../../utils';

const StackChart = lazy(() =>
  import('../../../common/Charts/BarChart/StackChart'),
);

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const StyledInnerContentCard = styled(ContentCard)({
  borderRadius: '20px',
  margin: '15px 0px',
});

const Heading = styled(Typography)({
  fontWeight: 500,
  fontSize: 25,
  color: '#000000',
});

const Stats = () => {
  const dispatch = useDispatch();
  const {
    selectedBranch,
    selectedDepartment,
    selectedConsultant,
    selectedVisitType,
    selectedStartDate,
    selectedEndDate
  } = useSelector(filtersSelector);

  const [showDetails, setShowDetails] = useState(false);

  const options = useMemo(() => {
    const {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    } = {
      branchName: selectedBranch ? trimSpaces(selectedBranch.label) : '',
      departmentName: selectedDepartment ? trimSpaces(selectedDepartment.label) : '',
      consultantName: selectedConsultant ? trimSpaces(selectedConsultant.label) : '',
      status: selectedVisitType ? trimSpaces(selectedVisitType.label) : '',
      startDate: selectedStartDate.format('DD-MM-YYYY'),
      endDate: selectedEndDate.format('DD-MM-YYYY'),
    };

    return {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    };
  }, [selectedBranch, selectedDepartment, selectedConsultant, selectedVisitType, selectedStartDate, selectedEndDate]);

  const [getFootFallStats, { data }] = useLazyGetFootFallStatsQuery();

  const [
    getFootFallDetails,
    { data: footFallDetails,
      error: footFallDetailsError,
      isLoading: footFallDetailsLoading,
      isFetching: footFallDetailsFetching
    },
  ] = useLazyGetFootFallDetailsQuery();

  useEffect(() => {
    getFootFallStats(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterApplied = () => {
    getFootFallStats(options);
    dispatch(resetIsFilterApplied());
  };

  const onResetFilter = () => {
    getFootFallStats({
      startDate: defaultStartDate.format('DD-MM-YYYY'),
      endDate: defaultEndDate.format('DD-MM-YYYY'),
    });
  };

  const handleStatsClick = (selectedBar) => {
    setShowDetails(true);
    const selectedMonth = extractObjectFromArray(data?.footfallMonthStats, selectedBar);

    const {
      startDate: selectedBarStartDate,
      endDate: selectedBarEndDate
    } = getStartAndEndDates(selectedMonth?.Month || '');

    getFootFallDetails({
      ...options,
      startDate: selectedBarStartDate,
      endDate: selectedBarEndDate,
    });
  };

  // Reset Filters on unmount
  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContentCard>
      <Grid item xs={12} md={10}>
        <StyledInnerContentCard>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">FootFall Stats</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
                py: (theme) => theme.spacing(2),
              }}
            >
              <Grid container>
                <CountsView
                  data={[
                    {
                      title: 'Total Visits',
                      count: data?.footfallStats[
                        TOTAL_VISITS
                      ] || 0,
                      color: colors.primary[500],
                    },
                    {
                      title: 'First Time visits',
                      count: data?.footfallStats[
                        TOTAL_FIRST_TIME_VISITS
                      ] || 0,
                      color: colors.error[500],
                    },
                    {
                      title: 'Follow up visits',
                      count: data?.footfallStats[
                        TOTAL_FOLLOWS_UP_VISITS
                      ] || 0,
                      color: colors.secondary[500],
                    },
                    {
                      title: 'Revisits',
                      count: data?.footfallStats[
                        TOTAL_REVISITS
                      ] || 0,
                      color: colors.success[500],
                      textColor: '#fff',
                    },
                  ]}
                />
              </Grid>
              <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                sx={{
                  mt: (theme) => theme.spacing(3),
                }}
              >
                <Suspense fallback={
                  <SkeletonLoader
                    skeletons={[
                      {
                        variant: 'rectangular',
                        height: 400,
                        animation: 'wave',
                      },
                    ]}
                  />
                }>
                  <StackChart
                    data={
                      data?.footfallMonthStats || []
                    }
                    xKey={'Month'}
                    yKeys={[
                      TOTAL_FIRST_TIME_VISITS,
                      TOTAL_FOLLOWS_UP_VISITS,
                      TOTAL_REVISITS,
                    ]}
                    chartColors={[
                      colors.error[500],
                      colors.secondary[500],
                      colors.success[500],
                    ]}
                    onClick={(selectedBar) => handleStatsClick(selectedBar)}
                  />
                </Suspense>
              </Grid>
            </Grid>
          </Grid>
          {
            showDetails &&
            (<Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <DataTable
                gridTitle="FootFall Details"
                columnDefs={[
                  {
                    headerName: 'Patient Name',
                    field: 'patientName',
                    filter: true,
                  },
                  {
                    headerName: 'Doctor Name',
                    field: 'doctorName',
                    filter: true,
                  },
                  {
                    headerName: 'Visit Date',
                    field: 'visitDate',
                    filter: 'agDateColumnFilter',
                    filterParams: dateFilterParam,
                  },
                  {
                    headerName: 'Branch Name',
                    field: 'branchName',
                    filter: true,
                  },
                  {
                    headerName: 'Department Name',
                    field: 'departmentName',
                    filter: true,
                  },
                  {
                    headerName: 'First Visit',
                    field: 'firstVisit',
                    cellRenderer: CheckRenderer
                  },
                  {
                    headerName: 'Follow Up Visit',
                    field: 'followUpVisit',
                    cellRenderer: CheckRenderer
                  },
                  {
                    headerName: 'Revisit',
                    field: 'revisit',
                    cellRenderer: CheckRenderer
                  }
                ]}
                data={footFallDetails || []}
                pageSize={15}
                height={300}
                isLoading={footFallDetailsLoading || footFallDetailsFetching}
                origin={'footfall_stats'}
                handleClose={() => {
                  setShowDetails(false);
                }}
              />
            </Grid>)
          }
        </StyledInnerContentCard>
      </Grid>
      <Grid item xs={12} md={2}>
        <FootFallFilter
          onFilterApplied={onFilterApplied}
          onResetFilter={onResetFilter}
        />
      </Grid>
    </StyledContentCard>
  );
};

export default Stats;
