import { Box, Button, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { authSelector, login } from '../../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';

import ChartsUsage from '../ChartsUsage';
import { Navigate } from 'react-router-dom';

const ExportButton = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 36,
  padding: '0 30px',
  '&:hover': {
    background: 'linear-gradient(45deg, #ef7a3c 30%, #FE6B8B 90%)',
    // boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .5)',
    textShadow: '0 0 1px #ffe8e8',
  },
});

const Login = () => {
  const { isAuthenticated } = useSelector(authSelector);
  const dispatch = useDispatch();

  const handleLoginClick = () => {
    dispatch(login());
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        m: 2,
      }}
    >
      <ExportButton onClick={handleLoginClick}>
        Click here to Login
      </ExportButton>

      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 3,
        }}
      >
        <ChartsUsage />
      </Box>

      <ExportButton onClick={handleLoginClick}>
        Click here to Login
      </ExportButton> */}
    </Box>
  );
};

export default Login;
