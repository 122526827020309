import { CheckRenderer, dateFilterParam, extractObjectFromArray } from '../../../utils';
import { Grid, Typography } from '@mui/material';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { TOTAL_DISCOUNT, TOTAL_NET_REVENUE, TOTAL_REVENUE } from '../../../constants';
import { defaultEndDate, defaultStartDate, getStartAndEndDates } from '../../../utils/date-time';
import { filtersSelector, resetIsFilterApplied } from '../../../features/filters/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetRevenueDetailsQuery, useLazyGetRevenueStatsQuery } from '../../../api/revenueApiSlice';

import ContentCard from '../../../common/ContentCard/ContentCard';
import CountsView from '../../../common/CountsView/CountsView';
import DataTable from '../../../common/DataTable/DataTable';
import RevenueFilter from '../RevenueFilter/RevenueFilter';
import SkeletonLoader from '../../../common/SkeletonLoader/SkeletonLoader';
import { colors } from '../../../theme/colors';
import { styled } from '@mui/material/styles';
import { trimSpaces } from '../../../utils';

const StackChart = lazy(() =>
  import('../../../common/Charts/BarChart/StackChart'),
);

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const StyledInnerContentCard = styled(ContentCard)({
  borderRadius: '20px',
  margin: '15px 0px',
});

const Heading = styled(Typography)({
  fontWeight: 500,
  fontSize: 25,
  color: '#000000',
});

const Stats = () => {
  const dispatch = useDispatch();
  const {
    selectedBranch,
    selectedDepartment,
    selectedRevenueStatus,
    selectedStartDate,
    selectedEndDate
  } = useSelector(filtersSelector);

  const [showDetails, setShowDetails] = useState(false);

  const options = useMemo(() => {
    const {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    } = {
      branchName: selectedBranch ? trimSpaces(selectedBranch.label) : '',
      departmentName: selectedDepartment ? trimSpaces(selectedDepartment.label) : '',
      status: selectedRevenueStatus ? trimSpaces(selectedRevenueStatus.label) : '',
      startDate: selectedStartDate.format('DD-MM-YYYY'),
      endDate: selectedEndDate.format('DD-MM-YYYY'),
    };

    return {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    };
  }, [selectedBranch, selectedDepartment, selectedRevenueStatus, selectedStartDate, selectedEndDate]);

  const [getRevenueStats, { data }] = useLazyGetRevenueStatsQuery();

  useEffect(() => {
    getRevenueStats(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterApplied = () => {
    getRevenueStats(options);
    dispatch(resetIsFilterApplied());
  };

  const [
    getRevenueDetails,
    { data: revenueDetails,
      error: revenueDetailsError,
      isLoading: revenueDetailsLoading,
      isFetching: revenueDetailsFetching
    },
  ] = useLazyGetRevenueDetailsQuery();

  const onResetFilter = () => {
    getRevenueStats({
      startDate: defaultStartDate.format('DD-MM-YYYY'),
      endDate: defaultEndDate.format('DD-MM-YYYY'),
    });
  };

  const handleStatsClick = (selectedBar) => {
    setShowDetails(true);
    const selectedMonth = extractObjectFromArray(data?.revenueMonthStats, selectedBar);

    const {
      startDate: selectedBarStartDate,
      endDate: selectedBarEndDate
    } = getStartAndEndDates(selectedMonth?.month || '');

    getRevenueDetails({
      ...options,
      startDate: selectedBarStartDate,
      endDate: selectedBarEndDate,
    });
  };

  return (
    <StyledContentCard>
      <Grid item xs={12} md={10}>
        <StyledInnerContentCard>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Revenue Stats</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
                py: (theme) => theme.spacing(2),
              }}
            >
              <Grid container>
                <CountsView
                  data={[
                    {
                      title: 'Total Revenue',
                      count: data?.revenueStats[
                        TOTAL_REVENUE
                      ] || 0,
                      color: colors.primary[500],
                    },
                    {
                      title: 'Total Discount',
                      count: data?.revenueStats[
                        TOTAL_DISCOUNT
                      ] || 0,
                      color: colors.error[500],
                    },
                    {
                      title: 'Net Revenue',
                      count: data?.revenueStats[
                        TOTAL_NET_REVENUE
                      ] || 0,
                      color: colors.secondary[500],
                    },
                  ]}
                />
              </Grid>
              <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                sx={{
                  mt: (theme) => theme.spacing(3),
                }}
              >
                <Suspense fallback={
                  <SkeletonLoader
                    skeletons={[
                      {
                        variant: 'rectangular',
                        height: 400,
                        animation: 'wave',
                      },
                    ]}
                  />
                }>
                  <StackChart
                    data={
                      data?.revenueMonthStats || []
                    }
                    xKey={'month'}
                    yKeys={[
                      TOTAL_REVENUE,
                      TOTAL_DISCOUNT,
                      TOTAL_NET_REVENUE,
                    ]}
                    chartColors={[
                      colors.primary[500],
                      colors.error[500],
                      colors.secondary[500],
                    ]}
                    onClick={(selectedBar) => handleStatsClick(selectedBar)}
                  />
                </Suspense>
              </Grid>
            </Grid>
          </Grid>
          {
            showDetails &&
            (<Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <DataTable
                gridTitle="Revenue Details"
                columnDefs={[
                  {
                    headerName: 'Invoice Id',
                    field: 'masterInvoiceId',
                    filter: true,
                  },
                  // {
                  //   headerName: 'Booking Date',
                  //   field: 'bookingDate',
                  //   filter: 'agDateColumnFilter',
                  //   filterParams: dateFilterParam,
                  // },
                  {
                    headerName: 'Branch Name',
                    field: 'branchName',
                    filter: true,
                  },
                  {
                    headerName: 'Department Name',
                    field: 'deptName',
                    filter: true,
                  },
                  {
                    headerName: 'Service Name',
                    field: 'serviceName',
                    filter: true,
                  },
                  {
                    headerName: 'Sponsor',
                    field: 'sponsorName',
                    filter: true,
                  },
                  { headerName: 'Invoice Amount', field: 'invoiceAmount', filter: true, },
                  { headerName: 'Discount', field: 'discount', filter: true, },
                  { headerName: 'Net Amount', field: 'netAmount', filter: true, },
                  { headerName: 'Invoice Status', field: 'invoiceStatus', filter: true, },
                  { headerName: 'Employee Name', field: 'employeeName', filter: true, },
                ]}
                data={revenueDetails || []}
                pageSize={15}
                height={300}
                isLoading={revenueDetailsLoading || revenueDetailsFetching}
                origin={'revenue_stats'}
                handleClose={() => {
                  setShowDetails(false);
                }}
              />
            </Grid>)
          }
        </StyledInnerContentCard>
      </Grid>
      <Grid item xs={12} md={2}>
        <RevenueFilter
          onFilterApplied={onFilterApplied}
          onResetFilter={onResetFilter}
        />
      </Grid>
    </StyledContentCard>
  );
};

export default Stats;
