import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { CheckRenderer, dateFilterParam, formatVisitsByTypeData } from '../../../utils';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { defaultEndDate, defaultStartDate } from '../../../utils/date-time';
import { filtersSelector, resetIsFilterApplied } from '../../../features/filters/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetFootFallActivityQuery, useLazyGetFootFallDetailsQuery } from '../../../api/footFallApiSlice';

import AppointmentsFilter from '../../Appointments/AppointmentsFilter/AppointmentsFilter';
import BarChart from '../../../common/Charts/BarChart/BarChart';
import ContentCard from '../../../common/ContentCard/ContentCard';
import DataTable from '../../../common/DataTable/DataTable';
import FootFallFilter from '../FootFallFilter/FootFallFilter';
import NivoPie from '../../../common/Charts/PieChart/NivoPie';
import TreeMap from '../../../common/Charts/TreeMap/TreeMap';
import { styled } from '@mui/material/styles';
import { trimSpaces } from '../../../utils';

const StackedColumn = lazy(() =>
  import('../../../common/Charts/BarChart/StackedColumn'),
);

const NivoTreeMap = lazy(() =>
  import('../../../common/Charts/TreeMap/NivoTreeMap'),
);

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const StyledInnerContentCard = styled(ContentCard)({
  borderRadius: '20px',
  margin: '15px 0px',
});

const Heading = styled(Typography)({
  fontWeight: 500,
  fontSize: 25,
  color: '#000000',
});

const StyledFilterWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(1)};
  margin-top: ${(props) => props.theme.spacing(6)};
`;

const Activity = () => {
  const dispatch = useDispatch();
  const {
    selectedBranch,
    selectedDepartment,
    selectedConsultant,
    selectedVisitType,
    selectedStartDate,
    selectedEndDate
  } = useSelector(filtersSelector);

  const options = useMemo(() => {
    const {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    } = {
      branchName: selectedBranch ? trimSpaces(selectedBranch.label) : '',
      departmentName: selectedDepartment ? trimSpaces(selectedDepartment.label) : '',
      consultantName: selectedConsultant ? trimSpaces(selectedConsultant.label) : '',
      status: selectedVisitType ? trimSpaces(selectedVisitType.label) : '',
      startDate: selectedStartDate.format('DD-MM-YYYY'),
      endDate: selectedEndDate.format('DD-MM-YYYY'),
    };

    return {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    };
  }, [selectedBranch, selectedDepartment, selectedConsultant, selectedVisitType, selectedStartDate, selectedEndDate]);

  const [showBranchDetails, setShowBranchDetails] = useState(false);
  const [showDepartmentDetails, setShowDepartmentDetails] = useState(false)
  const [showDetailsByVisitType, setShowDetailsByVisitType] = useState(false)

  /**
   * TODO: later handle error and isLoading..
   */
  const [getFootFallActivity, { data }] = useLazyGetFootFallActivityQuery();

  const [
    getFootFallDetails,
    { data: footFallDetails,
      error: footFallDetailsError,
      isLoading: footFallDetailsLoading,
      isFetching: footFallDetailsFetching
    },
  ] = useLazyGetFootFallDetailsQuery();

  useEffect(() => {
    getFootFallActivity(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterApplied = () => {
    getFootFallActivity(options);
    dispatch(resetIsFilterApplied());
  };

  const onResetFilter = () => {
    getFootFallActivity({
      startDate: defaultStartDate.format('DD-MM-YYYY'),
      endDate: defaultEndDate.format('DD-MM-YYYY'),
    });
  };

  const handleBranchBarClick = (selectedBar) => {
    setShowBranchDetails(true);
    setShowDepartmentDetails(false);
    const selectedBranchName = data?.footfallBranchVisitsActivity && data?.footfallBranchVisitsActivity[selectedBar]?.['Branch Name'];
    getFootFallDetails({
      ...options,
      branchName: selectedBranchName,
    });
  }

  const handleDepartmentBarClick = (selectedBar) => {
    setShowBranchDetails(false);
    setShowDepartmentDetails(true);
    const selectedDepartmentName = data?.footfallDepartmentVisitsActivity && data?.footfallDepartmentVisitsActivity[selectedBar]?.['Department Name'];
    getFootFallDetails({
      ...options,
      departmentName: `${selectedDepartmentName}`
    });
  }

  const handleStatusSliceClick = (selectedSlice) => {
    setShowDetailsByVisitType(true);
    getFootFallDetails({
      ...options,
      status: selectedSlice,
    });
  }

  return (
    <StyledContentCard>
      <Grid item xs={12} md={10}>
        {/* Branch Wise Visits */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Branch Wise Visits</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={400}
                />
              }>
                <BarChart
                  data={
                    data?.footfallBranchVisitsActivity || []
                  }
                  isHorizontal={false}
                  height={400}
                  xKey="Branch Name"
                  yKey="Total Branch Visits"
                  onClick={(selectedBar) => handleBranchBarClick(selectedBar)}
                />
              </Suspense>
            </Grid>
            {
              showBranchDetails &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="FootFall Details"
                  columnDefs={[
                    {
                      headerName: 'Patient Name',
                      field: 'patientName',
                      filter: true,
                    },
                    {
                      headerName: 'Doctor Name',
                      field: 'doctorName',
                      filter: true,
                    },
                    {
                      headerName: 'Visit Date',
                      field: 'visitDate',
                      filter: 'agDateColumnFilter',
                      filterParams: dateFilterParam,
                    },
                    {
                      headerName: 'Branch Name',
                      field: 'branchName',
                      filter: true,
                    },
                    {
                      headerName: 'Department Name',
                      field: 'departmentName',
                      filter: true,
                    },
                    {
                      headerName: 'First Visit',
                      field: 'firstVisit',
                      cellRenderer: CheckRenderer
                    },
                    {
                      headerName: 'Follow Up Visit',
                      field: 'followUpVisit',
                      cellRenderer: CheckRenderer
                    },
                    {
                      headerName: 'Revisit',
                      field: 'revisit',
                      cellRenderer: CheckRenderer
                    }
                  ]}
                  data={footFallDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={footFallDetailsLoading || footFallDetailsFetching}
                  origin={'footfall_activity'}
                  handleClose={() => {
                    setShowBranchDetails(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>

        {/* Department Wise Visits */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Department Wise Visits</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={400}
                />
              }>
                <BarChart
                  data={
                    data?.footfallDepartmentVisitsActivity || []
                  }
                  isHorizontal={false}
                  height={400}
                  xKey="Department Name"
                  yKey="Total Department Visits"
                  onClick={(selectedBar) => handleDepartmentBarClick(selectedBar)}
                />
              </Suspense>
            </Grid>
            {
              showDepartmentDetails &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="FootFall Details"
                  columnDefs={[
                    {
                      headerName: 'Patient Name',
                      field: 'patientName',
                      filter: true,
                    },
                    {
                      headerName: 'Doctor Name',
                      field: 'doctorName',
                      filter: true,
                    },
                    {
                      headerName: 'Visit Date',
                      field: 'visitDate',
                      filter: 'agDateColumnFilter',
                      filterParams: dateFilterParam,
                    },
                    {
                      headerName: 'Branch Name',
                      field: 'branchName',
                      filter: true,
                    },
                    {
                      headerName: 'Department Name',
                      field: 'departmentName',
                      filter: true,
                    },
                    {
                      headerName: 'First Visit',
                      field: 'firstVisit',
                      cellRenderer: CheckRenderer
                    },
                    {
                      headerName: 'Follow Up Visit',
                      field: 'followUpVisit',
                      cellRenderer: CheckRenderer
                    },
                    {
                      headerName: 'Revisit',
                      field: 'revisit',
                      cellRenderer: CheckRenderer
                    }
                  ]}
                  data={footFallDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={footFallDetailsLoading || footFallDetailsFetching}
                  origin={'footfall_activity'}
                  handleClose={() => {
                    setShowDepartmentDetails(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>

        {/* Consultant Wise Visits */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Consultant Wise Visits</Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense
                fallback={
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={400}
                  />
                }
              >
                <TreeMap
                  data={
                    data?.footfallConsultantVisitsActivity || []
                  }
                  height={'400px'}
                  xKey="Consultant Name"
                  yKey="Total Cconsultant Visits"
                />
              </Suspense>
            </Grid>
          </StyledInnerContentCard>
        </Grid>

        {/* Visits By Type */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center"
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Visits By Type</Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={400}
                />
              }>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <NivoPie
                    data={formatVisitsByTypeData(
                      data?.visitTypeActvity || {}
                    )}
                    height={400}
                    xKey="type"
                    yKey="visitsByType"
                    onClick={(selectedSlice) => handleStatusSliceClick(selectedSlice)}
                  />
                </div>
              </Suspense>
            </Grid>
            {
              showDetailsByVisitType &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="FootFall Details"
                  columnDefs={[
                    {
                      headerName: 'Patient Name',
                      field: 'patientName',
                      filter: true,
                    },
                    {
                      headerName: 'Doctor Name',
                      field: 'doctorName',
                      filter: true,
                    },
                    {
                      headerName: 'Visit Date',
                      field: 'visitDate',
                      filter: 'agDateColumnFilter',
                      filterParams: dateFilterParam,
                    },
                    {
                      headerName: 'Branch Name',
                      field: 'branchName',
                      filter: true,
                    },
                    {
                      headerName: 'Department Name',
                      field: 'departmentName',
                      filter: true,
                    },
                    {
                      headerName: 'First Visit',
                      field: 'firstVisit',
                      cellRenderer: CheckRenderer
                    },
                    {
                      headerName: 'Follow Up Visit',
                      field: 'followUpVisit',
                      cellRenderer: CheckRenderer
                    },
                    {
                      headerName: 'Revisit',
                      field: 'revisit',
                      cellRenderer: CheckRenderer
                    }
                  ]}
                  data={footFallDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={footFallDetailsLoading || footFallDetailsFetching}
                  origin={'footfall_activity'}
                  handleClose={() => {
                    setShowDetailsByVisitType(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <FootFallFilter
          onFilterApplied={onFilterApplied}
          onResetFilter={onResetFilter}
        />
      </Grid>
    </StyledContentCard>
  );
};

export default Activity;
