import { Grid, Typography } from '@mui/material';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { TOTAL_ADULT_PATIENTS, TOTAL_CHILD_PATIENTS, TOTAL_FEMALE_PATIENTS, TOTAL_FIRST_TIME_VISITS, TOTAL_FOLLOWS_UP_VISITS, TOTAL_MALE_PATIENTS, TOTAL_OTHER_PATIENTS, TOTAL_PATIENTS, TOTAL_REVISITS, TOTAL_VISITS } from '../../../constants';
import { dateFilterParam, extractObjectFromArray } from '../../../utils';
import { defaultEndDate, defaultStartDate, getStartAndEndDates } from '../../../utils/date-time';
import { filtersSelector, resetIsFilterApplied } from '../../../features/filters/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetPatientDetailsQuery, useLazyGetPatientStatsQuery } from '../../../api/patientsApiSlice';

import ContentCard from '../../../common/ContentCard/ContentCard';
import CountsView from '../../../common/CountsView/CountsView';
import DataTable from '../../../common/DataTable/DataTable';
import PatientsFilter from '../PatientsFilter/PatientsFilter';
import SkeletonLoader from '../../../common/SkeletonLoader/SkeletonLoader';
import { colors } from '../../../theme/colors';
import { styled } from '@mui/material/styles';
import { trimSpaces } from '../../../utils';

const StackChart = lazy(() =>
  import('../../../common/Charts/BarChart/StackChart'),
);

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const StyledInnerContentCard = styled(ContentCard)({
  borderRadius: '20px',
  margin: '15px 0px',
});

const Heading = styled(Typography)({
  fontWeight: 500,
  fontSize: 25,
  color: '#000000',
});

const Stats = () => {
  const dispatch = useDispatch();
  const {
    selectedBranch,
    selectedDepartment,
    selectedConsultant,
    selectedPatientType,
    selectedStartDate,
    selectedEndDate
  } = useSelector(filtersSelector);

  const [showDetails, setShowDetails] = useState(false);

  const options = useMemo(() => {
    const {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    } = {
      branchName: selectedBranch ? trimSpaces(selectedBranch.label) : '',
      departmentName: selectedDepartment ? trimSpaces(selectedDepartment.label) : '',
      consultantName: selectedConsultant ? trimSpaces(selectedConsultant.label) : '',
      status: selectedPatientType ? trimSpaces(selectedPatientType.label) : '',
      startDate: selectedStartDate.format('DD-MM-YYYY'),
      endDate: selectedEndDate.format('DD-MM-YYYY'),
    };

    return {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    };
  }, [selectedBranch, selectedDepartment, selectedConsultant, selectedPatientType, selectedStartDate, selectedEndDate]);

  const [getPatientStats, { data, isLoading }] = useLazyGetPatientStatsQuery();

  const [
    getPatientDetails,
    { data: patientDetails,
      error: patientDetailsError,
      isLoading: patientDetailsLoading,
      isFetching: patientDetailsFetching
    },
  ] = useLazyGetPatientDetailsQuery();

  useEffect(() => {
    getPatientStats(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterApplied = () => {
    getPatientStats(options);
    dispatch(resetIsFilterApplied());
  };

  const handleStatsClick = (selectedBar) => {
    setShowDetails(true);
    const selectedMonth = extractObjectFromArray(data?.patientMonthStats, selectedBar);

    const {
      startDate: selectedBarStartDate,
      endDate: selectedBarEndDate
    } = getStartAndEndDates(selectedMonth?.month || '');

    getPatientDetails({
      ...options,
      startDate: selectedBarStartDate,
      endDate: selectedBarEndDate,
    });
  };

  const onResetFilter = () => {
    getPatientStats({
      startDate: defaultStartDate.format('DD-MM-YYYY'),
      endDate: defaultEndDate.format('DD-MM-YYYY'),
    });
  };

  return (
    <StyledContentCard>
      <Grid item xs={12} md={10}>
        <StyledInnerContentCard>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Patient Stats</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
                py: (theme) => theme.spacing(2),
              }}
            >
              <Grid container>
                <CountsView
                  data={[
                    {
                      title: 'Total Patients',
                      count: data?.patientStats[
                        TOTAL_PATIENTS
                      ] || 0,
                      color: colors.primary[500],
                    },
                    {
                      title: 'Male',
                      count: data?.patientStats[
                        TOTAL_MALE_PATIENTS
                      ] || 0,
                      color: colors.error[500],
                    },
                    {
                      title: 'Female',
                      count: data?.patientStats[
                        TOTAL_FEMALE_PATIENTS
                      ] || 0,
                      color: colors.secondary[500],
                    },
                    {
                      title: 'Others',
                      count: data?.patientStats[
                        TOTAL_OTHER_PATIENTS
                      ] || 0,
                      color: colors.success[500],
                      textColor: '#fff',
                    },
                    {
                      title: 'Adults',
                      count: data?.patientStats[
                        TOTAL_ADULT_PATIENTS
                      ] || 0,
                      color: colors.warning[500],
                      textColor: '#fff',
                    },
                    {
                      title: 'Kids',
                      count: data?.patientStats[
                        TOTAL_CHILD_PATIENTS
                      ] || 0,
                      color: colors.success[400],
                      textColor: '#fff',
                    }
                  ]}
                />
              </Grid>
              <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                sx={{
                  mt: (theme) => theme.spacing(3),
                }}
              >
                <Suspense fallback={
                  <SkeletonLoader
                    skeletons={[
                      {
                        variant: 'rectangular',
                        height: 400,
                        animation: 'wave',
                      },
                    ]}
                  />
                }>
                  <StackChart
                    data={
                      data?.patientMonthStats || []
                    }
                    xKey={'month'}
                    yKeys={[
                      TOTAL_MALE_PATIENTS,
                      TOTAL_FEMALE_PATIENTS,
                      TOTAL_OTHER_PATIENTS,
                    ]}
                    chartColors={[
                      colors.error[500],
                      colors.secondary[500],
                      colors.success[500],
                    ]}
                    onClick={(selectedBar) => handleStatsClick(selectedBar)}
                  />
                </Suspense>
              </Grid>
            </Grid>
          </Grid>
          {
            showDetails &&
            (<Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <DataTable
                gridTitle="Patients Details"
                columnDefs={[
                  {
                    headerName: 'Patient Name',
                    field: 'patientName',
                    filter: true,
                  },
                  {
                    headerName: 'Nationality',
                    field: 'nationality',
                    filter: true,
                  },
                  {
                    headerName: 'Gender',
                    field: 'gender',
                    filter: true,
                  },
                  {
                    headerName: 'DOB',
                    field: 'dob',
                    filter: 'agDateColumnFilter',
                    filterParams: dateFilterParam,
                  },
                  {
                    headerName: 'Marital Status',
                    field: 'martialStatus',
                    filter: true,
                  },
                  {
                    headerName: 'Sponsor Name',
                    field: 'sponsorName',
                    filter: true,
                  },
                ]}
                data={patientDetails || []}
                pageSize={15}
                height={300}
                isLoading={patientDetailsLoading || patientDetailsFetching}
                origin={'patients_stats'}
                handleClose={() => {
                  setShowDetails(false);
                }}
              />
            </Grid>)
          }
        </StyledInnerContentCard>
      </Grid>
      <Grid item xs={12} md={2}>
        <PatientsFilter
          onFilterApplied={onFilterApplied}
          onResetFilter={onResetFilter}
        />
      </Grid>
    </StyledContentCard>
  );
};

export default Stats;
