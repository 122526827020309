import {
  PATIENTS_ACTIVITY,
  PATIENTS_DETAILS,
  PATIENTS_STATS,
} from '../constants';

import { apiSlice } from './apiSlice';
import { buildUrlUsingParams } from '../utils/urlformat';
import { createEntityAdapter } from '@reduxjs/toolkit';

const patientsAdapter = createEntityAdapter();

const initialState = patientsAdapter.getInitialState();

export const patientsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatientStats: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(PATIENTS_STATS, payload);
      },
    }),
    getPatientActivity: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(PATIENTS_ACTIVITY, payload);
      },
    }),
    getPatientDetails: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(PATIENTS_DETAILS, payload);
      },
    }),
  }),
});

export const {
  useGetPatientStatsQuery,
  useGetPatientActivityQuery,
  useLazyGetPatientStatsQuery,
  useLazyGetPatientActivityQuery,
  useLazyGetPatientDetailsQuery,
  useGetPatientDetailsQuery,
} = patientsApiSlice;
