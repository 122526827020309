import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import CounterReducer from '../features/counter/counterSlice';
import { apiSlice } from '../api/apiSlice';
import { appointmentsApiSlice } from '../api/appointmentApiSlice';
import authReducer from '../features/auth/authSlice';
import { doctorsApiSlice } from '../api/doctorsApiSlice';
import filtersReducer from '../features/filters/filtersSlice';
import { footFallApiSlice } from '../api/footFallApiSlice';
import { patientsApiSlice } from '../api/patientsApiSlice';
import { revenueApiSlice } from '../api/revenueApiSlice';
import storage from 'redux-persist/lib/storage';

// persisting the store
const persistConfig = {
  key: 'root',
  storage,
};

// Combining all reducers and initializing the store

const combinedReducer = combineReducers({
  counter: CounterReducer,
  auth: authReducer,
  filters: filtersReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [appointmentsApiSlice.reducerPath]: appointmentsApiSlice.reducer,
  [footFallApiSlice.reducerPath]: footFallApiSlice.reducer,
  [patientsApiSlice.reducerPath]: patientsApiSlice.reducer,
  [doctorsApiSlice.reducerPath]: doctorsApiSlice.reducer,
  [revenueApiSlice.reducerPath]: revenueApiSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout/fulfilled') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      apiSlice.middleware,
      appointmentsApiSlice.middleware,
      footFallApiSlice.middleware,
      patientsApiSlice.middleware,
      doctorsApiSlice.middleware,
      revenueApiSlice.middleware,
    ),
  devTools: process.env.NODE_ENV !== 'production',
});

const persistor = persistStore(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// setupListeners(store.dispatch)

export { store, persistor };
