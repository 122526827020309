import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import React, { memo, useState } from 'react';

import LazyImage from './LazyImage';
import PropTypes from 'prop-types';
import styled from '@mui/material/styles/styled';
import "./CardsView.css"
const CardWrapper = styled(Card)(({ active }) => ({
  height: 280,
  width: '100%',
  maxWidth: '300px',
  border: `1.8px solid ${active ? '#015092' : '#8A8A8A'}`,
  borderRadius: 12,
  overflow: 'hidden',
  boxShadow: 'none',
  backgroundColor: '#FFFFFF',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const CardTitle = styled(Typography)(({ active }) => ({
  fontWeight: 500,
  fontSize: '1.3rem',
  lineHeight: '2.5rem',
  color: active ? '#015092' : '#000000',
  textAlign: 'center',
}));

const DividerWrapper = styled(Divider)(({ active }) => ({
  margin: '8px 0',
  border: '1px thin',
  backgroundColor: active ? '#015092' : '#8A8A8A',
  borderColor: active ? '#015092' : '#8A8A8A',
  padding: 0,
}));

const ImageWrapper = styled(Box)(() => ({
  marginTop: '12px',
  height: '185px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    transition: 'all 0.3s ease-in-out',
    transform: 'scale(1.1)',
  },
  '&::-webkit-scrollbar':{
    display:"none"
  },
  transition: 'all 0.3s ease-in-out',
}));

const Image = styled(LazyImage)(({ loaded }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
  filter: loaded ? 'none' : 'blur(20px)',
  transition: 'all 0.3s ease-in-out',
}));

const CardContentWrapper = styled(CardContent)({
  flexGrow: 1,
});

const CardsView = memo(({ cardsData = [], selectedIndex = 0, handleSelectedIndex }) => {
  const [activeIndex, setActiveIndex] = useState(selectedIndex);

  const handleCardClick = (index) => {
    setActiveIndex(index);
    handleSelectedIndex(index);
  };

  return (
    <Box sx={{width:"100%",display: "-webkit-box",overflowX:cardsData.length > 4 ?"auto":"hidden"}} className="cards-view">
      {cardsData.map((card, index) => (
        <Box sx={{width:cardsData.length > 4 ? "21%":"23%",margin:"5px 24px 5px auto"}}>
          <CardWrapper onClick={() => handleCardClick(index)} active={activeIndex === index}>
            <CardContentWrapper>
              <CardTitle active={activeIndex === index}>{card.title}</CardTitle>
              <Box sx={{ padding: 0, mx: '-16px' }}>
                <DividerWrapper active={activeIndex === index} />
              </Box>
              <ImageWrapper>
                <Image src={card.image} alt={card.title} effect='blur' height={185} />
              </ImageWrapper>
            </CardContentWrapper>
          </CardWrapper>
        </Box>
      ))}
    </Box>
  );
});

CardsView.propTypes = {
  cardsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedIndex: PropTypes.number,
  handleSelectedIndex: PropTypes.func.isRequired,
};

export default CardsView;
