import PropTypes from 'prop-types';
import React from 'react';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(({ spacing }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  margin: spacing || 0,
}));

const SkeletonLoader = ({ skeletons }) => {
  const renderSkeletons = (skeletons) => {
    return skeletons.map((skeleton, index) => {
      const { style, spacing, children, ...skeletonProps } = skeleton;
      const skeletonVariant = skeletonProps.variant || 'rectangular';
      const finalHeight = skeletonProps.height || 100;
      const finalWidth = skeletonProps.width || '100%';
      const skeletonStyle = {
        ...style,
        borderRadius:
          skeletonVariant === 'circular' ? '50%' : style?.borderRadius,
      };
      return (
        <StyledDiv key={index} spacing={spacing} style={style}>
          <Skeleton
            animation={skeletonProps.animation || 'wave'}
            variant={skeletonVariant}
            height={finalHeight}
            width={finalWidth}
            style={skeletonStyle}
          />
          {children && (
            <StyledDiv style={style}>{renderSkeletons(children)}</StyledDiv>
          )}
        </StyledDiv>
      );
    });
  };

  return <>{renderSkeletons(skeletons)}</>;
};

SkeletonLoader.propTypes = {
  skeletons: PropTypes.arrayOf(
    PropTypes.shape({
      variant: PropTypes.oneOf(['text', 'circle', 'rectangular']),
      height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      style: PropTypes.object,
      spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      children: PropTypes.array,
      animation: PropTypes.oneOf(['pulse', 'wave', false]),
    }),
  ).isRequired,
};

export default SkeletonLoader;
