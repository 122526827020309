import { APPOINTMENTS_DETAILS } from '../constants';

const buildAppointmentsDetailsUrl = (
  branchName,
  departmentName,
  consultantName,
  userName,
  status,
  startDate,
  endDate,
) => {
  const queryParams = [];
  if (branchName) {
    queryParams.push(`branchName=${branchName}`);
  }
  if (departmentName) {
    queryParams.push(`departmentName=${departmentName}`);
  }
  if (consultantName) {
    queryParams.push(`consultantName=${consultantName}`);
  }
  if (userName) {
    queryParams.push(`userName=${userName}`);
  }
  if (status) {
    queryParams.push(`status=${status}`);
  }
  if (startDate) {
    queryParams.push(`startDate=${startDate}`);
  }
  if (endDate) {
    queryParams.push(`endDate=${endDate}`);
  }
  return `${APPOINTMENTS_DETAILS}?${queryParams.join('&')}`;
};

// write a function to build dynamic url if params are passed else return the static url

const buildUrlUsingParams = (url, params) => {
  if (params) {
    const queryParams = [];
    for (const [key, value] of Object.entries(params)) {
      if (value !== '' && value !== undefined && value !== null) {
        if (value.includes('&')) {
          // if value contains & then adding escape character
          queryParams.push(`${key}=${value.replace(/&/g, '%26')}`);
        } else {
          queryParams.push(`${key}=${value}`);
        }
      }
    }

    if (queryParams.length > 0) {
      return `${url}?${queryParams.join('&')}`;
    } else {
      return url;
    }
  }
  return url;
};

export { buildAppointmentsDetailsUrl, buildUrlUsingParams };
