import dayjs from 'dayjs';

const defaultStartDate = dayjs().subtract(1, 'year');
const defaultEndDate = dayjs();

const getStartAndEndDates = (month) => {
  if (!month || month === '') {
    const startDate = dayjs().startOf('month');
    const endDate = dayjs().endOf('month');
    return {
      startDate: startDate.format('DD-MM-YYYY'),
      endDate: endDate.format('DD-MM-YYYY'),
    };
  }

  const year = month.split('-')[0];
  const monthName = month.split('-')[1];

  const startDate = dayjs(`${year}-${monthName}-01`);
  const endDate = startDate.endOf('month');

  return {
    startDate: startDate.format('DD-MM-YYYY'),
    endDate: endDate.format('DD-MM-YYYY'),
  };
};

export { defaultStartDate, defaultEndDate, getStartAndEndDates };
