import { Box } from '@mui/material';
import ProgressiveImage from 'react-progressive-graceful-image';
import React from 'react';
import { styled } from '@mui/material/styles';

const PlcHolder = styled('img')({
  background:
    'linear-gradient(to right, rgb(246, 247, 248) 0%, rgb(237, 238, 241) 20%, rgb(246, 247, 248) 40%, rgb(246, 247, 248) 100%)',
  animation: 'placeHolderShimmer 1.5s ease-in-out infinite',
  animationName: 'placeHolderShimmer',
  '@keyframes placeHolderShimmer': {
    '0%': {
      backgroundPosition: 'top right',
    },
    '100%': {
      backgroundPosition: 'top left',
    },
  },
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  position: 'absolute',
  backgroundSize: '2250px 2250px !important',
  backgroundRepeat: 'no-repeat !important',
  backgroundPosition: '0 0 !important',
});

const ImageParent = styled(Box)(({ width, height }) => ({
  position: 'relative',
  width: '100%',
  paddingBottom: `${(height / width) * 100}%`,
}));

const StyledImage = styled('img')({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  position: 'absolute',
});

const LazyImage = ({
  width = '100%',
  height = 185,
  src,
  placeholder,
  alt = 'Image',
}) => {
  return (
    <ImageParent width={width} height={height}>
      <ProgressiveImage
        srcSetData={{
          srcSet: src,
        }}
        src={src}
        placeholder={placeholder}
      >
        {(src, loading, srcSetData) => {
          return !placeholder ? (
            loading || !srcSetData ? (
              <PlcHolder
                src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' width%3D'200' height%3D'150' viewBox%3D'0 0 200 150'%2F%3E"
                w={width}
                h={height}
              />
            ) : (
              <StyledImage
                srcSet={srcSetData.srcSet}
                sizes={srcSetData.sizes}
                src={src}
                alt={alt}
              />
            )
          ) : (
            <StyledImage
              srcSet={srcSetData?.srcSet}
              style={{ filter: loading ? 'blur(15px)' : 'blur(0px)' }}
              sizes={srcSetData?.sizes}
              src={src}
              alt={alt}
            />
          );
        }}
      </ProgressiveImage>
    </ImageParent>
  );
};

export default LazyImage;
