import { IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from "@mui/icons-material/Close";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const CustomDialog = styled(Dialog)(({ theme }) => ({
    maxWidth: "100%",
    "& .MuiDialog-paper": {
        minWidth: "30%",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
}));

const AutocompleteDialog = ({
    options,
    onClose,
    onSelect,
    open,
    title,
    selectedOption: selectedOptionProp,
    label,
}) => {
    const [value, setValue] = useState(null);
    const [selectedOption, setSelectedOption] = useState(selectedOptionProp);

    const handleClose = () => {
        onClose();
    };

    const handleSelect = (event, newValue) => {
        setValue(newValue);
        onSelect(newValue);
    };

    useEffect(() => {
        setSelectedOption(selectedOptionProp);
        setValue(selectedOptionProp);
    }, [selectedOptionProp]);

    return (
        <CustomDialog open={open} onClose={handleClose} sx={{
            minHeight: "60vh",
            maxHeight: "lg",
        }}>
            <CustomDialogTitle
                id="modalTitle"
                onClose={(event) => handleClose(event)}
            >
                <Typography sx={{ fontWeight: 600, fontSize: '17px' }}>
                    {title}
                </Typography>
            </CustomDialogTitle>
            <CustomDialogContent
                sx={{
                    paddingTop: '5px !important'
                }}
            >
                <Autocomplete
                    sx={{
                        width: '75%',
                        height: '100%',
                        '& .MuiAutocomplete-inputRoot': {
                            padding: '0px 8px',
                            // borderRadius: '25px',
                        },
                    }}
                    size='small'
                    value={value}
                    defaultValue={selectedOption}
                    options={options || []}
                    getOptionLabel={(option) => option.label}
                    onChange={handleSelect}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params}
                            label={label}
                            placeholder={label}
                        />
                    )}
                />
            </CustomDialogContent>
        </CustomDialog>
    );
};

export default AutocompleteDialog;
