import { memo, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Skeleton } from '@mui/material';
import { graphColors } from '../../../theme/colors';

const ChartType = {
  PIE: 'pie',
  DONUT: 'donut',
};

const PieChart = memo(
  ({
    chartType = ChartType.PIE,
    options = {},
    data = [],
    height = 450,
    borderRadius = 10,
    innerRadius = chartType === ChartType.PIE ? 0 : '50%',
    showToolbar = true,
    enableZoom = true,
    showLabels = true,
    showLegend = true,
    xKey = 'x',
    yKey = 'y',
    onClick
  }) => {
    const chartSeries = useMemo(() => {
      return data.map((item) => item[yKey]);
    }, [data, yKey]);

    const chartOptions = useMemo(() => {
      return {
        chart: {
          type: chartType,
          height,
          toolbar: {
            show: showToolbar,
          },
          events: {
            dataPointSelection: function (event, chartContext, config) {
              const selectedSlice = config.dataPointIndex;
              onClick(selectedSlice)
            }
          },
          zoom: {
            enabled: enableZoom,
          },
          ...options,
        },
        plotOptions: {
          [chartType]: {
            borderRadius,
            startAngle: -90,
            endAngle: 270,
            expandOnClick: false,
            dataLabels: {
              enabled: showLabels,
              offset: 0,
              minAngleToShowLabel: 10,
            },
            innerRadius,
          },
        },
        labels: data.map((item) => item[xKey]),
        colors: graphColors,
        legend: {
          show: showLegend,
        },
        ...options,
      };
    }, [
      options,
      height,
      showToolbar,
      enableZoom,
      data,
      xKey,
      borderRadius,
      chartType,
      innerRadius,
      showLabels,
      showLegend,
    ]);

    const [display, setDisplay] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDisplay(true);
      }, 1);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    return (
      <div style={{ width: '100%' }}>
        {display ? (
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type={chartType}
            height={height}
          />
        ) : (
          <Skeleton variant="rectangular" animation="wave" height={height} />
        )}
      </div>
    );
  },
);

PieChart.propTypes = {
  data: PropTypes.array.isRequired,
  xKey: PropTypes.string.isRequired,
  yKey: PropTypes.string.isRequired,
  options: PropTypes.object,
  height: PropTypes.number,
  showToolbar: PropTypes.bool,
  enableZoom: PropTypes.bool,
  borderRadius: PropTypes.number,
  showLabels: PropTypes.bool,
};

export default PieChart;
