import { Box, CssBaseline, Drawer, Toolbar, styled } from '@mui/material';

import Header from '../Header/Header';
import MenuBar from '../MenuBar/MenuBar';
import { Outlet } from 'react-router-dom';
import menuItems from './menuItems';

const drawerWidth = 240;

const ContentWrapper = styled('div')({
  flexGrow: 1,
  height: '100vh',
  backgroundColor: '#f6f5f5',
  overflow: 'auto',
  padding: '25px',
});

const NavBar = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#015092',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.03)',
            backdropFilter: 'blur(2px)',
            color: '#FFFFFF',
          },
          '& .MuiList-root': {
            paddingTop: '0',
            paddingBottom: '0',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <MenuBar menuItems={menuItems} />
        </Box>
      </Drawer>
      {/* Page Content goes here */}
      <ContentWrapper>
        <Toolbar />
        <Outlet />
      </ContentWrapper>
    </Box>
  );
};

export default NavBar;
