import React, { memo, useMemo } from 'react';

import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Skeleton } from '@mui/material';
import { graphColors } from '../../../theme/colors';

const TreeMap = memo(
  ({
    data = [],
    height = 350,
    borderRadius = 10,
    chartColors = graphColors,
    xKey = 'x',
    yKey = 'y',
  }) => {
    const chartSeries = useMemo(() => {
      return [
        {
          data: data.map((item) => ({
            x: item[xKey],
            y: item[yKey],
          })),
        },
      ];
    }, [data, xKey, yKey]);

    const chartOptions = useMemo(() => {
      return {
        chart: {
          type: 'treemap',
          height: height,
        },
        title: {
          text: '',
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
            nodeHighlightBehavior: 'descendants',
            enableAnimation: true,
            colorScale: {
              ranges: chartColors.map((color, index) => ({
                from: index * (100 / chartColors.length),
                to: (index + 1) * (100 / chartColors.length),
                color: color,
              })),
            },
            borderRadius: borderRadius,
            breadcrumb: {
              show: false,
            },
            zoom: true,
          },
        },
      };
    }, [height, chartColors, borderRadius]);

    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
      const timer = setTimeout(() => {
        setDisplay(true);
      }, 1);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    return (
      <div style={{ width: '100%', height }}>
        {display ? (
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="treemap"
            height={height}
          />
        ) : (
          <Skeleton variant="rectangular" animation="wave" height={height} />
        )}
      </div>
    );
  },
);

TreeMap.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.number,
  borderRadius: PropTypes.number,
  chartColors: PropTypes.arrayOf(PropTypes.string),
};

export default TreeMap;
