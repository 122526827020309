import { Box, Card, Grid, Typography, styled } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { colors } from '../../theme/colors';

const StyledDatePicker = styled(DatePicker)(({ styles }) => ({
    width: "100%",
    borderRadius: 10,
    '& .MuiOutlinedInput-root': {
        height: 35,
        fontSize: '0.75vw',
        width: "100%"
    },
    '& .MuiSvgIcon-root ': {
        fontSize: '1vw',
    },
}));

const CustomDatePicker = ({
    heading = 'Date Filter',
    currentSelectedDate,
    handleSelection
}) => {
    const [selectedDate, setSelectedDate] = useState(currentSelectedDate);

    useEffect(() => {
        setSelectedDate(currentSelectedDate);
    }, [currentSelectedDate]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        handleSelection(date);
    };

    return (
        <Card
            sx={{
                height: 'auto',
                width: '80%',
                boxShadow: 'none',
                border: '1px solid #1c74be',
                // borderRadius: '8px',
            }}
        >
            <Box
                sx={{
                    height: '30px',
                    backgroundColor: '#1c74be',
                    fontSize: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '5px',
                }}
            >
                <div>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            color: colors.white[100]
                        }}
                    >
                        {heading}
                    </Typography>
                </div>
            </Box>
            <Box
                sx={{
                    backgroundColor: "#FFFFFF",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                }}
            >
                <Grid container spacing={0} alignItems="center">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StyledDatePicker
                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                    </LocalizationProvider>
                </Grid>
            </Box>
        </Card>
    );
};

export default CustomDatePicker;
