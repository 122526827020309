import { Suspense, lazy, useCallback, useState } from 'react';

import CardsView from '../../common/CardsView/CardsView';
import DateFilter from '../../common/DateFilter/DateFilter';
import { Grid } from '@mui/material';

const Stats = lazy(() => import('./Stats/Stats'));
const Forecast = lazy(() => import('./Forecast/Forecast'));
const Details = lazy(() => import('./Details/Details'));
const Activity = lazy(() => import('./Activity/Activity'));

const Lab = () => {
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  const handleSelectedIndex = useCallback((index) => {
    setSelectedCardIndex(index);
  }, []);

  return (
    <>
      <Grid container display="flex" flexDirection="column" spacing={5}>
        <Grid item>
          <CardsView
            cardsData={[
              {
                id: 1,
                title: 'Stats',
                image: '/card1.png',
              },
              {
                id: 2,
                title: 'Activity',
                image: '/card2.png',
              },
              {
                id: 3,
                title: 'Details',
                image: '/card3.png',
              },
              {
                id: 4,
                title: 'Forecast',
                image: '/card1.png',
              },
            ]}
            handleSelectedIndex={handleSelectedIndex}
            selectedIndex={selectedCardIndex}
          />
        </Grid>
        <Grid item container justifyContent="center">
          <DateFilter />
        </Grid>
        {selectedCardIndex === 0 && (
          <Grid item container>
            <Suspense fallback={<div>Loading...</div>}>
              <Stats />
            </Suspense>
          </Grid>
        )}
        {selectedCardIndex === 1 && (
          <Grid item container>
            <Suspense fallback={<div>Loading...</div>}>
              <Activity />
            </Suspense>
          </Grid>
        )}
        {selectedCardIndex === 2 && (
          <Grid item container>
            <Suspense fallback={<div>Loading...</div>}>
              <Details />
            </Suspense>
          </Grid>
        )}
        {selectedCardIndex === 3 && (
          <Grid item container>
            <Suspense fallback={<div>Loading...</div>}>
              <Forecast />
            </Suspense>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Lab;
