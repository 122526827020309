import { DOCTORS_ACTIVITY, DOCTORS_DETAILS, DOCTORS_STATS } from '../constants';

import { apiSlice } from './apiSlice';
import { buildUrlUsingParams } from '../utils/urlformat';
import { createEntityAdapter } from '@reduxjs/toolkit';

const doctorsAdapter = createEntityAdapter();

const initialState = doctorsAdapter.getInitialState();

export const doctorsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDoctorsStats: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(DOCTORS_STATS, payload);
      },
    }),
    getDoctorsActivity: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(DOCTORS_ACTIVITY, payload);
      },
    }),
    getDoctorsDetails: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(DOCTORS_DETAILS, payload);
      },
    }),
  }),
});

export const {
  useGetDoctorsStatsQuery,
  useGetDoctorsActivityQuery,
  useLazyGetDoctorsStatsQuery,
  useLazyGetDoctorsActivityQuery,
  useLazyGetDoctorsDetailsQuery,
  useGetDoctorsDetailsQuery,
} = doctorsApiSlice;
