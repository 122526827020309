import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_URL } from '../config';

// Define a service using a base URL and expected endpoints

const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}`,
  // credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: [], // TODO: add tagTypes here to refresh and refetch
  endpoints: (builder) => ({}),
});
