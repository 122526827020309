import { Grid, Skeleton, Typography } from '@mui/material';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { dateFilterParam, trimSpaces } from '../../../utils';
import { defaultEndDate, defaultStartDate } from '../../../utils/date-time';
import { filtersSelector, resetIsFilterApplied } from '../../../features/filters/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetAppointmentActivityQuery, useLazyGetAppointmentDetailsQuery } from '../../../api/appointmentApiSlice';

import AppointmentsFilter from '../AppointmentsFilter/AppointmentsFilter';
import ContentCard from '../../../common/ContentCard/ContentCard';
import DataTable from '../../../common/DataTable/DataTable';
import { styled } from '@mui/material/styles';
import useSelectedFiltersOptions from '../../../hooks/useSelectedFiltersOptions';

const LineChart = lazy(() => import('../../../common/Charts/LineChart/LineChart'));
const BarChart = lazy(() => import('../../../common/Charts/BarChart/BarChart'));
const PieChart = lazy(() => import('../../../common/Charts/PieChart/PieChart'));
const TreeMap = lazy(() => import('../../../common/Charts/TreeMap/TreeMap'));

const StyledContentCard = styled(ContentCard)({
    borderRadius: '20px',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
});

const StyledInnerContentCard = styled(ContentCard)({
    borderRadius: '20px',
    margin: '15px 0px',
});

const Heading = styled(Typography)({
    fontWeight: 500,
    fontSize: 25,
    color: '#000000',
});

const Activity = () => {
    const dispatch = useDispatch();
    const {
        selectedBranch,
        selectedDepartment,
        selectedConsultant,
        selectedStatus,
        selectedStartDate,
        selectedEndDate
    } = useSelector(filtersSelector);

    const options = useMemo(() => {
        const {
            branchName,
            departmentName,
            consultantName,
            status,
            startDate,
            endDate,
        } = {
            branchName: selectedBranch ? trimSpaces(selectedBranch.label) : '',
            departmentName: selectedDepartment ? trimSpaces(selectedDepartment.label) : '',
            consultantName: selectedConsultant ? trimSpaces(selectedConsultant.label) : '',
            status: selectedStatus ? trimSpaces(selectedStatus.label) : '',
            startDate: selectedStartDate.format('DD-MM-YYYY'),
            endDate: selectedEndDate.format('DD-MM-YYYY'),
        };

        return {
            branchName,
            departmentName,
            consultantName,
            status,
            startDate,
            endDate,
        };
    }, [selectedBranch, selectedDepartment, selectedConsultant, selectedStatus, selectedStartDate, selectedEndDate]);

    const [showDepartmentDetails, setShowDepartmentDetails] = useState(false)
    const [showDetailsByStatus, setShowDetailsByStatus] = useState(false)

    /**
     * TODO: later handle error and isLoading..
     */
    const [getAppointmentActivity, { data }] = useLazyGetAppointmentActivityQuery();

    const [
        getAppointmentDetails,
        {
            data: appointmentDetailsData,
            isLoading: appointmentDetailsIsLoading,
            isFetching: appointmentDetailsIsFetching,
            error: appointmentDetailsError
        },
    ] = useLazyGetAppointmentDetailsQuery();

    const optionsFromHook = useSelectedFiltersOptions(selectedBranch,
        selectedDepartment,
        selectedConsultant,
        selectedStatus,
        selectedStartDate,
        selectedEndDate);

    useEffect(() => {
        getAppointmentActivity(optionsFromHook);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFilterApplied = () => {
        getAppointmentActivity(optionsFromHook);
        dispatch(resetIsFilterApplied());
    };

    const onResetFilter = () => {
        getAppointmentActivity({
            startDate: defaultStartDate.format('DD-MM-YYYY'),
            endDate: defaultEndDate.format('DD-MM-YYYY'),
        });
    };

    const handleDepartmentBarClick = (selectedBar) => {
        setShowDepartmentDetails(true);
        const selectedDepartmentName = data?.appointmentsDeptActivities && data?.appointmentsDeptActivities[selectedBar]?.['Department Name'];
        getAppointmentDetails({
            ...options,
            departmentName: selectedDepartmentName,
        });
    }

    const handleStatusSliceClick = (selectedSlice) => {
        setShowDetailsByStatus(true);
        const selectedStatusName = data?.appointmentByStatusActivities
            && data?.appointmentByStatusActivities
            [selectedSlice]?.['Status'];
        getAppointmentDetails({
            ...options,
            status: selectedStatusName,
        });
    }

    return (
        <StyledContentCard>
            <Grid item xs={12} md={10}>
                {/* Branch Wise Appointments */}
                <Grid container justifyContent="center">
                    <StyledInnerContentCard>
                        <Grid
                            container
                            justifyContent="center"
                            sx={{
                                py: (theme) => theme.spacing(5),
                            }}
                        >
                            <Heading variant="h5">Branch Wise Appointments</Heading>
                        </Grid>
                        <Grid
                            container
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                            spacing={2}
                            sx={{
                                px: (theme) => theme.spacing(10),
                            }}
                        >
                            <Suspense
                                fallback={
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        height={400}
                                    />
                                }
                            >
                                <LineChart
                                    data={data?.appointmentBranchActivity?.branchHashMapList || []}
                                    xKey="x"
                                    yKeys={
                                        data?.appointmentBranchActivity?.branches || []
                                    }
                                    height={400}
                                    curveType="smooth"
                                    strokeWidth={2}
                                    markerSize={6}
                                    gridLineColor="#ccc"
                                />
                            </Suspense>
                        </Grid>
                    </StyledInnerContentCard>
                </Grid>

                {/* Department Wise Appointments */}
                <Grid container justifyContent="center">
                    <StyledInnerContentCard>
                        <Grid
                            container
                            justifyContent="center"
                            sx={{
                                py: (theme) => theme.spacing(5),
                            }}
                        >
                            <Heading variant="h5">Department Wise Appointments</Heading>
                        </Grid>
                        {/* 
                            Chart cards here  we will show cards and charts
                        */}
                        <Grid
                            container
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                            spacing={2}
                            sx={{
                                px: (theme) => theme.spacing(10),
                            }}
                        >
                            <Suspense fallback={
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    height={400}
                                />
                            }>
                                <BarChart
                                    data={
                                        data?.appointmentsDeptActivities || []
                                    }
                                    isHorizontal={false}
                                    height={400}
                                    xKey="Department Name"
                                    yKey="Department Total Appointments"
                                    onClick={(selectedBar) => handleDepartmentBarClick(selectedBar)}
                                />
                            </Suspense>
                        </Grid>
                        {
                            showDepartmentDetails &&
                            (<Grid
                                container
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                                spacing={2}
                                sx={{
                                    px: (theme) => theme.spacing(10),
                                }}
                            >
                                <DataTable
                                    gridTitle="Department Wise Appointments"
                                    columnDefs={[
                                        {
                                            headerName: 'Booking Date',
                                            field: 'bookingDate',
                                            filter: 'agDateColumnFilter',
                                            filterParams: dateFilterParam,
                                        },
                                        {
                                            headerName: 'Branch Name',
                                            field: 'branchName',
                                            filter: true,
                                        },
                                        {
                                            headerName: 'Department Name',
                                            field: 'departmentName',
                                            filter: true,
                                        },
                                        { headerName: 'Doctor Name', field: 'doctorName', filter: true, },
                                        { headerName: 'Appointment Status', field: 'appointmentStatus', filter: true, },
                                        { headerName: 'Created By User Name', field: 'createdByUserName', filter: true, },
                                    ]}
                                    data={appointmentDetailsData || []}
                                    pageSize={15}
                                    height={300}
                                    isLoading={appointmentDetailsIsFetching}
                                    origin={'appointments_activity'}
                                    handleClose={() => {
                                        setShowDepartmentDetails(false);
                                    }}
                                />
                            </Grid>)
                        }
                    </StyledInnerContentCard>
                </Grid>

                {/* Consultant Wise Appointments */}
                <Grid container justifyContent="center">
                    <StyledInnerContentCard>
                        <Grid
                            container
                            justifyContent="center"
                            sx={{
                                py: (theme) => theme.spacing(5),
                            }}
                        >
                            <Heading variant="h5">Consultant Wise Appointments</Heading>
                        </Grid>
                        <Grid
                            container
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                            spacing={2}
                            sx={{
                                px: (theme) => theme.spacing(10),
                            }}
                        >
                            <Suspense
                                fallback={
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        height={400}
                                    />
                                }
                            >
                                <TreeMap
                                    data={
                                        data?.appointmentsConsultantActivities || []
                                    }
                                    height={'400px'}
                                    xKey="Consultant Name"
                                    yKey="Consultant Total Appointments"
                                    onClick={(selectedBar) =>
                                        console.log("selectedBar ::", selectedBar)
                                    }
                                />
                            </Suspense>
                        </Grid>
                    </StyledInnerContentCard>
                </Grid>

                {/* Appointments By Status */}
                <Grid container justifyContent="center">
                    <StyledInnerContentCard>
                        <Grid
                            container
                            justifyContent="center"
                            sx={{
                                py: (theme) => theme.spacing(5),
                            }}
                        >
                            <Heading variant="h5">Appointments By Status</Heading>
                        </Grid>
                        <Grid
                            container
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                            spacing={2}
                            sx={{
                                px: (theme) => theme.spacing(10),
                            }}
                        >
                            <Suspense fallback={
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    height={400}
                                />
                            }>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <PieChart
                                        data={data?.appointmentByStatusActivities || []}
                                        height={400}
                                        xKey="Status"
                                        yKey="Total Appointments"
                                        onClick={(selectedSlice) => handleStatusSliceClick(selectedSlice)}
                                    />
                                </div>
                            </Suspense>
                        </Grid>
                        {
                            showDetailsByStatus &&
                            (<Grid
                                container
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                                spacing={2}
                                sx={{
                                    px: (theme) => theme.spacing(10),
                                }}
                            >
                                <DataTable
                                    gridTitle="Department Wise Appointments"
                                    columnDefs={[
                                        {
                                            headerName: 'Booking Date',
                                            field: 'bookingDate',
                                            filter: 'agDateColumnFilter',
                                            filterParams: dateFilterParam,
                                        },
                                        {
                                            headerName: 'Branch Name',
                                            field: 'branchName',
                                            filter: true,
                                        },
                                        {
                                            headerName: 'Department Name',
                                            field: 'departmentName',
                                            filter: true,
                                        },
                                        { headerName: 'Doctor Name', field: 'doctorName', filter: true, },
                                        { headerName: 'Appointment Status', field: 'appointmentStatus', filter: true, },
                                        { headerName: 'Created By User Name', field: 'createdByUserName', filter: true, },
                                    ]}
                                    data={appointmentDetailsData || []}
                                    pageSize={15}
                                    height={300}
                                    isLoading={appointmentDetailsIsFetching}
                                    origin={'appointments_activity'}
                                    handleClose={() => {
                                        setShowDetailsByStatus(false);
                                    }}
                                />
                            </Grid>)
                        }
                    </StyledInnerContentCard>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                md={2}
            >
                <AppointmentsFilter
                    onFilterApplied={onFilterApplied}
                    onResetFilter={onResetFilter}
                />
            </Grid>
        </StyledContentCard>
    );
};

export default Activity;
