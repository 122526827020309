import { Box, Typography } from '@mui/material';

import React from 'react';
import { formatNumberToCurrency } from '../../utils';
import { styled } from '@mui/material/styles';

const CountCard = ({
  title = 'Title',
  count = 0,
  color = '#F5F5F5',
  width = '75%',
  height = 65,
  textColor = '#000',
}) => {
  const StyledDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  });

  const StyledCard = styled(Box)({
    borderRadius: 5,
    width: width,
    height: height,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color,
    border: '1px solid rgba(225, 225, 225, 0.5)',
    boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(1px)',
  });

  return (
    <StyledDiv>
      <StyledCard>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            fontSize: 24,
            textAlign: 'center',
            color: textColor,
            letterSpacing: '0.01em',
          }}
        >
          {formatNumberToCurrency(count ? count : 0)}
        </Typography>
      </StyledCard>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '0.5rem',
        }}
      >
        <Box
          sx={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: color,
            marginRight: 1,
          }}
        />
        <Typography
          variant="h6"
          sx={{
            color: '#000000',
            fontWeight: 500,
            fontSize: 16,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </Box>
    </StyledDiv>
  );
};

export default CountCard;
