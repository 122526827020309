import { Box, Button, Grid, Stack, TextField, styled } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useState } from "react";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ContentCard from "../ContentCard/ContentCard";
import dayjs from 'dayjs';

const StyledContentCard = styled(ContentCard)({
    borderRadius: '20px',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
});

function useDateRange(initialStartDate = null, initialEndDate = null) {
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [hasChanged, setHasChanged] = useState(false);
    const isResetEnabled = (startDate && startDate.format('DD-MM-YYYY') !== initialStartDate.format('DD-MM-YYYY')) || (endDate && endDate.format('DD-MM-YYYY') !== initialEndDate.format('DD-MM-YYYY'));

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setHasChanged((date && date.format('DD-MM-YYYY') !== initialStartDate.format('DD-MM-YYYY')) || (endDate && endDate.format('DD-MM-YYYY') !== initialEndDate.format('DD-MM-YYYY')));
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setHasChanged((startDate && startDate.format('DD-MM-YYYY') !== initialStartDate.format('DD-MM-YYYY')) || (date && date.format('DD-MM-YYYY') !== initialEndDate.format('DD-MM-YYYY')));
    };

    const handleReset = () => {
        setStartDate(initialStartDate);
        setEndDate(initialEndDate);
        setHasChanged(false);
    };

    const handleHasChanged = () => {
        setHasChanged(false);
    };

    return {
        startDate,
        endDate,
        handleStartDateChange,
        handleEndDateChange,
        isResetEnabled,
        handleReset,
        hasChanged,
        handleHasChanged
    };
}

function DateRange({
    onDateRangeChange,
}) {

    const defaultEndDate = dayjs()
    // one year ago
    const defaultStartDate = dayjs().subtract(1, 'year');

    const {
        startDate,
        endDate,
        handleStartDateChange,
        handleEndDateChange,
        isResetEnabled,
        handleReset,
        hasChanged,
        handleHasChanged
    } = useDateRange(
        defaultStartDate,
        defaultEndDate
    );

    const handleApply = () => {
        onDateRangeChange({
            startDate: startDate.format('DD-MM-YYYY'),
            endDate: endDate.format('DD-MM-YYYY'),
        });
        handleHasChanged();
    };

    const handleResetBtn = () => {
        onDateRangeChange({
            startDate: defaultStartDate.format('DD-MM-YYYY'),
            endDate: defaultEndDate.format('DD-MM-YYYY'),
        });
        handleReset();
    };

    return (
        <StyledContentCard>
            <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={1} lg={1}>
                    <Box textAlign="center">-</Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                renderInput={(params) => <TextField {...params} />}
                                style={{ height: "32px" }}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleApply}
                        disabled={!hasChanged}
                        size="medium"
                        fullWidth
                    >
                        Apply
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleResetBtn}
                        disabled={!isResetEnabled || hasChanged}
                        size="medium"
                        fullWidth
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>

        </StyledContentCard>
    );
}

export default DateRange;
