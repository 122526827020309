import {
  FOOTFALL_ACTIVITY,
  FOOTFALL_DETAILS,
  FOOTFALL_STATS,
} from '../constants';

import { apiSlice } from './apiSlice';
import { buildUrlUsingParams } from '../utils/urlformat';
import { createEntityAdapter } from '@reduxjs/toolkit';

const footFallAdapter = createEntityAdapter();

const initialState = footFallAdapter.getInitialState();

export const footFallApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFootFallStats: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(FOOTFALL_STATS, payload);
      },
    }),
    getFootFallActivity: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(FOOTFALL_ACTIVITY, payload);
      },
    }),
    getFootFallDetails: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(FOOTFALL_DETAILS, payload);
      },
    }),
  }),
});

export const {
  useGetFootFallStatsQuery,
  useGetFootFallActivityQuery,
  useLazyGetFootFallStatsQuery,
  useLazyGetFootFallActivityQuery,
  useLazyGetFootFallDetailsQuery,
  useGetFootFallDetailsQuery,
} = footFallApiSlice;
