import ContentCard from '../../../common/ContentCard/ContentCard';
import DataTable from '../../../common/DataTable/DataTable';
import DateRange from '../../../common/DateRange/DateRange';
import { Grid } from '@mui/material';
import { dateFilterParam } from '../../../utils';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { useGetAppointmentDetailsQuery } from '../../../api/appointmentApiSlice';
import { useState } from 'react';

const StyledContentCard = styled(ContentCard)({
    borderRadius: '20px',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
});

const Details = () => {
    const [dateRange, setDateRange] = useState({
        startDate: moment().subtract(1, 'years').format('DD-MM-YYYY'),
        endDate: moment().format('DD-MM-YYYY'),
    });
    // eslint-disable-next-line no-unused-vars
    const { data, error, isLoading, isFetching } = useGetAppointmentDetailsQuery(dateRange);

    const handleDateRangeChange = (newDateRange) => {
        setDateRange(newDateRange);
    };

    return (
        <Grid container display="flex" flexDirection="column" spacing={5}>
            <Grid item container justifyContent="center">
                <DateRange
                    onDateRangeChange={handleDateRangeChange}
                />
            </Grid>
            <Grid item container>
                <StyledContentCard>
                    <Grid item xs={12} md={12}>
                        <DataTable
                            gridTitle="Appointments Details"
                            columnDefs={[
                                {
                                    headerName: 'Booking Date',
                                    field: 'bookingDate',
                                    filter: 'agDateColumnFilter',
                                    filterParams: dateFilterParam,
                                },
                                {
                                    headerName: 'Branch Name',
                                    field: 'branchName',
                                    filter: true,
                                },
                                {
                                    headerName: 'Department Name',
                                    field: 'departmentName',
                                    filter: true,
                                },
                                { headerName: 'Doctor Name', field: 'doctorName', filter: true, },
                                { headerName: 'Appointment Status', field: 'appointmentStatus', filter: true, },
                                { headerName: 'Created By User Name', field: 'createdByUserName', filter: true, },
                            ]}
                            data={data && data.length > 0 ? data : []}
                            pageSize={15}
                            height={500}
                            isLoading={isFetching}
                        />
                    </Grid>
                </StyledContentCard>
            </Grid>
        </Grid>
    );
};

export default Details;
