import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { defaultEndDate, defaultStartDate } from '../../../utils/date-time';
import { filtersSelector, resetIsFilterApplied } from '../../../features/filters/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetClaimActivityQuery, useLazyGetClaimDetailsQuery } from '../../../api/claimApiSlice';

import BarChart from '../../../common/Charts/BarChart/BarChart';
import ClaimFilter from '../ClaimFilter/ClaimFilter';
import ContentCard from '../../../common/ContentCard/ContentCard';
import DataTable from '../../../common/DataTable/DataTable';
import LineChart from '../../../common/Charts/LineChart/LineChart';
import PieChart from '../../../common/Charts/PieChart/PieChart';
import { styled } from '@mui/material/styles';
import { trimSpaces } from '../../../utils';

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const StyledInnerContentCard = styled(ContentCard)({
  borderRadius: '20px',
  margin: '15px 0px',
});

const Heading = styled(Typography)({
  fontWeight: 500,
  fontSize: 25,
  color: '#000000',
});

const StyledFilterWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(1)};
  margin-top: ${(props) => props.theme.spacing(6)};
`;

const Activity = () => {
  const dispatch = useDispatch();
  const {
    selectedBranch,
    selectedDepartment,
    selectedClaimStatus,
    selectedStartDate,
    selectedEndDate
  } = useSelector(filtersSelector);

  const options = useMemo(() => {
    const {
      branchName,
      departmentName,
      status,
      startDate,
      endDate,
    } = {
      branchName: selectedBranch ? trimSpaces(selectedBranch.label) : '',
      departmentName: selectedDepartment ? trimSpaces(selectedDepartment.label) : '',
      status: selectedClaimStatus ? trimSpaces(selectedClaimStatus.label) : '',
      startDate: selectedStartDate.format('DD-MM-YYYY'),
      endDate: selectedEndDate.format('DD-MM-YYYY'),
    };

    return {
      branchName,
      departmentName,
      status,
      startDate,
      endDate,
    };
  }, [selectedBranch, selectedDepartment, selectedClaimStatus, selectedStartDate, selectedEndDate]);

  const [showDepartmentDetails, setShowDepartmentDetails] = useState(false)
  const [showDetailsByService, setShowDetailsByService] = useState(false)
  const [showDetailsByTop5Departments, setShowDetailsByTop5Departments] = useState(false)
  const [showDetailsByTop5InsuranceProvider, setShowDetailsByTop5InsuranceProvider] = useState(false)
  const [showDetailsByTop5PendingClaims, setShowDetailsByTop5PendingClaims] = useState(false)
  const [showDetailsByTop5WriteOffClaims, setShowDetailsByTop5WriteOffClaims] = useState(false)

  /**
   * TODO: later handle error and isLoading..
   */
  const [getClaimActivity, { data }] = useLazyGetClaimActivityQuery();

  useEffect(() => {
    getClaimActivity(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterApplied = () => {
    getClaimActivity(options);
    dispatch(resetIsFilterApplied());
  };

  const onResetFilter = () => {
    getClaimActivity({
      startDate: defaultStartDate.format('DD-MM-YYYY'),
      endDate: defaultEndDate.format('DD-MM-YYYY'),
    });
  };

  const [
    getClaimDetails,
    { data: claimDetails,
      error: claimDetailsError,
      isLoading: claimDetailsLoading,
      isFetching: claimDetailsFetching
    },
  ] = useLazyGetClaimDetailsQuery();

  const handleDepartmentBarClick = (selectedBar) => {
    setShowDepartmentDetails(true)
    setShowDetailsByService(false)
    setShowDetailsByTop5Departments(false)
    setShowDetailsByTop5InsuranceProvider(false)
    const selectedDepartmentName = data?.claimDeptActivities && data?.claimDeptActivities[selectedBar]?.['deptName'];
    getClaimDetails({
      ...options,
      departmentName: `${selectedDepartmentName}`
    });
  }

  const handleServiceSliceClick = (selectedSlice) => {
    setShowDepartmentDetails(false)
    setShowDetailsByService(true)
    setShowDetailsByTop5Departments(false)
    setShowDetailsByTop5InsuranceProvider(false)
    const selectedStatusName = data?.claimServiceActivities
      && data?.claimServiceActivities
      [selectedSlice]?.['serviceName'];
    getClaimDetails({
      ...options,
      serviceName: selectedStatusName,
    });
  }

  const handleTop5DeptBarClick = (selectedBar) => {
    setShowDepartmentDetails(false)
    setShowDetailsByService(false)
    setShowDetailsByTop5Departments(true)
    setShowDetailsByTop5InsuranceProvider(false)
    const selectedDepartmentName = data?.topDeptClaimsActivity && data?.topDeptClaimsActivity[selectedBar]?.['claimName'];
    getClaimDetails({
      ...options,
      departmentName: `${selectedDepartmentName}`
    });
  }

  const handleTop5SponsorBarClick = (selectedBar) => {
    setShowDepartmentDetails(false)
    setShowDetailsByService(false)
    setShowDetailsByTop5Departments(false)
    setShowDetailsByTop5InsuranceProvider(true)
    const selectedSponsorName = data?.topSponsorClaimActivity && data?.topSponsorClaimActivity[selectedBar]?.['claimName'];
    getClaimDetails({
      ...options,
      sponsorName: `${selectedSponsorName}`
    });
  }

  return (
    <StyledContentCard>
      <Grid item xs={12} md={10}>
        {/* Branch Wise Claims */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Branch Wise Claims</Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense
                fallback={
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={400}
                  />
                }
              >
                <LineChart
                  data={
                    data?.claimBranchWiseActivity.branchHashMapList || []
                  }
                  xKey="x"
                  yKeys={
                    data?.claimBranchWiseActivity.branches || []
                  }
                  height={400}
                  curveType="smooth"
                  strokeWidth={2}
                  markerSize={6}
                  gridLineColor="#ccc"
                />
              </Suspense>
            </Grid>
          </StyledInnerContentCard>
        </Grid>

        {/* Department Wise Claims */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Department Wise Claims</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={
                <Skeleton variant='rectangular' animation='wave' height={400} />
              }>
                <BarChart
                  data={
                    data?.claimDeptActivities || []
                  }
                  isHorizontal={false}
                  height={400}
                  xKey="deptName"
                  yKey="totalClaimed"
                  onClick={(selectedBar) => handleDepartmentBarClick(selectedBar)}
                />
              </Suspense>
            </Grid>
            {
              showDepartmentDetails &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="Claim Details"
                  columnDefs={[
                    {
                      headerName: 'Claim Id',
                      field: 'claimId',
                      filter: true,
                    },
                    {
                      headerName: 'Invoice No',
                      field: 'invoiceNo',
                      filter: true,
                    },
                    {
                      headerName: 'Branch Name',
                      field: 'branchName',
                      filter: true,
                    },
                    {
                      headerName: 'Department Name',
                      field: 'deptName',
                      filter: true,
                    },
                    {
                      headerName: 'Service Name',
                      field: 'serviceName',
                      filter: true,
                    },
                    { headerName: 'Claim Amount', field: 'claimAmount', filter: true, },
                    { headerName: 'Rejected Amount', field: 'rejectAmount', filter: true, },
                    { headerName: 'Approved Amount', field: 'approvedAmount', filter: true, },
                    { headerName: 'Denial Code', field: 'denialCode', filter: true, },
                    { headerName: 'Invoice Claim Status', field: 'invoiceClaimStatus', filter: true, },
                  ]}
                  data={claimDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={claimDetailsLoading || claimDetailsFetching}
                  origin={'claim_stats'}
                  handleClose={() => {
                    setShowDepartmentDetails(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>

        {/* Service Wise Claims */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center"
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Service Wise Claims</Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={400}
                />
              }>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {/* <NivoPie
                    data={pieChartFormatter(
                      {
                        'Service 1': 100,
                        'Service 2': 200,
                        'Service 3': 300,
                        'Service 4': 400,
                        'Service 5': 500,
                      },
                      'service',
                      'totalClaims',
                    )}
                    height={400}
                    xKey="service"
                    yKey="totalClaims"
                  /> */}
                  <PieChart
                    data={data?.claimServiceActivities || []}
                    height={400}
                    xKey="serviceName"
                    yKey="totalClaimed"
                    showLegend={false}
                    onClick={(selectedSlice) => handleServiceSliceClick(selectedSlice)}
                  />
                </div>
              </Suspense>
            </Grid>
            {
              showDetailsByService &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="Claim Details"
                  columnDefs={[
                    {
                      headerName: 'Claim Id',
                      field: 'claimId',
                      filter: true,
                    },
                    {
                      headerName: 'Invoice No',
                      field: 'invoiceNo',
                      filter: true,
                    },
                    {
                      headerName: 'Branch Name',
                      field: 'branchName',
                      filter: true,
                    },
                    {
                      headerName: 'Department Name',
                      field: 'deptName',
                      filter: true,
                    },
                    {
                      headerName: 'Service Name',
                      field: 'serviceName',
                      filter: true,
                    },
                    { headerName: 'Claim Amount', field: 'claimAmount', filter: true, },
                    { headerName: 'Rejected Amount', field: 'rejectAmount', filter: true, },
                    { headerName: 'Approved Amount', field: 'approvedAmount', filter: true, },
                    { headerName: 'Denial Code', field: 'denialCode', filter: true, },
                    { headerName: 'Invoice Claim Status', field: 'invoiceClaimStatus', filter: true, },
                  ]}
                  data={claimDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={claimDetailsLoading || claimDetailsFetching}
                  origin={'claim_stats'}
                  handleClose={() => {
                    setShowDetailsByService(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>

        {/* Top 5 Departments By Claims */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Top 5 Departments</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={<Skeleton
                variant="rectangular"
                animation="wave"
                height={400}
              />}>
                <BarChart
                  data={
                    data?.topDeptClaimsActivity || []
                  }
                  isHorizontal={true}
                  height={400}
                  xKey="claimName"
                  yKey="totalAmount"
                  onClick={(selectedBar) => handleTop5DeptBarClick(selectedBar)}
                />
              </Suspense>
            </Grid>
            {
              showDetailsByTop5Departments &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="Claim Details"
                  columnDefs={[
                    {
                      headerName: 'Claim Id',
                      field: 'claimId',
                      filter: true,
                    },
                    {
                      headerName: 'Invoice No',
                      field: 'invoiceNo',
                      filter: true,
                    },
                    {
                      headerName: 'Branch Name',
                      field: 'branchName',
                      filter: true,
                    },
                    {
                      headerName: 'Department Name',
                      field: 'deptName',
                      filter: true,
                    },
                    {
                      headerName: 'Service Name',
                      field: 'serviceName',
                      filter: true,
                    },
                    { headerName: 'Claim Amount', field: 'claimAmount', filter: true, },
                    { headerName: 'Rejected Amount', field: 'rejectAmount', filter: true, },
                    { headerName: 'Approved Amount', field: 'approvedAmount', filter: true, },
                    { headerName: 'Denial Code', field: 'denialCode', filter: true, },
                    { headerName: 'Invoice Claim Status', field: 'invoiceClaimStatus', filter: true, },
                  ]}
                  data={claimDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={claimDetailsLoading || claimDetailsFetching}
                  origin={'claim_stats'}
                  handleClose={() => {
                    setShowDetailsByTop5Departments(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>

        {/* Top 5 Insurance Providers By Claims */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Top 5 Insurance Providers</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={<Skeleton
                variant="rectangular"
                animation="wave"
                height={400}
              />}>
                <BarChart
                  data={
                    data?.topSponsorClaimActivity || []
                  }
                  isHorizontal={true}
                  height={400}
                  xKey="claimName"
                  yKey="totalAmount"
                  onClick={(selectedBar) => handleTop5SponsorBarClick(selectedBar)}
                />
              </Suspense>
            </Grid>
            {
              showDetailsByTop5InsuranceProvider &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="Claim Details"
                  columnDefs={[
                    {
                      headerName: 'Claim Id',
                      field: 'claimId',
                      filter: true,
                    },
                    {
                      headerName: 'Invoice No',
                      field: 'invoiceNo',
                      filter: true,
                    },
                    {
                      headerName: 'Branch Name',
                      field: 'branchName',
                      filter: true,
                    },
                    {
                      headerName: 'Department Name',
                      field: 'deptName',
                      filter: true,
                    },
                    {
                      headerName: 'Service Name',
                      field: 'serviceName',
                      filter: true,
                    },
                    { headerName: 'Claim Amount', field: 'claimAmount', filter: true, },
                    { headerName: 'Rejected Amount', field: 'rejectAmount', filter: true, },
                    { headerName: 'Approved Amount', field: 'approvedAmount', filter: true, },
                    { headerName: 'Denial Code', field: 'denialCode', filter: true, },
                    { headerName: 'Invoice Claim Status', field: 'invoiceClaimStatus', filter: true, },
                  ]}
                  data={claimDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={claimDetailsLoading || claimDetailsFetching}
                  origin={'claim_stats'}
                  handleClose={() => {
                    setShowDetailsByTop5InsuranceProvider(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>

        {/* Top 5 Pending Claims By Insurance Providers */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">
                Top 5 Pending Claims By Insurance Providers
              </Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={<Skeleton
                variant="rectangular"
                animation="wave"
                height={400}
              />}>
                <BarChart
                  data={
                    data?.topSponsorPendingActivity || []
                  }
                  isHorizontal={true}
                  height={400}
                  xKey="claimName"
                  yKey="totalAmount"
                />
              </Suspense>
            </Grid>
            {
              showDepartmentDetails &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="Claim Details"
                  columnDefs={[
                    {
                      headerName: 'Claim Id',
                      field: 'claimId',
                      filter: true,
                    },
                    {
                      headerName: 'Invoice No',
                      field: 'invoiceNo',
                      filter: true,
                    },
                    {
                      headerName: 'Branch Name',
                      field: 'branchName',
                      filter: true,
                    },
                    {
                      headerName: 'Department Name',
                      field: 'deptName',
                      filter: true,
                    },
                    {
                      headerName: 'Service Name',
                      field: 'serviceName',
                      filter: true,
                    },
                    { headerName: 'Claim Amount', field: 'claimAmount', filter: true, },
                    { headerName: 'Rejected Amount', field: 'rejectAmount', filter: true, },
                    { headerName: 'Approved Amount', field: 'approvedAmount', filter: true, },
                    { headerName: 'Denial Code', field: 'denialCode', filter: true, },
                    { headerName: 'Invoice Claim Status', field: 'invoiceClaimStatus', filter: true, },
                  ]}
                  data={claimDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={claimDetailsLoading || claimDetailsFetching}
                  origin={'claim_stats'}
                  handleClose={() => {
                    setShowDepartmentDetails(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>

        {/* Top 5 Write Off Claims By Insurance Providers */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center"
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">
                Top 5 Write Off Claims By Insurance Providers
              </Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={<Skeleton
                variant="rectangular"
                animation="wave"
                height={400}
              />}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <BarChart
                    data={
                      data?.topWriteOffActivity || []
                    }
                    height={400}
                    xKey="sponsorName"
                    yKey="totalWriteoff"
                  />
                </div>
              </Suspense>
            </Grid>
            {
              showDepartmentDetails &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="Claim Details"
                  columnDefs={[
                    {
                      headerName: 'Claim Id',
                      field: 'claimId',
                      filter: true,
                    },
                    {
                      headerName: 'Invoice No',
                      field: 'invoiceNo',
                      filter: true,
                    },
                    {
                      headerName: 'Branch Name',
                      field: 'branchName',
                      filter: true,
                    },
                    {
                      headerName: 'Department Name',
                      field: 'deptName',
                      filter: true,
                    },
                    {
                      headerName: 'Service Name',
                      field: 'serviceName',
                      filter: true,
                    },
                    { headerName: 'Claim Amount', field: 'claimAmount', filter: true, },
                    { headerName: 'Rejected Amount', field: 'rejectAmount', filter: true, },
                    { headerName: 'Approved Amount', field: 'approvedAmount', filter: true, },
                    { headerName: 'Denial Code', field: 'denialCode', filter: true, },
                    { headerName: 'Invoice Claim Status', field: 'invoiceClaimStatus', filter: true, },
                  ]}
                  data={claimDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={claimDetailsLoading || claimDetailsFetching}
                  origin={'claim_stats'}
                  handleClose={() => {
                    setShowDepartmentDetails(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <ClaimFilter
          onFilterApplied={onFilterApplied}
          onResetFilter={onResetFilter}
        />
      </Grid>
    </StyledContentCard>
  );
};

export default Activity;
