import { Box, Card, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import AutocompleteDialog from '../AutocompleteDialog/AutocompleteDialog';
import { colors } from '../../theme/colors';

const FilterC = ({
    heading = 'Filter',
    options = [],
    selectTitle = 'Select',
    handleSelection,
    selectedOption: selectedOptionProp,
}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(
        selectedOptionProp || null
    );

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        setSelectedOption(selectedOptionProp);
    }, [selectedOptionProp]);

    const handleSelectOption = (option) => {
        setSelectedOption(option);
        setDialogOpen(false);
        handleSelection(option);
    };

    return (
        <Card
            sx={{
                height: 'auto',
                width: '80%',
                boxShadow: 'none',
                border: '1px solid #1c74be',
            }}
        >
            <Box
                sx={{
                    height: '30px',
                    backgroundColor: '#1c74be',
                    fontSize: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '5px',
                }}
            >
                <div>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            color: colors.white[100]
                        }}
                    >
                        {heading}
                    </Typography>
                </div>
            </Box>
            <Box
                sx={{
                    backgroundColor: "#FFFFFF",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                    overflow: "hidden",
                }}
            >
                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={10}>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                color: "#116EBB",
                                fontWeight: 400,
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            title={selectedOption ? selectedOption.label : selectTitle}
                        >
                            {selectedOption ? selectedOption.label : selectTitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} alignContent="center">
                        <ArrowDropDownOutlinedIcon
                            sx={{
                                color: "#116EBB",
                                cursor: "pointer",
                            }}
                            onClick={handleOpenDialog}
                        />
                    </Grid>
                </Grid>
            </Box>
            <AutocompleteDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                title={heading}
                label={selectTitle}
                onSelect={handleSelectOption}
                options={options}
                selectedOption={selectedOption}
            />
        </Card>
    );
};

export default FilterC;
