import { trimSpaces } from '../utils';
import { useMemo } from 'react';

function useSelectedFiltersOptions(
  selectedBranch,
  selectedDepartment,
  selectedConsultant,
  selectedStatus,
  selectedStartDate,
  selectedEndDate,
) {
  const options = useMemo(() => {
    const branchName = trimSpaces(selectedBranch?.label) ?? '';
    const departmentName = trimSpaces(selectedDepartment?.label) ?? '';
    const consultantName = trimSpaces(selectedConsultant?.label) ?? '';
    const status = trimSpaces(selectedStatus?.label) ?? '';
    const startDate = selectedStartDate?.format('DD-MM-YYYY') ?? '';
    const endDate = selectedEndDate?.format('DD-MM-YYYY') ?? '';

    return {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    };
  }, [
    selectedBranch,
    selectedDepartment,
    selectedConsultant,
    selectedStatus,
    selectedStartDate,
    selectedEndDate,
  ]);

  return options;
}

export default useSelectedFiltersOptions;
