const dashboardReport = {
  totalVisits: 3862520,
  firstTimeVisits: 1328148,
  followUpVisits: 2786198,
  totalRevisitCount: 0,
  totalConsultations: 4115183,
  totalNonConsultations: 402147,
  consultationsByDepartment: [
    {
      appointmentsByDepartment: 1005,
      department: 'Physiotherapy'
    },
    {
      appointmentsByDepartment: 10159,
      department: 'Emergency'
    },
    {
      appointmentsByDepartment: 3766,
      department: 'Dermatolog-Raheema'
    },
    {
      appointmentsByDepartment: 81,
      department: 'OBG-DAMMAM3'
    },
    {
      appointmentsByDepartment: 7093,
      department: 'Dermatology'
    },
    {
      appointmentsByDepartment: 14159,
      department: 'Medicine-Faysliyah'
    },
    {
      appointmentsByDepartment: 8295,
      department: 'Dermatolog-Faysliyah'
    },
    {
      appointmentsByDepartment: 2549,
      department: 'Orthopadic'
    },
    {
      appointmentsByDepartment: 10801,
      department: 'Paediatric'
    },
    {
      appointmentsByDepartment: 12811,
      department: 'Dental'
    },
    {
      appointmentsByDepartment: 105,
      department: 'Opthalmology'
    },
    {
      appointmentsByDepartment: 165,
      department: 'Urology'
    },
    {
      appointmentsByDepartment: 322,
      department: 'Psychotherapy'
    },
    {
      appointmentsByDepartment: 7343,
      department: 'Derma'
    },
    {
      appointmentsByDepartment: 6009,
      department: 'ENT'
    },
    {
      appointmentsByDepartment: 8550,
      department: 'Dermatolog-Doha'
    },
    {
      appointmentsByDepartment: 72,
      department: 'Opthalmoology'
    },
    {
      appointmentsByDepartment: 1918,
      department: 'Dermatolog-Basamat'
    },
    {
      appointmentsByDepartment: 15163,
      department: 'Dental-Basamat'
    },
    {
      appointmentsByDepartment: 5403,
      department: 'Dermatolog-DAMMAM3'
    },
    {
      appointmentsByDepartment: 2,
      department: 'Radiology Services'
    },
    {
      appointmentsByDepartment: 162,
      department: 'E N T'
    },
    {
      appointmentsByDepartment: 17728,
      department: 'Dental-DAMMAM3'
    },
    {
      appointmentsByDepartment: 4535,
      department: 'OBG-Faisalaya'
    },
    {
      appointmentsByDepartment: 1240,
      department: 'Surgery'
    },
    {
      appointmentsByDepartment: 4286,
      department: 'OBG'
    },
    {
      appointmentsByDepartment: 2210,
      department: 'Orthopedic'
    },
    {
      appointmentsByDepartment: 28792,
      department: 'DENTAL'
    },
    {
      appointmentsByDepartment: 1439,
      department: 'Cardiology'
    },
    {
      appointmentsByDepartment: 13857,
      department: 'Dental-Doha'
    },
    {
      appointmentsByDepartment: 1490,
      department: 'Medicine-Doha'
    },
    {
      appointmentsByDepartment: 5370,
      department: 'Ophthalmology'
    },
    {
      appointmentsByDepartment: 137,
      department: 'Medicine-DAMMAM3'
    },
    {
      appointmentsByDepartment: 26461,
      department: 'DERMATOLOGY'
    },
    {
      appointmentsByDepartment: 7,
      department: 'Emergency Room'
    },
    {
      appointmentsByDepartment: 1764,
      department: 'Dermatology-Swiss'
    },
    {
      appointmentsByDepartment: 8730,
      department: 'General Medical'
    },
    {
      appointmentsByDepartment: 353,
      department: 'Neurology'
    },
    {
      appointmentsByDepartment: 6625,
      department: 'Dental-Raheema'
    },
    {
      appointmentsByDepartment: 1183,
      department: 'Emergency Room '
    },
    {
      appointmentsByDepartment: 9,
      department: 'NEUROLOGY'
    },
    {
      appointmentsByDepartment: 22286,
      department: 'Dental-Faysliyah'
    }
  ],
  consultationsByDoctor: [
    {
      visitsByDoctor: 1384,
      doctor: 'MADHAN GUNASEELAN'
    },
    {
      visitsByDoctor: 881,
      doctor: 'Amira'
    },
    {
      visitsByDoctor: 963,
      doctor: 'Mai Mohammad Abdalraufe Salman'
    },
    {
      visitsByDoctor: 24,
      doctor: 'Haydra Facial Facial'
    },
    {
      visitsByDoctor: 236,
      doctor: 'Mohammed Kafafy'
    },
    {
      visitsByDoctor: 2169,
      doctor: 'Husayn Ahmad Burhan'
    },
    {
      visitsByDoctor: 3145,
      doctor: 'Ahmed Qasem Teama'
    },
    {
      visitsByDoctor: 2179,
      doctor: 'Thany Mahmud Abdalhamed Ahmad'
    },
    {
      visitsByDoctor: 6,
      doctor: 'Fatimah Al khlaif'
    },
    {
      visitsByDoctor: 489,
      doctor: 'Amani Abdel-Baqi'
    },
    {
      visitsByDoctor: 65,
      doctor: 'Hassan El-Sayed'
    },
    {
      visitsByDoctor: 29,
      doctor: 'Ahmed Allam'
    },
    {
      visitsByDoctor: 572,
      doctor: 'Afnan Mouad'
    },
    {
      visitsByDoctor: 1467,
      doctor: 'Mohamed  Albakor'
    },
    {
      visitsByDoctor: 404,
      doctor: 'Ebrahim  Bakeer'
    },
    {
      visitsByDoctor: 140,
      doctor: 'Wafa  Ghoul'
    },
    {
      visitsByDoctor: 11,
      doctor: 'Islam Hany Mokhtar Eissa'
    },
    {
      visitsByDoctor: 1134,
      doctor: 'Rasha Farouk'
    },
    {
      visitsByDoctor: 5,
      doctor: 'Rafeef Bugis'
    },
    {
      visitsByDoctor: 59,
      doctor: 'Nada Abd Albaqi'
    },
    {
      visitsByDoctor: 94,
      doctor: 'Noor Almustafa'
    },
    {
      visitsByDoctor: 362,
      doctor: 'Sara Ahmed'
    },
    {
      visitsByDoctor: 335,
      doctor: 'Dueto Three'
    },
    {
      visitsByDoctor: 1867,
      doctor: 'Reem Abdelkawy Hassan Khater'
    },
    {
      visitsByDoctor: 38,
      doctor: 'Fatina Attieh'
    },
    {
      visitsByDoctor: 39,
      doctor: 'Iman Al-Qaishawi'
    },
    {
      visitsByDoctor: 2553,
      doctor: 'Ghousia Akbari Abdulwajid Khan'
    },
    {
      visitsByDoctor: 3151,
      doctor: 'RAVI SHUKLA'
    },
    {
      visitsByDoctor: 88,
      doctor: 'Haya Vhor Bhai'
    },
    {
      visitsByDoctor: 290,
      doctor: 'Mohammed Salman'
    },
    {
      visitsByDoctor: 33,
      doctor: 'Exillis'
    },
    {
      visitsByDoctor: 1463,
      doctor: 'Marwa Bader'
    },
    {
      visitsByDoctor: 27,
      doctor: 'Sahab Najeeb'
    },
    {
      visitsByDoctor: 354,
      doctor: 'Mohamed Abdul Bary'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Iman Al-Mushrif'
    },
    {
      visitsByDoctor: 1698,
      doctor: 'Rhona Montenegro Sonsona'
    },
    {
      visitsByDoctor: 1824,
      doctor: 'Hussain Sharif'
    },
    {
      visitsByDoctor: 1496,
      doctor: 'SARY SAYED'
    },
    {
      visitsByDoctor: 855,
      doctor: 'Abir Yassin'
    },
    {
      visitsByDoctor: 405,
      doctor: 'Mohammed Mahdi'
    },
    {
      visitsByDoctor: 5,
      doctor: 'Basma Sger'
    },
    {
      visitsByDoctor: 25,
      doctor: 'Amal Alqahtany'
    },
    {
      visitsByDoctor: 1,
      doctor: 'CLARAMMA ARIMALIL PROTHASICS'
    },
    {
      visitsByDoctor: 2753,
      doctor: 'AUDAI MOIEN AMMARI'
    },
    {
      visitsByDoctor: 28,
      doctor: 'Samir Mohamad Awama'
    },
    {
      visitsByDoctor: 142,
      doctor: 'Amal Eysa'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Jane BASANGAN'
    },
    {
      visitsByDoctor: 170,
      doctor: 'Maha Arisha'
    },
    {
      visitsByDoctor: 5,
      doctor: 'Maram Al-Awami'
    },
    {
      visitsByDoctor: 2440,
      doctor: 'AHMED ELFEKY'
    },
    {
      visitsByDoctor: 37,
      doctor: 'Omaimah Alnemer'
    },
    {
      visitsByDoctor: 11,
      doctor: 'Moaz Alhaj'
    },
    {
      visitsByDoctor: 2094,
      doctor: 'Mutab Al Mahmoud'
    },
    {
      visitsByDoctor: 45,
      doctor: 'Mayyada Saleem'
    },
    {
      visitsByDoctor: 516,
      doctor: 'Adnan Mostafa'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Sarah El Tessan'
    },
    {
      visitsByDoctor: 67,
      doctor: 'Iman Nour Udeen'
    },
    {
      visitsByDoctor: 924,
      doctor: 'GENTLE TWO MACHINE'
    },
    {
      visitsByDoctor: 2230,
      doctor: 'DUETTU'
    },
    {
      visitsByDoctor: 1641,
      doctor: 'Ali Al Moghazy'
    },
    {
      visitsByDoctor: 105,
      doctor: 'Omar Bashter'
    },
    {
      visitsByDoctor: 15,
      doctor: 'Hazim mohammed'
    },
    {
      visitsByDoctor: 1088,
      doctor: 'Dina atef'
    },
    {
      visitsByDoctor: 43,
      doctor: 'Infini'
    },
    {
      visitsByDoctor: 16,
      doctor: 'Reem Qassem'
    },
    {
      visitsByDoctor: 1009,
      doctor: 'Abdelrahman Abdulgaffar  Abduldaem'
    },
    {
      visitsByDoctor: 92,
      doctor: 'Amal Ali'
    },
    {
      visitsByDoctor: 26,
      doctor: 'Mohamad Tashkandi'
    },
    {
      visitsByDoctor: 437,
      doctor: 'Ali Al abbas'
    },
    {
      visitsByDoctor: 50,
      doctor: 'sini Sunny'
    },
    {
      visitsByDoctor: 1781,
      doctor: 'Essam AlBadawi'
    },
    {
      visitsByDoctor: 333,
      doctor: 'GentleLase Pro one'
    },
    {
      visitsByDoctor: 2692,
      doctor: 'Rana mohammed'
    },
    {
      visitsByDoctor: 1302,
      doctor: 'Sadeq Qodceiah'
    },
    {
      visitsByDoctor: 1669,
      doctor: 'DUETTO2'
    },
    {
      visitsByDoctor: 5,
      doctor: 'Maza khair'
    },
    {
      visitsByDoctor: 46,
      doctor: 'Bushra Ismail'
    },
    {
      visitsByDoctor: 77,
      doctor: 'ABDULRAHMAN khaleel'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Rehab Muhammad'
    },
    {
      visitsByDoctor: 4623,
      doctor: 'Spiro Hanna Asbr Shuhud'
    },
    {
      visitsByDoctor: 12,
      doctor: 'Radwan Ikrimah Radwan Alnabelsi'
    },
    {
      visitsByDoctor: 560,
      doctor: 'Alaa Mustafa Khatib'
    },
    {
      visitsByDoctor: 52,
      doctor: 'Hydrafacial'
    },
    {
      visitsByDoctor: 991,
      doctor: 'Ameen'
    },
    {
      visitsByDoctor: 2168,
      doctor: 'Yazan Mamoun Said Tabba'
    },
    {
      visitsByDoctor: 100,
      doctor: 'Sozan'
    },
    {
      visitsByDoctor: 512,
      doctor: 'Renny Thankachan'
    },
    {
      visitsByDoctor: 19,
      doctor: 'Hawraa Jafar'
    },
    {
      visitsByDoctor: 522,
      doctor: 'Rasha Mostafa'
    },
    {
      visitsByDoctor: 177,
      doctor: 'Wejdan Almobarak'
    },
    {
      visitsByDoctor: 171,
      doctor: 'Hydraficial'
    },
    {
      visitsByDoctor: 10,
      doctor: 'Asaad Dighady'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Fatima AlAjmi'
    },
    {
      visitsByDoctor: 19,
      doctor: 'Sultan Alqahtani'
    },
    {
      visitsByDoctor: 2363,
      doctor: 'Nour Al Jazzar'
    },
    {
      visitsByDoctor: 2829,
      doctor: 'MAHMOUD ABDULMAWLA'
    },
    {
      visitsByDoctor: 1947,
      doctor: 'MAHMOUD HAGGAG ABDELMALEK'
    },
    {
      visitsByDoctor: 1416,
      doctor: 'Zahraa Yousef'
    },
    {
      visitsByDoctor: 3,
      doctor: 'Fatima Alnemer'
    },
    {
      visitsByDoctor: 329,
      doctor: 'Ghssan Shamsdeen'
    },
    {
      visitsByDoctor: 2322,
      doctor: 'Reem abu raed'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Bashayer Al Dahlan'
    },
    {
      visitsByDoctor: 35,
      doctor: 'Huda Qandeel'
    },
    {
      visitsByDoctor: 170,
      doctor: 'Zuhair Abbas'
    },
    {
      visitsByDoctor: 1,
      doctor: 'facial'
    },
    {
      visitsByDoctor: 93,
      doctor: 'Eglal Mouhamed Hassan Rajab'
    },
    {
      visitsByDoctor: 1413,
      doctor: 'MOHAMMED HASSAN'
    },
    {
      visitsByDoctor: 613,
      doctor: 'ABDULLAH ALGHAMDI'
    },
    {
      visitsByDoctor: 606,
      doctor: 'Assad Jawed'
    },
    {
      visitsByDoctor: 1605,
      doctor: 'Amani Ameen Khalid Tarbosh'
    },
    {
      visitsByDoctor: 27,
      doctor: 'Dima Mohammad Abdalqader Almr'
    },
    {
      visitsByDoctor: 1750,
      doctor: 'Mohamed Omar Abdulqader Emar'
    },
    {
      visitsByDoctor: 3053,
      doctor: 'Ramia Rajendran'
    },
    {
      visitsByDoctor: 8,
      doctor: 'Al Hanoof Al Kabei'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Mohamed Sameir'
    },
    {
      visitsByDoctor: 82,
      doctor: 'Bahiya felo'
    },
    {
      visitsByDoctor: 108,
      doctor: 'Haydra Facial'
    },
    {
      visitsByDoctor: 104,
      doctor: 'Nahed Derar'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Housam AlHalaki'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Elham Nou'
    },
    {
      visitsByDoctor: 642,
      doctor: 'Mahdi Ghazwi'
    },
    {
      visitsByDoctor: 1074,
      doctor: 'Mahmoud Abubakr'
    },
    {
      visitsByDoctor: 4322,
      doctor: 'Waheed alahmad'
    },
    {
      visitsByDoctor: 1674,
      doctor: 'MOHAMMED ADEL ELSAYED'
    },
    {
      visitsByDoctor: 6,
      doctor: 'Amira Farghli'
    },
    {
      visitsByDoctor: 573,
      doctor: 'Saba Sflo'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Samah ElBadrawi'
    },
    {
      visitsByDoctor: 927,
      doctor: 'Fatima ali'
    },
    {
      visitsByDoctor: 1061,
      doctor: 'Adnan Mustafa'
    },
    {
      visitsByDoctor: 437,
      doctor: 'hosam nasab'
    },
    {
      visitsByDoctor: 1310,
      doctor: 'Rania abdukhalek'
    },
    {
      visitsByDoctor: 74,
      doctor: 'Ficial'
    },
    {
      visitsByDoctor: 8,
      doctor: 'Anfal almarzooq'
    },
    {
      visitsByDoctor: 185,
      doctor: 'Ibrahim Yousef Badrasawi'
    },
    {
      visitsByDoctor: 69,
      doctor: 'Haydra FAcial'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Reem Al Khatr'
    },
    {
      visitsByDoctor: 63,
      doctor: 'Yassmin Ahmed Nour'
    },
    {
      visitsByDoctor: 71,
      doctor: 'Ammar ABDULLAH'
    },
    {
      visitsByDoctor: 30,
      doctor: 'MOHAMMED AL NEHAS'
    },
    {
      visitsByDoctor: 905,
      doctor: 'AHMED HEGAZY'
    },
    {
      visitsByDoctor: 5,
      doctor: 'Hussam Omer Ahmed'
    },
    {
      visitsByDoctor: 1180,
      doctor: 'MOHAMED ABDELMAKSOUD'
    },
    {
      visitsByDoctor: 9,
      doctor: 'Cyndi Dano'
    },
    {
      visitsByDoctor: 956,
      doctor: 'Saleh alyafey'
    },
    {
      visitsByDoctor: 60,
      doctor: 'Sheikha Al Ibrahim'
    },
    {
      visitsByDoctor: 221,
      doctor: 'Ramdan Mohamed Tawfiq Ali'
    },
    {
      visitsByDoctor: 1018,
      doctor: 'SHEREN SEDEQ'
    },
    {
      visitsByDoctor: 1413,
      doctor: 'Majedah salama'
    },
    {
      visitsByDoctor: 590,
      doctor: 'Labib Faramawy'
    },
    {
      visitsByDoctor: 220,
      doctor: 'Manar Khater'
    },
    {
      visitsByDoctor: 1406,
      doctor: 'Hisham shokry'
    },
    {
      visitsByDoctor: 303,
      doctor: 'HYDRAFACIAL'
    },
    {
      visitsByDoctor: 387,
      doctor: 'RANIA SABER ALSSAYIS'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Elite'
    },
    {
      visitsByDoctor: 1182,
      doctor: 'Muhanad Mohamad Khir Smiri'
    },
    {
      visitsByDoctor: 1074,
      doctor: 'Suhayla alnajjar'
    },
    {
      visitsByDoctor: 891,
      doctor: 'AMAL HASSAN'
    },
    {
      visitsByDoctor: 25,
      doctor: 'Alan Eisaa Eisaa'
    },
    {
      visitsByDoctor: 184,
      doctor: 'Mona Al Haj'
    },
    {
      visitsByDoctor: 319,
      doctor: 'Abdul Jalil AlBariah'
    },
    {
      visitsByDoctor: 185,
      doctor: 'Ahmd Mhyaldyn Mykry'
    },
    {
      visitsByDoctor: 1251,
      doctor: 'Mohammed Alhajj'
    },
    {
      visitsByDoctor: 54,
      doctor: 'Ahmed Wahba'
    },
    {
      visitsByDoctor: 35,
      doctor: 'Safwan Alghamdi'
    },
    {
      visitsByDoctor: 46,
      doctor: 'Randa Alarbaji'
    },
    {
      visitsByDoctor: 155,
      doctor: 'exilis'
    },
    {
      visitsByDoctor: 5,
      doctor: 'Rasha Mohammad Mohammad Hazen'
    },
    {
      visitsByDoctor: 647,
      doctor: 'Duetto 4'
    },
    {
      visitsByDoctor: 773,
      doctor: 'Hussam Mohammed Alsududi'
    },
    {
      visitsByDoctor: 5,
      doctor: 'Sara Al Sahafi'
    },
    {
      visitsByDoctor: 4,
      doctor: 'Rasha Elnoby'
    },
    {
      visitsByDoctor: 320,
      doctor: 'Khaled Alotaibi'
    },
    {
      visitsByDoctor: 3230,
      doctor: 'Gentle Lase'
    },
    {
      visitsByDoctor: 23,
      doctor: 'Mohammed Saber'
    },
    {
      visitsByDoctor: 55,
      doctor: 'Fouad Qaesha'
    },
    {
      visitsByDoctor: 1401,
      doctor: 'MAHMOUD ABDELAZIZ'
    },
    {
      visitsByDoctor: 1010,
      doctor: 'Duetto'
    },
    {
      visitsByDoctor: 1947,
      doctor: 'Mahmoud Mabrouk'
    },
    {
      visitsByDoctor: 95,
      doctor: 'Aqilah Albahrany'
    },
    {
      visitsByDoctor: 3,
      doctor: 'Amro Hussam'
    },
    {
      visitsByDoctor: 2155,
      doctor: 'Hadeel Azmi Abed Rabou'
    },
    {
      visitsByDoctor: 945,
      doctor: 'Ahlam Darweesh'
    },
    {
      visitsByDoctor: 533,
      doctor: 'GENTLE PRO'
    },
    {
      visitsByDoctor: 8,
      doctor: 'Lobna Mohamed'
    },
    {
      visitsByDoctor: 1220,
      doctor: 'Malak althagafi'
    },
    {
      visitsByDoctor: 2263,
      doctor: 'Shaoeb Khan'
    },
    {
      visitsByDoctor: 1234,
      doctor: 'Mohammed nafe'
    },
    {
      visitsByDoctor: 6072,
      doctor: 'Hadba Husein'
    },
    {
      visitsByDoctor: 59,
      doctor: 'Hetoon hussain'
    },
    {
      visitsByDoctor: 1596,
      doctor: 'Mansour Musa'
    },
    {
      visitsByDoctor: 1727,
      doctor: 'Mai Hussein'
    },
    {
      visitsByDoctor: 263,
      doctor: 'SUNSHINE DELLAVA SUMARIBOS'
    },
    {
      visitsByDoctor: 253,
      doctor: 'Waad Al Mazrouie'
    },
    {
      visitsByDoctor: 1678,
      doctor: 'Nadia Alatrash'
    },
    {
      visitsByDoctor: 15,
      doctor: 'Mohamad Taha Aljassem'
    },
    {
      visitsByDoctor: 113,
      doctor: 'Nora Alsadah'
    },
    {
      visitsByDoctor: 23,
      doctor: 'Mahmoud elati'
    },
    {
      visitsByDoctor: 100,
      doctor: 'Ahmed Abdel Tawab'
    },
    {
      visitsByDoctor: 91,
      doctor: 'Fatemah Al Zahra'
    },
    {
      visitsByDoctor: 286,
      doctor: 'Haitham'
    },
    {
      visitsByDoctor: 2257,
      doctor: 'NAJLA ALI'
    },
    {
      visitsByDoctor: 3,
      doctor: 'Jerman alqahtani'
    },
    {
      visitsByDoctor: 2741,
      doctor: 'Mohamed Mahmoud Metwally Mohamed'
    },
    {
      visitsByDoctor: 483,
      doctor: 'AZZA RABIA ALSHASHTAWI ALSHIN'
    },
    {
      visitsByDoctor: 18,
      doctor: 'Laila Hussein Ismail Alsomali'
    },
    {
      visitsByDoctor: 181,
      doctor: 'Salima Shaaban Eladly'
    },
    {
      visitsByDoctor: 1540,
      doctor: 'Marwa  Mohamed'
    },
    {
      visitsByDoctor: 437,
      doctor: 'Walaa Ghonim'
    },
    {
      visitsByDoctor: 82,
      doctor: 'Eman Abu Ali'
    },
    {
      visitsByDoctor: 1335,
      doctor: 'Mayadh Alabadi'
    },
    {
      visitsByDoctor: 1086,
      doctor: 'Mohammad Kafafy '
    },
    {
      visitsByDoctor: 18,
      doctor: 'Enas Abdulla'
    },
    {
      visitsByDoctor: 156,
      doctor: 'amjad alotaibi'
    },
    {
      visitsByDoctor: 1149,
      doctor: 'Raquel alcachupas'
    },
    {
      visitsByDoctor: 1379,
      doctor: 'Ahlam  Jodah'
    },
    {
      visitsByDoctor: 1385,
      doctor: 'AHMED MOHAMMED ESSAM DESOUKY'
    },
    {
      visitsByDoctor: 71,
      doctor: 'Adnan Alhaji'
    },
    {
      visitsByDoctor: 1086,
      doctor: 'Aliaa Shehata'
    },
    {
      visitsByDoctor: 258,
      doctor: 'Moatz Mohammed'
    },
    {
      visitsByDoctor: 13,
      doctor: 'Spectra Q'
    },
    {
      visitsByDoctor: 1353,
      doctor: 'DUETTO ONE MACHINE'
    },
    {
      visitsByDoctor: 1586,
      doctor: 'HAMS MOHAMMED MAHMOUD'
    },
    {
      visitsByDoctor: 2341,
      doctor: 'Mohamed  Alhajissa'
    },
    {
      visitsByDoctor: 5,
      doctor: 'Farah Sindi'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Sarah Al-Anzi'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Reda Zaki'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Emergency Doctor'
    },
    {
      visitsByDoctor: 19,
      doctor: 'Asmaa ali'
    },
    {
      visitsByDoctor: 2042,
      doctor: 'Ismail Amer'
    },
    {
      visitsByDoctor: 2597,
      doctor: 'Abeer Ahmed Abdulmonem'
    },
    {
      visitsByDoctor: 57,
      doctor: 'Ayman abo baker abo baker'
    },
    {
      visitsByDoctor: 19,
      doctor: 'Zainab Al Abedallah'
    },
    {
      visitsByDoctor: 1515,
      doctor: 'Rafat Al Shawi'
    },
    {
      visitsByDoctor: 72,
      doctor: 'Spectra Lutronic'
    },
    {
      visitsByDoctor: 1405,
      doctor: 'Dueto'
    },
    {
      visitsByDoctor: 1916,
      doctor: 'Mohamed azzat alhaffar'
    },
    {
      visitsByDoctor: 159,
      doctor: 'Mahasin Al Shakhs'
    },
    {
      visitsByDoctor: 2420,
      doctor: 'Shihab Anwar Kader'
    },
    {
      visitsByDoctor: 482,
      doctor: 'Rahma'
    },
    {
      visitsByDoctor: 1733,
      doctor: 'Spectra'
    },
    {
      visitsByDoctor: 361,
      doctor: 'GHADA MANSOUR'
    },
    {
      visitsByDoctor: 8,
      doctor: 'Mhdmohannad Mhd Mustafa Qutrieh'
    },
    {
      visitsByDoctor: 2940,
      doctor: 'Mujib Bilqis'
    },
    {
      visitsByDoctor: 163,
      doctor: 'Fotona'
    },
    {
      visitsByDoctor: 28,
      doctor: 'Zayad Sylan'
    },
    {
      visitsByDoctor: 353,
      doctor: 'Mohamed Hamedo'
    },
    {
      visitsByDoctor: 1275,
      doctor: 'Mohamed Sayed Abouelseoud'
    },
    {
      visitsByDoctor: 1372,
      doctor: 'SHYMA MUHAMMAD DIAB'
    },
    {
      visitsByDoctor: 104,
      doctor: 'Bayan Al Hwsah'
    },
    {
      visitsByDoctor: 163,
      doctor: 'Shereen Alshafie'
    },
    {
      visitsByDoctor: 2425,
      doctor: 'Mohammed Osama'
    },
    {
      visitsByDoctor: 147,
      doctor: 'Gentel Lase 3'
    },
    {
      visitsByDoctor: 1477,
      doctor: 'Roaa Ismael'
    },
    {
      visitsByDoctor: 1416,
      doctor: 'ABDALRAOF AHMAD AL GHAZALY'
    },
    {
      visitsByDoctor: 897,
      doctor: 'Eman abdullah'
    },
    {
      visitsByDoctor: 597,
      doctor: 'Mohammed Fawzi'
    },
    {
      visitsByDoctor: 98,
      doctor: 'souaad alzamaar'
    },
    {
      visitsByDoctor: 313,
      doctor: 'Mohammad Tashkandi'
    },
    {
      visitsByDoctor: 115,
      doctor: 'Jenan Al-ali'
    },
    {
      visitsByDoctor: 9,
      doctor: 'abdulsalam alanazi'
    },
    {
      visitsByDoctor: 20,
      doctor: 'Wid Elmessiri'
    },
    {
      visitsByDoctor: 522,
      doctor: 'Ola Zayed'
    },
    {
      visitsByDoctor: 17,
      doctor: 'Maiy AL Atyah'
    },
    {
      visitsByDoctor: 2623,
      doctor: 'Zaher Mohamad Khachan Jazieh'
    },
    {
      visitsByDoctor: 1648,
      doctor: 'Sabiha Banu Mahboob'
    },
    {
      visitsByDoctor: 2131,
      doctor: 'Mahmud Alshrbyny Mohammad Alshrbyny'
    },
    {
      visitsByDoctor: 1513,
      doctor: 'Afshan  Taraneem'
    },
    {
      visitsByDoctor: 11,
      doctor: 'Dina Ahmad Saleem Alnajjar'
    },
    {
      visitsByDoctor: 1922,
      doctor: 'Asma Diyab'
    },
    {
      visitsByDoctor: 174,
      doctor: 'Hisham Ahmed'
    },
    {
      visitsByDoctor: 1631,
      doctor: 'Sawsan Akhdar'
    },
    {
      visitsByDoctor: 4,
      doctor: 'Alanod alnemari'
    },
    {
      visitsByDoctor: 74,
      doctor: 'Fawaz Alatallah'
    },
    {
      visitsByDoctor: 681,
      doctor: 'ISLAM MOHAMMED MURTADHA'
    },
    {
      visitsByDoctor: 934,
      doctor: 'Sarah Majdy Hassan'
    },
    {
      visitsByDoctor: 18,
      doctor: 'Ammar Adnan Almasri'
    },
    {
      visitsByDoctor: 2270,
      doctor: 'MAMDOUH JAMEA'
    },
    {
      visitsByDoctor: 57,
      doctor: 'Facial'
    },
    {
      visitsByDoctor: 934,
      doctor: 'SALAMAH WASFI ALMASRY'
    },
    {
      visitsByDoctor: 12,
      doctor: 'Diana Ortiz Gundan'
    },
    {
      visitsByDoctor: 1258,
      doctor: 'Mohamed Abd el fatah'
    },
    {
      visitsByDoctor: 9,
      doctor: 'Huda'
    },
    {
      visitsByDoctor: 19,
      doctor: 'Sara Alsayed'
    },
    {
      visitsByDoctor: 168,
      doctor: 'IBRAHIM EL ADGHAM'
    },
    {
      visitsByDoctor: 11,
      doctor: 'Mohannad Rasheed Hamdan'
    },
    {
      visitsByDoctor: 68,
      doctor: 'Rasha Ismail'
    },
    {
      visitsByDoctor: 129,
      doctor: 'Zahraa Alnemer'
    },
    {
      visitsByDoctor: 844,
      doctor: 'Wael Kalil'
    },
    {
      visitsByDoctor: 53,
      doctor: 'JACQUELEAH LANZADERAS'
    },
    {
      visitsByDoctor: 131,
      doctor: 'Duetto Device'
    },
    {
      visitsByDoctor: 24,
      doctor: 'Sharaf Al-Qahtani'
    },
    {
      visitsByDoctor: 74,
      doctor: 'Reham Attia'
    },
    {
      visitsByDoctor: 18,
      doctor: 'Mohamed Amar'
    },
    {
      visitsByDoctor: 709,
      doctor: 'Marwa  Ahmed'
    },
    {
      visitsByDoctor: 15,
      doctor: 'Taqua Ahmed Almomany'
    },
    {
      visitsByDoctor: 193,
      doctor: 'Exilis'
    },
    {
      visitsByDoctor: 20,
      doctor: 'Jumana Alelaiw'
    },
    {
      visitsByDoctor: 795,
      doctor: 'Fouad Qaisha'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Melssa Markiz Madella'
    },
    {
      visitsByDoctor: 7,
      doctor: 'Asrar ALradadi'
    },
    {
      visitsByDoctor: 245,
      doctor: 'Mohammed Al-Nahas'
    },
    {
      visitsByDoctor: 42,
      doctor: 'mustafa alsalem'
    },
    {
      visitsByDoctor: 4,
      doctor: 'Fy Albeeshi'
    },
    {
      visitsByDoctor: 6090,
      doctor: 'Mohammed Fawaz Ghanaeem'
    },
    {
      visitsByDoctor: 95,
      doctor: 'Hussam Al-Habbabi'
    },
    {
      visitsByDoctor: 531,
      doctor: 'Abeer Al Anzi'
    },
    {
      visitsByDoctor: 73,
      doctor: 'khalaf alhowaish'
    },
    {
      visitsByDoctor: 4623,
      doctor: 'Yasser Ahmed Hamed Agameya'
    },
    {
      visitsByDoctor: 2,
      doctor: 'raqiat alkhamis'
    },
    {
      visitsByDoctor: 5,
      doctor: 'Enas Jalal Naim Alkhrissat'
    },
    {
      visitsByDoctor: 165,
      doctor: 'Mohamed Hammam'
    },
    {
      visitsByDoctor: 636,
      doctor: 'Samer mukaimer'
    },
    {
      visitsByDoctor: 147,
      doctor: 'MALAK MANSOUR'
    },
    {
      visitsByDoctor: 493,
      doctor: 'Gentle lase (candela)'
    },
    {
      visitsByDoctor: 1947,
      doctor: 'Ali Alshaikh'
    },
    {
      visitsByDoctor: 21,
      doctor: 'Fatima Al-Zayer'
    },
    {
      visitsByDoctor: 1774,
      doctor: 'Mohamed Hosam AHEMD DWYDREE'
    },
    {
      visitsByDoctor: 12,
      doctor: 'Gentle Lase PRO3 Device'
    },
    {
      visitsByDoctor: 1007,
      doctor: 'Gentle Lase 2'
    },
    {
      visitsByDoctor: 747,
      doctor: 'Manar Helal'
    },
    {
      visitsByDoctor: 970,
      doctor: 'Gental lase 2'
    },
    {
      visitsByDoctor: 690,
      doctor: 'ahmed'
    },
    {
      visitsByDoctor: 1372,
      doctor: 'Heba Fathi Abusamra'
    },
    {
      visitsByDoctor: 3008,
      doctor: 'EHAB ALDAKEN'
    },
    {
      visitsByDoctor: 1056,
      doctor: 'Gental lase 3'
    },
    {
      visitsByDoctor: 1034,
      doctor: 'Nour Al-Azami'
    },
    {
      visitsByDoctor: 379,
      doctor: 'Qais Al-Mousa'
    },
    {
      visitsByDoctor: 4530,
      doctor: 'Omar Qalawi'
    },
    {
      visitsByDoctor: 97,
      doctor: 'Noha Elsayed'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Tahany Mahmoud'
    },
    {
      visitsByDoctor: 1168,
      doctor: 'SOAAD ALKHALD'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Lola Albendary'
    },
    {
      visitsByDoctor: 2200,
      doctor: 'AMIRA MOHAMED MAHMOUD SAID'
    },
    {
      visitsByDoctor: 3295,
      doctor: 'AHMED ALMASRI'
    },
    {
      visitsByDoctor: 941,
      doctor: 'Mohammed Alami'
    },
    {
      visitsByDoctor: 2120,
      doctor: 'Rehab Tolba'
    },
    {
      visitsByDoctor: 468,
      doctor: 'Mohammed monther'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Nida AlSadah'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Reham Bioumy'
    },
    {
      visitsByDoctor: 484,
      doctor: 'Yousef Farah'
    },
    {
      visitsByDoctor: 1215,
      doctor: 'LINA ALABADI'
    },
    {
      visitsByDoctor: 17,
      doctor: 'GENTLELASE PRO'
    },
    {
      visitsByDoctor: 115,
      doctor: 'Salwa Zafar'
    },
    {
      visitsByDoctor: 361,
      doctor: 'Jehad Al Ratrout'
    },
    {
      visitsByDoctor: 28,
      doctor: 'Yaman Adnan Labash'
    },
    {
      visitsByDoctor: 165,
      doctor: 'Haytham'
    },
    {
      visitsByDoctor: 2204,
      doctor: 'Noura Khalid'
    },
    {
      visitsByDoctor: 151,
      doctor: 'Adham Shawqy'
    },
    {
      visitsByDoctor: 15,
      doctor: 'HYDRA FACIAL MACHINE'
    },
    {
      visitsByDoctor: 159,
      doctor: 'Amal Alkhaldi'
    },
    {
      visitsByDoctor: 51,
      doctor: 'EXILIS'
    },
    {
      visitsByDoctor: 22,
      doctor: 'Fatima Alkhamis'
    },
    {
      visitsByDoctor: 345,
      doctor: 'NAHLA MAHMOUD MOHAMED'
    },
    {
      visitsByDoctor: 1017,
      doctor: 'Ahmed AlAdly'
    },
    {
      visitsByDoctor: 1057,
      doctor: 'Samar Ali Jameel Hassan'
    },
    {
      visitsByDoctor: 1,
      doctor: 'JILL JOYNE DAVID'
    },
    {
      visitsByDoctor: 48,
      doctor: 'Rafaa Ahmed Al Feky'
    },
    {
      visitsByDoctor: 511,
      doctor: 'Mohamed Safwat'
    },
    {
      visitsByDoctor: 162,
      doctor: 'Ala Fayiz Applejim Hasan'
    },
    {
      visitsByDoctor: 81,
      doctor: 'Mwadah Mutawakkil'
    },
    {
      visitsByDoctor: 690,
      doctor: 'Hazem Qamar'
    },
    {
      visitsByDoctor: 6,
      doctor: 'Laila Alkhatib'
    },
    {
      visitsByDoctor: 941,
      doctor: 'Gentle Lase 1'
    },
    {
      visitsByDoctor: 1170,
      doctor: 'BASMAH NASOHI'
    },
    {
      visitsByDoctor: 2872,
      doctor: 'ASHRAF ALESAWI'
    },
    {
      visitsByDoctor: 930,
      doctor: 'Osama fahmy'
    },
    {
      visitsByDoctor: 1791,
      doctor: 'Naglaa Hashesh'
    },
    {
      visitsByDoctor: 132,
      doctor: 'Ahmed Mohamed Saleh'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Batool Alzaki'
    },
    {
      visitsByDoctor: 1748,
      doctor: 'hanaa eid'
    },
    {
      visitsByDoctor: 1146,
      doctor: 'Gentlelase Pro Laser'
    },
    {
      visitsByDoctor: 1616,
      doctor: 'AMR ABOUELHODA MAHMOUD ELSIRAFY'
    },
    {
      visitsByDoctor: 1415,
      doctor: 'Alya El Sayed'
    },
    {
      visitsByDoctor: 28,
      doctor: 'Islam Ali'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Rashed Aljamah'
    },
    {
      visitsByDoctor: 8,
      doctor: 'ABRAR ALI A AHMED'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Mujtaba Albaqal'
    },
    {
      visitsByDoctor: 16,
      doctor: 'Ahmed Abd Alaziz'
    },
    {
      visitsByDoctor: 14,
      doctor: 'Haneen AlDalili'
    },
    {
      visitsByDoctor: 6,
      doctor: 'Hasan Ali Almasri'
    },
    {
      visitsByDoctor: 32,
      doctor: 'Manar'
    },
    {
      visitsByDoctor: 8,
      doctor: 'Sunshine CHAN'
    },
    {
      visitsByDoctor: 6,
      doctor: 'GENTLELASE MAX'
    },
    {
      visitsByDoctor: 3,
      doctor: 'Maamoun mustafa Abo-khalil'
    },
    {
      visitsByDoctor: 456,
      doctor: 'Ibtihal Al Amer'
    },
    {
      visitsByDoctor: 2370,
      doctor: 'HOSNY MOHAMMED'
    },
    {
      visitsByDoctor: 196,
      doctor: 'Nemat Mousa Ali'
    },
    {
      visitsByDoctor: 1170,
      doctor: 'Gentle Lase 3'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Fadila Alhammad'
    },
    {
      visitsByDoctor: 11,
      doctor: 'Abdul Hamid Al-Mansour'
    },
    {
      visitsByDoctor: 1675,
      doctor: 'Mohamed Sabri'
    },
    {
      visitsByDoctor: 51,
      doctor: 'Ahmed Zaakria'
    },
    {
      visitsByDoctor: 85,
      doctor: 'Mahmoud Fayed'
    },
    {
      visitsByDoctor: 105,
      doctor: 'Muhammad Nakshendi'
    },
    {
      visitsByDoctor: 393,
      doctor: 'Mohammed Alsaleh'
    },
    {
      visitsByDoctor: 611,
      doctor: 'Abdul Rahman Ahmed'
    },
    {
      visitsByDoctor: 539,
      doctor: 'Fatimah Al Bahrani'
    },
    {
      visitsByDoctor: 586,
      doctor: 'Enas'
    },
    {
      visitsByDoctor: 1168,
      doctor: 'Hossameldeen Gamal Elsheemy'
    },
    {
      visitsByDoctor: 449,
      doctor: 'Rouhi Syed Adnan'
    },
    {
      visitsByDoctor: 183,
      doctor: 'Mohanad Salamah'
    },
    {
      visitsByDoctor: 1289,
      doctor: 'EMAD MOHAMAD CHOUREITAH'
    },
    {
      visitsByDoctor: 137,
      doctor: 'Osama Abdelrahman'
    },
    {
      visitsByDoctor: 608,
      doctor: 'Mohammed Tashkandi'
    },
    {
      visitsByDoctor: 14,
      doctor: 'Rami Ali Hejazi'
    },
    {
      visitsByDoctor: 23,
      doctor: 'WAEL DEB ALAHMADALMBARK'
    },
    {
      visitsByDoctor: 4,
      doctor: 'MARWAH ALHULAIMI'
    },
    {
      visitsByDoctor: 107,
      doctor: 'Ahmed Sulaiman'
    },
    {
      visitsByDoctor: 86,
      doctor: 'Marwah Sharaf'
    },
    {
      visitsByDoctor: 59,
      doctor: 'Romisah Miftah Abdulaleem'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Saif Saudi'
    },
    {
      visitsByDoctor: 756,
      doctor: 'LAMA WASFI SALAMAH ALMASRI'
    },
    {
      visitsByDoctor: 406,
      doctor: 'Ghasan Shams Alden'
    },
    {
      visitsByDoctor: 2070,
      doctor: 'Shatha Suliman'
    },
    {
      visitsByDoctor: 10,
      doctor: 'Mustafa Mohammed Alsaeed Mohammed'
    },
    {
      visitsByDoctor: 359,
      doctor: 'javeriya nahen'
    },
    {
      visitsByDoctor: 37,
      doctor: 'Hassan Al Khabaz'
    },
    {
      visitsByDoctor: 1273,
      doctor: 'SPECTRA'
    },
    {
      visitsByDoctor: 18,
      doctor: 'Kholoud Zabani'
    },
    {
      visitsByDoctor: 216,
      doctor: 'Javeriya Nahin'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Noha Wahba'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Ibrahim Al-Halawani'
    },
    {
      visitsByDoctor: 763,
      doctor: 'Asmaa alsuliman'
    },
    {
      visitsByDoctor: 2103,
      doctor: 'Arsenia Javier Valera'
    },
    {
      visitsByDoctor: 531,
      doctor: 'Mayada Elfakharany'
    },
    {
      visitsByDoctor: 1315,
      doctor: 'Zainab Ali Halal'
    },
    {
      visitsByDoctor: 8,
      doctor: 'Spectra.'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Nycil Paulose'
    },
    {
      visitsByDoctor: 77,
      doctor: 'Basema Alobaid'
    },
    {
      visitsByDoctor: 1580,
      doctor: 'YOMNA AHMED ABDELAZIZ BARAKAT'
    },
    {
      visitsByDoctor: 408,
      doctor: 'Eman Galal'
    },
    {
      visitsByDoctor: 92,
      doctor: 'Amal Almarbat'
    },
    {
      visitsByDoctor: 14,
      doctor: 'Gentle Laser'
    },
    {
      visitsByDoctor: 13,
      doctor: 'Samar Beshara'
    },
    {
      visitsByDoctor: 922,
      doctor: 'Sajida Khan Abdul'
    },
    {
      visitsByDoctor: 1907,
      doctor: 'Shadi Alsomadi'
    },
    {
      visitsByDoctor: 10,
      doctor: 'Fatimah Al Bahrini'
    },
    {
      visitsByDoctor: 1876,
      doctor: 'Yasser Omar'
    },
    {
      visitsByDoctor: 871,
      doctor: 'Mamdouh Hassan'
    },
    {
      visitsByDoctor: 2457,
      doctor: 'AHMED FATHI'
    },
    {
      visitsByDoctor: 13,
      doctor: 'Afraa Authman Babker Alahmar'
    },
    {
      visitsByDoctor: 12,
      doctor: 'Samia Jamil Nassif'
    },
    {
      visitsByDoctor: 48,
      doctor: 'ABDULRAHMAN ALZAHIR'
    },
    {
      visitsByDoctor: 18,
      doctor: 'Mahmoud Jamil Alhalbouni'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Reinalyn Manaid'
    },
    {
      visitsByDoctor: 2866,
      doctor: 'Imadedin Ahmad Baghdadi'
    },
    {
      visitsByDoctor: 43,
      doctor: 'Ayman Selim'
    },
    {
      visitsByDoctor: 67,
      doctor: 'Wjood Faisal'
    },
    {
      visitsByDoctor: 2801,
      doctor: 'Ashraf  Nassar'
    },
    {
      visitsByDoctor: 242,
      doctor: 'Aya Al Rashidy'
    },
    {
      visitsByDoctor: 2,
      doctor: 'FATIMAH ALAWAD'
    },
    {
      visitsByDoctor: 67,
      doctor: 'Safaa Taha'
    },
    {
      visitsByDoctor: 1590,
      doctor: 'Amjad Hamarshah'
    },
    {
      visitsByDoctor: 1691,
      doctor: 'Muhannad Smiri'
    },
    {
      visitsByDoctor: 74,
      doctor: 'JEHAD BU SALEH'
    },
    {
      visitsByDoctor: 575,
      doctor: 'Samar Zaki'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Mary'
    },
    {
      visitsByDoctor: 1979,
      doctor: 'Hisham Yousef'
    },
    {
      visitsByDoctor: 2370,
      doctor: 'Tariq alraggad'
    },
    {
      visitsByDoctor: 3747,
      doctor: 'Jaber Mirza Mirza'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Ghadah Mustafaa Ali Aljundi'
    },
    {
      visitsByDoctor: 1865,
      doctor: 'ABDELLATIEF MOHAMED'
    },
    {
      visitsByDoctor: 11,
      doctor: 'Fadi Abdelazim Mohamed Abouelw'
    },
    {
      visitsByDoctor: 11,
      doctor: 'majed zahran'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Mae Rose Glindro'
    },
    {
      visitsByDoctor: 70,
      doctor: 'Abdullah Elgendy'
    },
    {
      visitsByDoctor: 1192,
      doctor: 'SOMAYA ELSAYED ELMAHALAWY'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Mary Sioco Joy'
    },
    {
      visitsByDoctor: 37,
      doctor: 'Walid Al-Tuwairqi'
    },
    {
      visitsByDoctor: 84,
      doctor: 'Khalid Alatni'
    },
    {
      visitsByDoctor: 10,
      doctor: 'Doaa Elsayad'
    },
    {
      visitsByDoctor: 1206,
      doctor: 'Motasim Alkhmous'
    },
    {
      visitsByDoctor: 212,
      doctor: 'Mahmoud Aljazzar'
    },
    {
      visitsByDoctor: 398,
      doctor: 'Mohammed Amro'
    },
    {
      visitsByDoctor: 2047,
      doctor: 'Samar Sakar'
    },
    {
      visitsByDoctor: 358,
      doctor: 'GentleLase Pro Four'
    },
    {
      visitsByDoctor: 21,
      doctor: 'Reem Alghounim'
    },
    {
      visitsByDoctor: 12,
      doctor: 'GentleLase PRO2 Device'
    },
    {
      visitsByDoctor: 14,
      doctor: 'Abdulkarim Al Falkah'
    },
    {
      visitsByDoctor: 515,
      doctor: 'JOSEPHINE GONZALES'
    },
    {
      visitsByDoctor: 17,
      doctor: 'Mustafa Hamdi'
    },
    {
      visitsByDoctor: 523,
      doctor: 'Ahmad habib'
    },
    {
      visitsByDoctor: 58,
      doctor: 'Maitham Alsaleh'
    },
    {
      visitsByDoctor: 434,
      doctor: 'Sameh altaweel'
    },
    {
      visitsByDoctor: 20,
      doctor: 'Mayaan Ali'
    },
    {
      visitsByDoctor: 7,
      doctor: 'Mohammad Nashbat'
    },
    {
      visitsByDoctor: 1273,
      doctor: 'Rafaa M.Talal M.Salim Assfari'
    },
    {
      visitsByDoctor: 17,
      doctor: 'Reem Khater'
    },
    {
      visitsByDoctor: 1030,
      doctor: 'Mohamed Sabry Abdelhalim Mohamed'
    },
    {
      visitsByDoctor: 250,
      doctor: 'Ahmad fayed'
    },
    {
      visitsByDoctor: 25,
      doctor: 'Khalid Abo Rasheed'
    },
    {
      visitsByDoctor: 2,
      doctor: 'LORWIN ARENGA GALAN'
    },
    {
      visitsByDoctor: 890,
      doctor: 'Wesam ghatashah'
    },
    {
      visitsByDoctor: 1148,
      doctor: 'Mohamed  Hamadnah'
    },
    {
      visitsByDoctor: 201,
      doctor: 'Inas Abdulaal'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Regular Facial Machine'
    },
    {
      visitsByDoctor: 542,
      doctor: 'Ahmed Kheder'
    },
    {
      visitsByDoctor: 2981,
      doctor: 'Khlaid hemaidat'
    },
    {
      visitsByDoctor: 839,
      doctor: 'Sadaf sdeeq'
    },
    {
      visitsByDoctor: 2206,
      doctor: 'Rimon Ghaleb Ajram'
    },
    {
      visitsByDoctor: 1059,
      doctor: 'Mohamed Amar Hauby'
    },
    {
      visitsByDoctor: 3229,
      doctor: 'Ali Saleem Baddour'
    },
    {
      visitsByDoctor: 1164,
      doctor: 'Nashwa mohammed'
    },
    {
      visitsByDoctor: 1062,
      doctor: 'ABDELRAMAN ALI ABDLRAMAN ALI ELSAKET'
    },
    {
      visitsByDoctor: 1532,
      doctor: 'SHAIMAA SAMER ABD ELFATAH RAMDEN'
    },
    {
      visitsByDoctor: 557,
      doctor: 'Mansour Al Ali'
    },
    {
      visitsByDoctor: 49,
      doctor: 'Rand Al akkas'
    },
    {
      visitsByDoctor: 25,
      doctor: 'Shahad Alghamdi'
    },
    {
      visitsByDoctor: 4,
      doctor: 'Sculp Sure'
    },
    {
      visitsByDoctor: 28,
      doctor: 'Ahmed Alamry'
    },
    {
      visitsByDoctor: 29,
      doctor: 'Bassem Ashry'
    },
    {
      visitsByDoctor: 700,
      doctor: 'Archana Raghav'
    },
    {
      visitsByDoctor: 19,
      doctor: 'Mohaned Salama'
    },
    {
      visitsByDoctor: 52,
      doctor: 'Motaz AllaUdden'
    },
    {
      visitsByDoctor: 1273,
      doctor: 'Diana Ali'
    },
    {
      visitsByDoctor: 42,
      doctor: 'Noora Al-Baijan'
    },
    {
      visitsByDoctor: 1746,
      doctor: 'Nada  Hassan'
    },
    {
      visitsByDoctor: 11,
      doctor: 'Vanquish'
    },
    {
      visitsByDoctor: 1763,
      doctor: 'Amin Elatawneh'
    },
    {
      visitsByDoctor: 261,
      doctor: 'Norah Abdul aziz'
    },
    {
      visitsByDoctor: 2312,
      doctor: 'AMER LAMLOUM'
    },
    {
      visitsByDoctor: 1254,
      doctor: 'Dutto Laser'
    },
    {
      visitsByDoctor: 793,
      doctor: 'duha abed almuneim'
    },
    {
      visitsByDoctor: 4,
      doctor: 'Hasan Al-Ali'
    },
    {
      visitsByDoctor: 116,
      doctor: 'Nomer Labuguen'
    },
    {
      visitsByDoctor: 920,
      doctor: 'Mohammed alkhatib'
    },
    {
      visitsByDoctor: 39,
      doctor: 'Ryan Ahmed'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Zahraa Al-Abandi'
    },
    {
      visitsByDoctor: 5,
      doctor: 'Moaz Eid'
    },
    {
      visitsByDoctor: 38,
      doctor: 'Sondos Jaafar Alawamy'
    },
    {
      visitsByDoctor: 1292,
      doctor: 'Huda tadfi'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Mohanad Salama'
    },
    {
      visitsByDoctor: 1048,
      doctor: 'Ahmed Mohamed'
    },
    {
      visitsByDoctor: 1341,
      doctor: 'Mohammed Ahmed Mohammad Alhaj'
    },
    {
      visitsByDoctor: 36,
      doctor: 'Motaz Mohammed'
    },
    {
      visitsByDoctor: 270,
      doctor: 'GentleLase Pro Two'
    },
    {
      visitsByDoctor: 28,
      doctor: 'ZAINAB ALSHABIB'
    },
    {
      visitsByDoctor: 218,
      doctor: 'Malek Almutairi'
    },
    {
      visitsByDoctor: 1563,
      doctor: 'Ashraf abdulaty'
    },
    {
      visitsByDoctor: 59,
      doctor: 'Eman Tallat'
    },
    {
      visitsByDoctor: 17,
      doctor: 'Atheer Khaled'
    },
    {
      visitsByDoctor: 89,
      doctor: 'Hudda Qandeel'
    },
    {
      visitsByDoctor: 1846,
      doctor: 'EMAN NOUREIDIN'
    },
    {
      visitsByDoctor: 597,
      doctor: 'Javeriya  Nahin'
    },
    {
      visitsByDoctor: 14,
      doctor: 'Enas Al Darsh'
    },
    {
      visitsByDoctor: 240,
      doctor: 'Asmaa Alhabib'
    },
    {
      visitsByDoctor: 2243,
      doctor: 'Mohmed Abdelmonem Mostafa'
    },
    {
      visitsByDoctor: 68,
      doctor: 'Eman Elkholy'
    },
    {
      visitsByDoctor: 907,
      doctor: 'Rahmah Hassan ALDaboos'
    },
    {
      visitsByDoctor: 233,
      doctor: 'Gentel Pro 2'
    },
    {
      visitsByDoctor: 4,
      doctor: 'may abdullah'
    },
    {
      visitsByDoctor: 726,
      doctor: 'Amal Helmi'
    },
    {
      visitsByDoctor: 812,
      doctor: 'Fatimah Ahmad Abdaljalil'
    },
    {
      visitsByDoctor: 28,
      doctor: 'AMNAH SALMAN ALQATAN'
    },
    {
      visitsByDoctor: 40,
      doctor: 'SPECTRA MACHINE'
    },
    {
      visitsByDoctor: 317,
      doctor: 'Mustafa Sami'
    },
    {
      visitsByDoctor: 3192,
      doctor: 'Bassma Samir'
    },
    {
      visitsByDoctor: 138,
      doctor: 'Heba Ahmed'
    },
    {
      visitsByDoctor: 134,
      doctor: 'Moatz Mohamed'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Dianne Grace Pajatin'
    },
    {
      visitsByDoctor: 5,
      doctor: 'Mona Alzazo'
    },
    {
      visitsByDoctor: 70,
      doctor: 'Rabab Gamal'
    },
    {
      visitsByDoctor: 182,
      doctor: 'Rania Abu Turaba'
    },
    {
      visitsByDoctor: 1432,
      doctor: 'DUETTO TWO MACHINE'
    },
    {
      visitsByDoctor: 57,
      doctor: 'Marwa Mohamed'
    },
    {
      visitsByDoctor: 824,
      doctor: 'EHAB ALGHANNOUM'
    },
    {
      visitsByDoctor: 849,
      doctor: 'Gentle lase'
    },
    {
      visitsByDoctor: 919,
      doctor: 'Marwa ali'
    },
    {
      visitsByDoctor: 54,
      doctor: 'Alma Bawzeer'
    },
    {
      visitsByDoctor: 13,
      doctor: 'Enas Ali'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Omar'
    },
    {
      visitsByDoctor: 1549,
      doctor: 'Thaminah Humayun Humayun'
    },
    {
      visitsByDoctor: 1014,
      doctor: 'ALYA FLYWON'
    },
    {
      visitsByDoctor: 1358,
      doctor: 'Duetto 3'
    },
    {
      visitsByDoctor: 91,
      doctor: 'Feras ALZBOUN'
    },
    {
      visitsByDoctor: 1046,
      doctor: 'Gental lase 1'
    },
    {
      visitsByDoctor: 3064,
      doctor: 'HEBAH YOUNES'
    },
    {
      visitsByDoctor: 1468,
      doctor: 'Sari hussain'
    },
    {
      visitsByDoctor: 2554,
      doctor: 'Dina Ahmed Mahrous Baderaldeen'
    },
    {
      visitsByDoctor: 2198,
      doctor: 'Ahmed Salah'
    },
    {
      visitsByDoctor: 141,
      doctor: 'Dalia Anbtawi'
    },
    {
      visitsByDoctor: 171,
      doctor: 'Mohammed Maged'
    },
    {
      visitsByDoctor: 158,
      doctor: 'GHAIDA ALJIHANI'
    },
    {
      visitsByDoctor: 691,
      doctor: 'RAGDAA ALRAWENI'
    },
    {
      visitsByDoctor: 1424,
      doctor: 'Ali mohammed'
    },
    {
      visitsByDoctor: 9,
      doctor: 'Ahmed Khedr'
    },
    {
      visitsByDoctor: 181,
      doctor: 'Eman Al Qeshawi'
    },
    {
      visitsByDoctor: 176,
      doctor: 'Eiman Hasan'
    },
    {
      visitsByDoctor: 62,
      doctor: 'Abdulaziz almulhim'
    },
    {
      visitsByDoctor: 26,
      doctor: 'Kinan Aziz'
    },
    {
      visitsByDoctor: 29,
      doctor: 'Saad Al Zahrani'
    },
    {
      visitsByDoctor: 14,
      doctor: 'Hala Boukhamseen'
    },
    {
      visitsByDoctor: 44,
      doctor: 'Haifa Mohammed'
    },
    {
      visitsByDoctor: 3409,
      doctor: 'Dalia Mohamed Anwar Aboelhana'
    },
    {
      visitsByDoctor: 1542,
      doctor: 'Abdullah altakaenah'
    },
    {
      visitsByDoctor: 1744,
      doctor: 'Shareef attia'
    },
    {
      visitsByDoctor: 155,
      doctor: 'Haythem Sedeek'
    },
    {
      visitsByDoctor: 697,
      doctor: 'NANCY SAREN NOLASCO'
    },
    {
      visitsByDoctor: 2956,
      doctor: 'Abdulfatah Beaker'
    },
    {
      visitsByDoctor: 1612,
      doctor: 'MAZEN MUSLIH DAGHMASH'
    },
    {
      visitsByDoctor: 82,
      doctor: 'HEYAM ALHASAN'
    },
    {
      visitsByDoctor: 1391,
      doctor: 'Marwa makki'
    },
    {
      visitsByDoctor: 1601,
      doctor: 'Hayfa Mhd Tawfik Jarad'
    },
    {
      visitsByDoctor: 469,
      doctor: 'Nahla Elsayed'
    },
    {
      visitsByDoctor: 120,
      doctor: 'Mohammed mosher'
    },
    {
      visitsByDoctor: 113,
      doctor: 'Tasneem Hassan'
    },
    {
      visitsByDoctor: 7,
      doctor: 'Rafika Hawary Amari'
    },
    {
      visitsByDoctor: 1318,
      doctor: 'Amro Abdulghani'
    },
    {
      visitsByDoctor: 881,
      doctor: 'GENTLE ONE MACHINE'
    },
    {
      visitsByDoctor: 493,
      doctor: 'Doaa Hamed'
    },
    {
      visitsByDoctor: 1277,
      doctor: 'Mahmoud Sayed'
    },
    {
      visitsByDoctor: 104,
      doctor: 'Maha Hussain'
    },
    {
      visitsByDoctor: 45,
      doctor: 'May Attia'
    },
    {
      visitsByDoctor: 3,
      doctor: 'bindagji'
    },
    {
      visitsByDoctor: 1595,
      doctor: 'Dawlat  Afandi'
    },
    {
      visitsByDoctor: 28,
      doctor: 'Laila Hassan'
    },
    {
      visitsByDoctor: 162,
      doctor: 'Mohammed Fathallah'
    },
    {
      visitsByDoctor: 66,
      doctor: 'Amani Al Naseer'
    },
    {
      visitsByDoctor: 1316,
      doctor: 'Mahmoud SAYED Sayed'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Mahamed Khalefah'
    },
    {
      visitsByDoctor: 684,
      doctor: 'Dalia Same Ewyas'
    },
    {
      visitsByDoctor: 9,
      doctor: 'Mai Fahad Al-Jishi'
    },
    {
      visitsByDoctor: 571,
      doctor: 'Karim Al-Saeed'
    },
    {
      visitsByDoctor: 121,
      doctor: 'Mustafa Al Hamran'
    },
    {
      visitsByDoctor: 350,
      doctor: 'Ahmed Saad'
    },
    {
      visitsByDoctor: 23,
      doctor: 'Zainab Al-Muzaien'
    },
    {
      visitsByDoctor: 404,
      doctor: 'Gentle Pro'
    },
    {
      visitsByDoctor: 1900,
      doctor: 'Rehab Saliby'
    },
    {
      visitsByDoctor: 466,
      doctor: 'CARYL TOLORES BRANAIROS'
    },
    {
      visitsByDoctor: 1569,
      doctor: 'parveen '
    },
    {
      visitsByDoctor: 476,
      doctor: 'dalia nawaf alnajar'
    },
    {
      visitsByDoctor: 43,
      doctor: 'Doha Mosallam'
    },
    {
      visitsByDoctor: 2142,
      doctor: 'Mohammad Baiomy'
    },
    {
      visitsByDoctor: 34,
      doctor: 'Zainab Abdulsalam'
    },
    {
      visitsByDoctor: 1698,
      doctor: 'Waleed AL Othman'
    },
    {
      visitsByDoctor: 1531,
      doctor: 'TALAL ABDULLATIF AKKARI'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Mohamed Ramadan'
    },
    {
      visitsByDoctor: 2514,
      doctor: 'Hebtalla Ahmed Mohammed Hasneen'
    },
    {
      visitsByDoctor: 92,
      doctor: 'EYAD ZAKARIA ALMURAD'
    },
    {
      visitsByDoctor: 2109,
      doctor: 'Yousef Abdi'
    },
    {
      visitsByDoctor: 4,
      doctor: 'May  Alzuwawi'
    },
    {
      visitsByDoctor: 102,
      doctor: 'Reem Halawani'
    },
    {
      visitsByDoctor: 411,
      doctor: 'Farhat L L Zainab'
    },
    {
      visitsByDoctor: 771,
      doctor: 'Hossam Almassri'
    },
    {
      visitsByDoctor: 2358,
      doctor: 'SHIMAA MABROUK MOHAMED ALSODANY'
    },
    {
      visitsByDoctor: 1502,
      doctor: 'Mohammed Abdullah Muthana'
    },
    {
      visitsByDoctor: 263,
      doctor: 'Khaled Albakri'
    },
    {
      visitsByDoctor: 2040,
      doctor: 'MOHAMMED WASFI ALMASRI'
    },
    {
      visitsByDoctor: 138,
      doctor: 'Fatma Al Zahra'
    },
    {
      visitsByDoctor: 47,
      doctor: 'SOPRANO'
    },
    {
      visitsByDoctor: 49,
      doctor: 'Keran Shindy'
    },
    {
      visitsByDoctor: 135,
      doctor: 'Raghib Wasfy Alhajawy'
    },
    {
      visitsByDoctor: 48,
      doctor: 'Remon Ajram'
    },
    {
      visitsByDoctor: 14,
      doctor: 'Mostafa Hamdi'
    },
    {
      visitsByDoctor: 9,
      doctor: 'adnan mostafa'
    },
    {
      visitsByDoctor: 7,
      doctor: 'Mahmoud Al Gazzar'
    },
    {
      visitsByDoctor: 18,
      doctor: 'Sarah Saeed'
    },
    {
      visitsByDoctor: 2913,
      doctor: 'Hitham Elsayed'
    },
    {
      visitsByDoctor: 758,
      doctor: 'Ayman  Abdullatif'
    },
    {
      visitsByDoctor: 1248,
      doctor: 'GENTLE LASE TWO'
    },
    {
      visitsByDoctor: 637,
      doctor: 'Mostafa Hamed'
    },
    {
      visitsByDoctor: 17,
      doctor: 'Abdul Mohsen Al-Hassan'
    },
    {
      visitsByDoctor: 23,
      doctor: 'Al-Issa'
    },
    {
      visitsByDoctor: 3,
      doctor: 'Maryam Sadeq'
    },
    {
      visitsByDoctor: 19,
      doctor: 'Yasmin Noor'
    },
    {
      visitsByDoctor: 945,
      doctor: 'Emman Hassan'
    },
    {
      visitsByDoctor: 1811,
      doctor: 'GENTLE LASE'
    },
    {
      visitsByDoctor: 35,
      doctor: 'Mohamed Alamer'
    },
    {
      visitsByDoctor: 212,
      doctor: 'NAEL ANWAR'
    },
    {
      visitsByDoctor: 109,
      doctor: 'Duha Abdel Moneim'
    },
    {
      visitsByDoctor: 31,
      doctor: 'Reham Saeed'
    },
    {
      visitsByDoctor: 167,
      doctor: 'Hydra Facial'
    },
    {
      visitsByDoctor: 2791,
      doctor: 'Haitham Abdulgabar Nafeaattary'
    },
    {
      visitsByDoctor: 220,
      doctor: 'Rema'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Moaz Haj'
    },
    {
      visitsByDoctor: 2093,
      doctor: 'safa zaghloul'
    },
    {
      visitsByDoctor: 29,
      doctor: 'Jood Makhzoom'
    },
    {
      visitsByDoctor: 5,
      doctor: 'mohammed sindi'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Kenda Al Zamil'
    },
    {
      visitsByDoctor: 148,
      doctor: 'Mahmoud Abdulrahman'
    },
    {
      visitsByDoctor: 233,
      doctor: 'Atheer Alabdulatif'
    },
    {
      visitsByDoctor: 260,
      doctor: 'Amr Abdelaziz'
    },
    {
      visitsByDoctor: 375,
      doctor: 'Munzir Mahmoud'
    },
    {
      visitsByDoctor: 47,
      doctor: 'Mohamed AL Nahaas'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Ammar Abdullah'
    },
    {
      visitsByDoctor: 3404,
      doctor: 'Berna Mounir Elzoobi'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Jerman al qahtani'
    },
    {
      visitsByDoctor: 1265,
      doctor: 'Wissam Al-Rashidi'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Amani Alhakami'
    },
    {
      visitsByDoctor: 1931,
      doctor: 'Rafif Mohammad Alaswad'
    },
    {
      visitsByDoctor: 2556,
      doctor: 'Ahmed Mohammed Alsayed Easamra'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Abeer Alalwan'
    },
    {
      visitsByDoctor: 1493,
      doctor: 'Tariq alashmawi'
    },
    {
      visitsByDoctor: 17,
      doctor: 'Elias Rizk Bshara'
    },
    {
      visitsByDoctor: 120,
      doctor: 'Ehab Moneeb'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Javera'
    },
    {
      visitsByDoctor: 13,
      doctor: 'Kawther Al-Hajji'
    },
    {
      visitsByDoctor: 366,
      doctor: 'Ahlam Zedan'
    },
    {
      visitsByDoctor: 131,
      doctor: 'Muneera Bokhary'
    },
    {
      visitsByDoctor: 25,
      doctor: 'fatimah al nasser'
    },
    {
      visitsByDoctor: 14,
      doctor: 'Naima Al Hamam'
    },
    {
      visitsByDoctor: 216,
      doctor: 'Ahmed Tolba'
    },
    {
      visitsByDoctor: 8,
      doctor: 'Safa Taha'
    },
    {
      visitsByDoctor: 873,
      doctor: 'Rawda Alnabahin'
    },
    {
      visitsByDoctor: 177,
      doctor: 'Islam Munir Ali'
    },
    {
      visitsByDoctor: 221,
      doctor: 'Mohamed Othman'
    },
    {
      visitsByDoctor: 4,
      doctor: 'Jennifer Dorado'
    },
    {
      visitsByDoctor: 16,
      doctor: 'Essam Majed Alakkari'
    },
    {
      visitsByDoctor: 1060,
      doctor: 'Hnadee Mhmd Nanwa'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Amal Alqahrany'
    },
    {
      visitsByDoctor: 2060,
      doctor: 'DUETTO'
    },
    {
      visitsByDoctor: 15,
      doctor: 'Griselda Patricia'
    },
    {
      visitsByDoctor: 31,
      doctor: 'Shatha Alshibani'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Abdullah Al-Telmisani'
    },
    {
      visitsByDoctor: 391,
      doctor: 'Mohammed Toukhi'
    },
    {
      visitsByDoctor: 593,
      doctor: 'Marwa Khalil'
    },
    {
      visitsByDoctor: 788,
      doctor: 'FAHMIDA HASAN SHAMSUL HASAN'
    },
    {
      visitsByDoctor: 6,
      doctor: 'DIMA ZIYAD HAMILA'
    },
    {
      visitsByDoctor: 2741,
      doctor: 'Aya baker barjus'
    },
    {
      visitsByDoctor: 725,
      doctor: 'RAMIA RAJENDRAN'
    },
    {
      visitsByDoctor: 23,
      doctor: 'Dalia Nasr El Din'
    },
    {
      visitsByDoctor: 71,
      doctor: 'NEJLA AROUIISE'
    },
    {
      visitsByDoctor: 14,
      doctor: 'FACIAL Device'
    },
    {
      visitsByDoctor: 47,
      doctor: 'Walaa Mohammed'
    },
    {
      visitsByDoctor: 216,
      doctor: 'ALIAA ALMASRI'
    },
    {
      visitsByDoctor: 7,
      doctor: 'Amerah Adel Ahmed'
    },
    {
      visitsByDoctor: 2513,
      doctor: 'Ibrahim Mohamed Soliman'
    },
    {
      visitsByDoctor: 4,
      doctor: 'Nashwan Adnan Alsamawi'
    },
    {
      visitsByDoctor: 80,
      doctor: 'Reem Al Farhany'
    },
    {
      visitsByDoctor: 1012,
      doctor: 'Musab Zaroor'
    },
    {
      visitsByDoctor: 99,
      doctor: 'Amal El Abis'
    },
    {
      visitsByDoctor: 2227,
      doctor: 'Ayman Abdel-Latif'
    },
    {
      visitsByDoctor: 10,
      doctor: 'Reem Al Khater'
    },
    {
      visitsByDoctor: 2025,
      doctor: 'Almutazbellah Mohamed Altayeh'
    },
    {
      visitsByDoctor: 77,
      doctor: 'AHMED SANNYELBAHAA'
    },
    {
      visitsByDoctor: 48,
      doctor: 'Carlo MANIAOL'
    },
    {
      visitsByDoctor: 39,
      doctor: 'MOHAMMED AL DOKHI'
    },
    {
      visitsByDoctor: 324,
      doctor: 'Moath Galeb'
    },
    {
      visitsByDoctor: 13,
      doctor: 'Sarah Al Muhandir'
    },
    {
      visitsByDoctor: 148,
      doctor: 'Munira AlAnzi'
    },
    {
      visitsByDoctor: 177,
      doctor: 'Asma Syd Jaballlah Eawayis'
    },
    {
      visitsByDoctor: 830,
      doctor: 'Wessam Maged Abdelraouf Mattar'
    },
    {
      visitsByDoctor: 7,
      doctor: 'seba saflo'
    },
    {
      visitsByDoctor: 641,
      doctor: 'Maha Hessen'
    },
    {
      visitsByDoctor: 2228,
      doctor: 'Nycil  Paulose'
    },
    {
      visitsByDoctor: 145,
      doctor: 'Naser Ragab'
    },
    {
      visitsByDoctor: 26,
      doctor: 'Mohamed Matbouly Abdelmoghny'
    },
    {
      visitsByDoctor: 128,
      doctor: 'Nada ibrahim'
    },
    {
      visitsByDoctor: 453,
      doctor: 'Hisham Omar'
    },
    {
      visitsByDoctor: 187,
      doctor: 'Omar Ali'
    },
    {
      visitsByDoctor: 41,
      doctor: 'Sara Mahana'
    },
    {
      visitsByDoctor: 7,
      doctor: 'jjaveriya Nahin'
    },
    {
      visitsByDoctor: 244,
      doctor: 'Safaa Abdilghany'
    },
    {
      visitsByDoctor: 1267,
      doctor: 'Raniya Alsaeed Sayah'
    },
    {
      visitsByDoctor: 25,
      doctor: 'Noha Ibrahim'
    },
    {
      visitsByDoctor: 21,
      doctor: 'razan almaghrab'
    },
    {
      visitsByDoctor: 278,
      doctor: 'Ahmed Ghiaz Hussain Anbar'
    },
    {
      visitsByDoctor: 4,
      doctor: 'Cyrielle Garneth Herero'
    },
    {
      visitsByDoctor: 21,
      doctor: 'Marwa Ali'
    },
    {
      visitsByDoctor: 62,
      doctor: 'Thekryat Majdi Baker Badrasawi'
    },
    {
      visitsByDoctor: 1120,
      doctor: 'Mahmoud Al-Atti'
    },
    {
      visitsByDoctor: 386,
      doctor: 'Abdullah Al Aqrabawi'
    },
    {
      visitsByDoctor: 1376,
      doctor: 'Baker basha'
    },
    {
      visitsByDoctor: 9,
      doctor: 'Mohamed sabry'
    },
    {
      visitsByDoctor: 243,
      doctor: 'NOHA OMAR ABDEL ALMASRY'
    },
    {
      visitsByDoctor: 182,
      doctor: 'Ibrahim Bakeer'
    },
    {
      visitsByDoctor: 57,
      doctor: 'Inas Al Darwish'
    },
    {
      visitsByDoctor: 15,
      doctor: 'Duha Hijazi'
    },
    {
      visitsByDoctor: 31,
      doctor: 'Cryo'
    },
    {
      visitsByDoctor: 3155,
      doctor: 'Khalil Mehyar'
    },
    {
      visitsByDoctor: 4,
      doctor: 'Emadeddin Adib Alassali'
    },
    {
      visitsByDoctor: 124,
      doctor: 'Maha hamed'
    },
    {
      visitsByDoctor: 100,
      doctor: 'Zain Aldeen Hamodi'
    },
    {
      visitsByDoctor: 2,
      doctor: 'Mahmoud Shaheen'
    },
    {
      visitsByDoctor: 3557,
      doctor: 'Abdullah Hassan'
    },
    {
      visitsByDoctor: 996,
      doctor: 'Asma Deis'
    },
    {
      visitsByDoctor: 2550,
      doctor: 'sami abdulrauof zaablawi'
    },
    {
      visitsByDoctor: 121,
      doctor: 'Mona Alhindawy'
    },
    {
      visitsByDoctor: 19,
      doctor: 'Handrin Mohamed Naji Fahham'
    },
    {
      visitsByDoctor: 2182,
      doctor: 'Navida  Idrees'
    },
    {
      visitsByDoctor: 46,
      doctor: 'Mariam Al Oysher'
    },
    {
      visitsByDoctor: 120,
      doctor: 'Sajad Alsaba'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Jiniva Mayo Samin'
    },
    {
      visitsByDoctor: 16,
      doctor: 'Walaa Abd Alsalam'
    },
    {
      visitsByDoctor: 3549,
      doctor: 'Azam  Salamah'
    },
    {
      visitsByDoctor: 19,
      doctor: 'Gassan shams al deen'
    },
    {
      visitsByDoctor: 1,
      doctor: 'DOAA IBRAHIM'
    },
    {
      visitsByDoctor: 14,
      doctor: 'FACLAL'
    },
    {
      visitsByDoctor: 1,
      doctor: 'LUTRONIC SPECTRA XT'
    },
    {
      visitsByDoctor: 12,
      doctor: 'Mohamed Ali'
    },
    {
      visitsByDoctor: 426,
      doctor: 'GentleLase Pro Three'
    },
    {
      visitsByDoctor: 2158,
      doctor: 'Sini Shajl Sunny Shaji'
    },
    {
      visitsByDoctor: 764,
      doctor: 'Amal AlMarbat'
    },
    {
      visitsByDoctor: 3,
      doctor: 'MALEEHA ZAHOOR'
    },
    {
      visitsByDoctor: 1314,
      doctor: 'SAHAR ALSTOHI'
    },
    {
      visitsByDoctor: 2319,
      doctor: 'Moustafa  Khieraldeen'
    },
    {
      visitsByDoctor: 128,
      doctor: 'Omnia Lutfi'
    },
    {
      visitsByDoctor: 1052,
      doctor: 'Basma Sakr'
    },
    {
      visitsByDoctor: 2280,
      doctor: 'AMANI SABORE'
    },
    {
      visitsByDoctor: 117,
      doctor: 'Gasan Shams Udeen'
    },
    {
      visitsByDoctor: 4314,
      doctor: 'ELAF KOJAK'
    },
    {
      visitsByDoctor: 4,
      doctor: 'Wail Mhd Radwan Aljabban'
    },
    {
      visitsByDoctor: 272,
      doctor: 'Fatima Sawi'
    },
    {
      visitsByDoctor: 1204,
      doctor: 'ALI SALLOM'
    },
    {
      visitsByDoctor: 1,
      doctor: 'Noor alshareef'
    },
    {
      visitsByDoctor: 2,
      doctor: 'mustafa Tarqaji'
    },
    {
      visitsByDoctor: 23,
      doctor: 'Ahmed Alarab'
    },
    {
      visitsByDoctor: 7,
      doctor: 'eman aldajany'
    },
    {
      visitsByDoctor: 45,
      doctor: 'Salma Ahmed'
    },
    {
      visitsByDoctor: 18,
      doctor: 'Haider Alali'
    },
    {
      visitsByDoctor: 129,
      doctor: 'Ahmed Hassib'
    },
    {
      visitsByDoctor: 2402,
      doctor: 'Basl Smyr Qlajw'
    },
    {
      visitsByDoctor: 24,
      doctor: 'Soprano'
    },
    {
      visitsByDoctor: 1994,
      doctor: 'Marwa Aldahri'
    },
    {
      visitsByDoctor: 18,
      doctor: 'Ahmed Khalifa'
    },
    {
      visitsByDoctor: 32,
      doctor: 'Xilis'
    },
    {
      visitsByDoctor: 2001,
      doctor: 'Medhat Osama Mohammed'
    },
    {
      visitsByDoctor: 4,
      doctor: 'Khaldoun Abdulghafour Alomawi'
    },
    {
      visitsByDoctor: 2426,
      doctor: 'Mayadah Adeeb Othman'
    },
    {
      visitsByDoctor: 3562,
      doctor: 'Mohamed Ahmed Ragheb Ketkat'
    },
    {
      visitsByDoctor: 2377,
      doctor: 'Samar  Alati'
    },
    {
      visitsByDoctor: 1273,
      doctor: 'SAMAR SALAH'
    }
  ],
  monthlyVisits: [
    {
      visitsByMonth: 35081,
      month: 1,
      year: 2022,
      Month: 'Jan-2022'
    },
    {
      visitsByMonth: 122240,
      month: 2,
      year: 2022,
      Month: 'Feb-2022'
    },
    {
      visitsByMonth: 112116,
      month: 3,
      year: 2022,
      Month: 'Mar-2022'
    },
    {
      month: 4,
      year: 2022,
      Month: 'Apr-2022',
      visitsByMonth: 0
    },
    {
      month: 5,
      year: 2022,
      Month: 'May-2022',
      visitsByMonth: 0
    },
    {
      month: 6,
      year: 2022,
      Month: 'Jun-2022',
      visitsByMonth: 0
    },
    {
      month: 7,
      year: 2022,
      Month: 'Jul-2022',
      visitsByMonth: 0
    },
    {
      month: 8,
      year: 2022,
      Month: 'Aug-2022',
      visitsByMonth: 0
    },
    {
      month: 9,
      year: 2022,
      Month: 'Sept-2022',
      visitsByMonth: 0
    },
    {
      month: 10,
      year: 2022,
      Month: 'Oct-2022',
      visitsByMonth: 0
    },
    {
      month: 11,
      year: 2022,
      Month: 'Nov-2022',
      visitsByMonth: 0
    },
    {
      visitsByMonth: 4,
      month: 12,
      year: 2022,
      Month: 'Dec-2022'
    }
  ]
}

export default dashboardReport
