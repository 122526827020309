/* eslint-disable no-useless-catch */

import { HttpClient } from '../utils/httpClient'
import jwtDecode from 'jwt-decode'

class AuthService {
  constructor() {
    this.httpClient = new HttpClient()
  }

  async login(user) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { emailAddress, password } = user

      /**
       * TODO - Check and fix http wrapper logic
       */

      const response = await this.httpClient.post('/user/login', {
        emailAddress: emailAddress.trim(),
        password
      })

      if (response.data) {
        localStorage.setItem('jwt', JSON.stringify(response.data.token))
        let decodedJwt = jwtDecode(response.data.token)
        decodedJwt = {
          ...decodedJwt,
          fullName: `${decodedJwt.firstName} ${decodedJwt.lastName}`
        }
        localStorage.setItem('user', JSON.stringify(decodedJwt))

        return { jwt: response.data.token, user: decodedJwt }
      }
      return { jwt: null, user: null }
    } catch (error) {
      throw error
    }
  }

  async logout() {
    localStorage.removeItem('user')
    localStorage.removeItem('jwt')
    return {
      status: 'success',
      data: {
        jwt: null,
        user: null
      }
    }
  }
}

export { AuthService }
