import { Outlet } from 'react-router-dom';
import React from 'react';

/**
 * This component is the outer layout of the application which contains the header and the footer only.
 * @returns {React.Component}
 * We will use this when user is not logged in.
 */
const OuterLayout = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default OuterLayout;
