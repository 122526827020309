import { REVENUE_ACTIVITY, REVENUE_DETAILS, REVENUE_STATS } from '../constants';

import { apiSlice } from './apiSlice';
import { buildUrlUsingParams } from '../utils/urlformat';
import { createEntityAdapter } from '@reduxjs/toolkit';

const revenueAdapter = createEntityAdapter();

const initialState = revenueAdapter.getInitialState();

export const revenueApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRevenueStats: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(REVENUE_STATS, payload);
      },
    }),
    getRevenueActivity: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(REVENUE_ACTIVITY, payload);
      },
    }),
    getRevenueDetails: builder.query({
      query: (payload) => {
        return buildUrlUsingParams(REVENUE_DETAILS, payload);
      },
    }),
  }),
});

export const {
  useGetRevenueStatsQuery,
  useGetRevenueActivityQuery,
  useLazyGetRevenueStatsQuery,
  useLazyGetRevenueActivityQuery,
  useLazyGetRevenueDetailsQuery,
  useGetRevenueDetailsQuery,
} = revenueApiSlice;
