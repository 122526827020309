import { Navigate, useRoutes } from 'react-router-dom';

// routes
import Appointments from '../components/Appointments/Appointments';
import CP_APPOINTMENTS from '../components/Comparison/Appointments/CP_APPOINTMENTS';
import ChartsUsage from '../components/ChartsUsage';
import Claims from '../components/Claims/Claims';
import Doctors from '../components/Doctors/Doctors';
import FootFall from '../components/FootFall/FootFall';
import Lab from '../components/Lab/Lab';
// layouts
import Layout from '../common/Layout/Layout';
import Login from '../components/Login/Login';
import NotFound from '../pages/page404';
import OuterLayout from '../common/Layout/OuterLayout';
import Patients from '../components/Patients/Patients';
import PublicRoute from './PublicRoute';
import Revenue from '../components/Revenue/Revenue';
import RouteNavigator from './RouteNavigator';
import Signup from '../components/Signup/Signup';

export default function Router() {
  return useRoutes([
    {
      path: '/app',
      element: <Layout />,
      children: [
        { path: 'dashboard', element: <ChartsUsage /> },
        { path: 'appointments', element: <Appointments /> },
        { path: 'footfall', element: <FootFall /> },
        { path: 'patients', element: <Patients /> },
        { path: 'doctors', element: <Doctors /> },
        { path: 'lab', element: <Lab /> },
        { path: 'revenue', element: <Revenue /> },
        { path: 'claims', element: <Claims /> },
        {
          path: 'comparison',
          children: [{ path: 'appointments', element: <CP_APPOINTMENTS /> }],
        },
      ],
    },
    {
      path: '/',
      element: <OuterLayout />,
      children: [
        {
          path: '/',
          element: <RouteNavigator />,
        },
        {
          path: 'login',
          element: (
            <PublicRoute>
              <Login />
            </PublicRoute>
          ),
        },
        {
          path: 'register',
          element: (
            <PublicRoute>
              <Signup />
            </PublicRoute>
          ),
        },
        {
          path: 'dashboard',
          element: (
            <PublicRoute>
              <ChartsUsage />
            </PublicRoute>
          ),
        },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
