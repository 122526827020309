import { Box, Grid, Typography } from '@mui/material';

import ContentCard from '../../../common/ContentCard/ContentCard';
import CountCard from '../../../common/CountCard/CountCard';
import FilterComponent from '../../../common/FilterComponent/FilterComponent';
import StackedColumn from '../../../common/Charts/BarChart/StackedColumn';
import colors from '../../../theme/colors';
import { styled } from '@mui/material/styles';

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const StyledInnerContentCard = styled(ContentCard)({
  borderRadius: '20px',
  margin: '15px 0px',
});

const Heading = styled(Typography)({
  fontWeight: 500,
  fontSize: 25,
  color: '#000000',
});

const StyledFilterWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(1)};
  margin-top: ${(props) => props.theme.spacing(6)};
`;

const Forecast = () => {
  return (
    <StyledContentCard>
      <Grid item xs={12} md={10}>
        <StyledInnerContentCard>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">ForeCast</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
          </Grid>
        </StyledInnerContentCard>
      </Grid>
      <Grid item xs={12} md={2}>
        <StyledFilterWrapper>
          <FilterComponent heading="Branch" selectTitle="Select Branch" />
          <FilterComponent heading="Department" selectTitle="Select Dept." />
          <FilterComponent heading="Consultant" selectTitle="Select Conslt." />
          <FilterComponent heading="Status" selectTitle="Select Status" />
        </StyledFilterWrapper>
      </Grid>
    </StyledContentCard>
  );
};

export default Forecast;
