import { Divider, InputBase, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const FilterComponent = ({
  heading = 'Filter',
  options = [],
  selectTitle = 'Select',
  handleSelection
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState('');
  const [filterValue, setFilterValue] = React.useState('');
  const [filteredOptions, setFilteredOptions] = React.useState(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    handleSelection(option);
    handleCloseMenu();
  };

  useEffect(() => {
    if (filterValue) {
      const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(filterValue.toLowerCase()),
      );
      setFilteredOptions(filteredOptions);
    } else {
      setFilteredOptions(options);
    }
  }, [filterValue, options, selectedOption]);

  return (
    <Card
      sx={{
        height: 'auto',
        width: '80%',
        boxShadow: 'none',
        border: '1px solid #A6D7FF',
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          height: '30px',
          backgroundColor: '#A6D7FF',
          fontSize: '15px',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '5px',
        }}
      >
        <div>
          <Typography
            sx={{
              fontWeight: 400,
              color: '#000000',
            }}
          >
            {heading}
          </Typography>
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '10px',
          overflow: 'hidden',
        }}
      >
        {/* 
          Add selected option here and elipsis if the text is too long
        */}
        <Typography
          sx={{
            fontSize: '14px',
            color: '#116EBB',
            fontWeight: 400,
            ellipsis: true,
          }}
        >
          {selectedOption.value ? selectedOption.label : `${selectTitle}`}
        </Typography>
        <div
          sx={{
            marginRight: '4px',
            cursor: 'pointer',
          }}
          aria-label="filter-options"
          onClick={handleOpenMenu}
        >
          <ArrowDropDownOutlinedIcon
            sx={{
              color: '#116EBB',
              cursor: 'pointer',
            }}
          />
        </div>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ width: '400px', height: '350px' }}
      >
        <div>
          <InputBase
            sx={{
              height: '30px',
              width: '99%',
              backgroundColor: '#FFFFFF',
              fontSize: '14px',
              paddingLeft: '10px',
              border: '1px solid #000',
              margin: '1px 2px',
              '&:hover': {
                backgroundColor: '#FAFAFA',
              },
              '&:focus': {
                outline: 'none',
                backgroundColor: '#FFFFFF',
                borderColor: '#4DA6FF',
                boxShadow: '0 0 0 0.2rem rgba(77,166,255,.25)',
              },
            }}
            placeholder="Search"
            type="text"
            value={filterValue}
            onChange={handleFilterChange}
            onBlur={(e) => console.log('onBlur', e.target.value)}
          />
          <Divider sx={{ my: 1 }} />
          {filteredOptions.map((option) => (
            <MenuItem
              onClick={() => handleSelectOption(option)}
              sx={{ fontSize: '14px', fontWeight: 500 }}
              key={`${option.value}-${option.label}`}
            >
              {option.label}
            </MenuItem>
          ))}
        </div>
      </Menu>
    </Card>
  );
};

export default FilterComponent;
