import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { dateFilterParam, trimSpaces } from '../../../utils';
import { defaultEndDate, defaultStartDate } from '../../../utils/date-time';
import { filtersSelector, resetIsFilterApplied } from '../../../features/filters/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetDoctorsActivityQuery, useLazyGetDoctorsDetailsQuery } from '../../../api/doctorsApiSlice';

import BarChart from '../../../common/Charts/BarChart/BarChart';
import ContentCard from '../../../common/ContentCard/ContentCard';
import DataTable from '../../../common/DataTable/DataTable';
import DoctorsFilter from '../DoctorsFilter/DoctorsFilter';
import LineChart from '../../../common/Charts/LineChart/LineChart';
// import NivoTreeMap from '../../../common/Charts/TreeMap/NivoTreeMap';
import { styled } from '@mui/material/styles';

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center'
})

const StyledInnerContentCard = styled(ContentCard)({
  borderRadius: '20px',
  margin: '15px 0px'
})

const Heading = styled(Typography)({
  fontWeight: 500,
  fontSize: 25,
  color: '#000000'
})

const StyledFilterWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(1)};
  margin-top: ${(props) => props.theme.spacing(6)};
`

const Activity = () => {
  const dispatch = useDispatch();
  const {
    selectedBranch,
    selectedDepartment,
    selectedDoctorStatus,
    selectedStartDate,
    selectedEndDate
  } = useSelector(filtersSelector);

  const options = useMemo(() => {
    const {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    } = {
      branchName: selectedBranch ? trimSpaces(selectedBranch.label) : '',
      departmentName: selectedDepartment ? trimSpaces(selectedDepartment.label) : '',
      status: selectedDoctorStatus ? selectedDoctorStatus.label : '',
      startDate: selectedStartDate.format('DD-MM-YYYY'),
      endDate: selectedEndDate.format('DD-MM-YYYY'),
    };

    return {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    };
  }, [selectedBranch, selectedDepartment, selectedDoctorStatus, selectedStartDate, selectedEndDate]);

  const [showDepartmentDetails, setShowDepartmentDetails] = useState(false)
  const [showDetailsByNationality, setShowDetailsByNationality] = useState(false)

  /**
   * TODO: later handle error and isLoading..
   */
  const [getDoctorsActivity, { data }] = useLazyGetDoctorsActivityQuery();

  const [
    getDoctorsDetails,
    { data: doctorsDetails,
      error: doctorsDetailsError,
      isLoading: doctorsDetailsLoading,
      isFetching: doctorsDetailsFetching
    },
  ] = useLazyGetDoctorsDetailsQuery();

  useEffect(() => {
    getDoctorsActivity(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterApplied = () => {
    getDoctorsActivity(options);
    dispatch(resetIsFilterApplied());
  };

  const onResetFilter = () => {
    getDoctorsActivity({
      startDate: defaultStartDate.format('DD-MM-YYYY'),
      endDate: defaultEndDate.format('DD-MM-YYYY'),
    });
  };

  const handleDepartmentBarClick = (selectedBar) => {
    setShowDepartmentDetails(true);
    setShowDetailsByNationality(false);
    const selectedDepartmentName = data?.doctorDeptActivities && data?.doctorDeptActivities[selectedBar]?.['deptName'];
    getDoctorsDetails({
      ...options,
      departmentName: `${selectedDepartmentName}`
    });
  }

  const handleNationalityBarClick = (selectedBar) => {
    setShowDetailsByNationality(true);
    setShowDepartmentDetails(false);
    const selectedNation = data?.doctorNationalityActivities && data?.doctorNationalityActivities[selectedBar]?.['nationality'];
    getDoctorsDetails({
      ...options,
      nationality: `${selectedNation}`
    });
  }

  return (
    <StyledContentCard>
      <Grid item xs={12} md={10}>
        {/* Branch Wise Doctors */}
        <Grid container justifyContent='center'>
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent='center' // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5)
              }}
            >
              <Heading variant='h5'>Branch Wise Doctors</Heading>
            </Grid>
            <Grid
              container
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10)
              }}
            >
              <Suspense fallback={<Skeleton variant='rectangular' animation='wave' height={400} />}>
                <LineChart
                  data={
                    data?.doctorBranchWiseActivities?.branchHashMapList || []
                  }
                  xKey='x'
                  yKeys={
                    data?.doctorBranchWiseActivities?.branches || []
                  }
                  height={400}
                  curveType='smooth'
                  strokeWidth={2}
                  markerSize={6}
                  gridLineColor='#ccc'
                />
              </Suspense>
            </Grid>
          </StyledInnerContentCard>
        </Grid>

        {/* Department Wise Doctors */}
        <Grid container justifyContent='center'>
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent='center' // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5)
              }}
            >
              <Heading variant='h5'>Department Wise Doctors</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10)
              }}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <BarChart
                  data={
                    data?.doctorDeptActivities || []
                  }
                  isHorizontal={false}
                  height={400}
                  xKey='deptName'
                  yKey='totalDoctors'
                  onClick={(selectedBar) => handleDepartmentBarClick(selectedBar)}
                />
              </Suspense>
            </Grid>
            {
              showDepartmentDetails &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="Doctors Details"
                  columnDefs={[
                    {
                      headerName: 'Doctor Name',
                      field: 'doctorName',
                      filter: true,
                    },
                    {
                      headerName: 'Nationality',
                      field: 'nationality',
                      filter: true,
                    },
                    {
                      headerName: 'Gender',
                      field: 'gender',
                      filter: true,
                    },
                    {
                      headerName: 'DOB',
                      field: 'dob',
                      filter: 'agDateColumnFilter',
                      filterParams: dateFilterParam,
                    },
                    {
                      headerName: 'Designation',
                      field: 'designation',
                      filter: true,
                    },
                    {
                      headerName: 'Branch',
                      field: 'branchName',
                      filter: true,
                    },
                    {
                      headerName: 'Department',
                      field: 'deptName',
                      filter: true,
                    },
                    {
                      headerName: 'Total Consultations',
                      field: 'noOfConsultations',
                      filter: true,
                    },
                  ]}
                  data={doctorsDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={doctorsDetailsLoading || doctorsDetailsFetching}
                  origin={'doctors_stats'}
                  handleClose={() => {
                    setShowDepartmentDetails(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>

        {/* Nationality Wise Doctors */}
        <Grid container justifyContent='center'>
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent='center' // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5)
              }}
            >
              <Heading variant='h5'>Nationality Wise Doctors</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10)
              }}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <BarChart
                  data={
                    data?.doctorNationalityActivities || []
                  }
                  isHorizontal={false}
                  height={400}
                  xKey='nationality'
                  yKey='totalDoctors'
                  onClick={(selectedBar) => handleNationalityBarClick(selectedBar)}
                />
              </Suspense>
            </Grid>
            {
              showDetailsByNationality &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="Doctors Details"
                  columnDefs={[
                    {
                      headerName: 'Doctor Name',
                      field: 'doctorName',
                      filter: true,
                    },
                    {
                      headerName: 'Nationality',
                      field: 'nationality',
                      filter: true,
                    },
                    {
                      headerName: 'Gender',
                      field: 'gender',
                      filter: true,
                    },
                    {
                      headerName: 'DOB',
                      field: 'dob',
                      filter: 'agDateColumnFilter',
                      filterParams: dateFilterParam,
                    },
                    {
                      headerName: 'Designation',
                      field: 'designation',
                      filter: true,
                    },
                    {
                      headerName: 'Branch',
                      field: 'branchName',
                      filter: true,
                    },
                    {
                      headerName: 'Department',
                      field: 'deptName',
                      filter: true,
                    },
                    {
                      headerName: 'Total Consultations',
                      field: 'noOfConsultations',
                      filter: true,
                    },
                  ]}
                  data={doctorsDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={doctorsDetailsLoading || doctorsDetailsFetching}
                  origin={'doctors_stats'}
                  handleClose={() => {
                    setShowDetailsByNationality(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <DoctorsFilter
          onFilterApplied={onFilterApplied}
          onResetFilter={onResetFilter}
        />
      </Grid>
    </StyledContentCard>
  )
}

export default Activity
