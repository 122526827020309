import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React, { useState } from 'react';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Link } from 'react-router-dom';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { styled } from '@mui/material/styles';

const ListItemButton = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  '&.parent-item': {
    backgroundColor: '#1D74BE',
    fontWeight: '600',
    fontSize: '1.1rem',
    borderBottom: '0.1px solid #555555',
  },
  '&.nested-item': {
    // marginLeft: theme.spacing(4),
  },
  '& .MuiSvgIcon-root': {
    marginLeft: '-8px',
  },
  '&:hover': {
    '& .MuiTypography-root': {
      color: '#fff',
    },
  },
  '&.Mui-selected': {
    '& .MuiTypography-root': {
      color: '#fff',
    },
    '&:before': {
      content: '"\\2022"',
      marginLeft: '-15px',
      marginRight: '5px',
      fontSize: '1.5rem',
      color: '#fff',
    },
  },
  '&.Mui-selected:hover': {
    '& .MuiTypography-root': {
      color: '#fff',
    },
  },
}));

const MenuBar = (props) => {
  const { menuItems } = props;

  const [open, setOpen] = useState({
    1: true,
    11: true,
    111: true,
  });

  const handleExpand = (id) => {
    setOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const renderList = (items, isNested = false, isEndLevel = false) => {
    return items.map((item) => (
      <React.Fragment key={item.id}>
        <ListItemButton
          onClick={() => handleExpand(item.id)}
          sx={{
            pl: isNested ? (isEndLevel ? 8 : 7) : 4,
            bgcolor: isEndLevel
              ? 'inherit'
              : open[item.id]
              ? '#1D74BE'
              : 'inherit',
            '& .MuiTypography-root': {
              fontWeight: item.className === 'parent-item' ? '600' : 'normal',
              fontSize:
                item.className === 'parent-item'
                  ? '1.1rem'
                  : isNested
                  ? '0.9rem'
                  : '1rem',
              color: isEndLevel ? '#C3C3C3' : 'inherit',
            },
          }}
          className={`${item.className} ${isEndLevel ? 'end-level-item' : ''}`}
          component={isEndLevel ? Link : 'div'}
          to={isEndLevel ? item.link : undefined}
          selected={isEndLevel && item.link === window.location.pathname}
        >
          {item.icon && (
            <ListItemIcon
              sx={{
                minWidth: 'auto',
                color: '#fff',
                fontSize: '1rem',
                pr: 1,
              }}
            >
              {item.icon}
            </ListItemIcon>
          )}
          <ListItemText
            primary={item.title}
            sx={{
              '& MuiTypography-root ': {
                fontSize: isEndLevel ? '0.8rem' : isNested ? '0.7rem' : '1rem',
                color: isEndLevel ? '#b8bdc1' : 'inherit',
              },
            }}
          />
          {item.children && item.children.length > 0 && (
            <React.Fragment>
              {isNested ? (
                open[item.id] ? (
                  <RemoveRoundedIcon
                    sx={{
                      fontSize: '1.2rem',
                      marginRight: '15px',
                    }}
                  />
                ) : (
                  <AddRoundedIcon
                    sx={{
                      fontSize: '1.2rem',
                      marginRight: '15px',
                    }}
                  />
                )
              ) : open[item.id] ? (
                <ArrowDropDownOutlinedIcon
                  sx={{
                    fontSize: '2rem',
                  }}
                />
              ) : (
                <PlayArrowRoundedIcon
                  sx={{
                    fontSize: '1.5rem',
                  }}
                />
              )}
            </React.Fragment>
          )}
        </ListItemButton>
        {item.children && item.children.length > 0 && (
          <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderList(
                item.children,
                true,
                !item.children.some(
                  (child) => child.children && child.children.length > 0,
                ),
              )}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ));
  };

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: '#1A629D',
        '& .MuiListItemButton-root': {
          color: '#fff',
        },
        '& .MuiListItemText-primary': {
          color: '#fff',
        },
      }}
    >
      {renderList(menuItems)}
    </List>
  );
};

export default MenuBar;
