import AnalyticsIcon from '@mui/icons-material/Analytics';
import PieChartIcon from '@mui/icons-material/PieChart';

const menuItems = [
  {
    id: 1,
    title: 'Operation',
    icon: <PieChartIcon />,
    link: '/operation',
    className: 'parent-item',
    children: [
      {
        id: 11,
        title: 'CDSS',
        link: '/operation/cdss',
        children: [
          {
            id: 111,
            title: 'Appointments',
            link: '/app/appointments',
          },
          {
            id: 112,
            title: 'Footfall',
            link: '/app/footfall',
          },
          {
            id: 113,
            title: 'Patients',
            link: '/app/patients',
          },
          {
            id: 114,
            title: 'Doctors',
            link: '/app/doctors',
          },
          // {
          //   id: 115,
          //   title: 'Lab',
          //   link: '/app/lab',
          // },
          {
            id: 116,
            title: 'Revenue',
            link: '/app/revenue',
          },
          {
            id: 117,
            title: 'Claims',
            link: '/app/claims',
          },
        ],
      },
      {
        id: 12,
        title: 'BDSS',
        link: '/operation/bdss',
        children: [
          {
            id: 121,
            title: 'Appointments',
            link: '/app/appointments',
          },
        ],
      },
      {
        id: 13,
        title: 'LDSS',
        link: '/operation/ldss',
        children: [
          {
            id: 131,
            title: 'Appointments',
            link: '/app/appointments',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: 'Analytics',
    icon: <AnalyticsIcon />,
    link: '/analytics',
    className: 'parent-item',
    children: [
      {
        id: 21,
        title: 'Comparison',
        link: '/analytics/comparison',
        children: [
          {
            id: 211,
            title: 'Appointments',

            link: '/app/comparison/appointments',
          },
        ],
      },
    ],
  },
  // {
  //   id: 3,
  //   title: 'Reports',
  //   icon: <PieChartIcon />,
  //   link: '/reports',
  //   className: 'parent-item',
  //   children: [
  //     {
  //       id: 31,
  //       title: 'CDSS',
  //       link: '/reports/cdss',
  //       children: [],
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   title: 'Charts',
  //   icon: <PieChartIcon />,
  //   link: '/reports',
  //   className: 'parent-item',
  //   children: [
  //     {
  //       id: 41,
  //       title: 'All Charts',
  //       link: '/dashboard',
  //       children: [],
  //     },
  //   ],
  // },
];

export default menuItems;
