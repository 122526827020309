import FilterC from '../FilterC/FilterC';
import { setDoctorStatus } from '../../features/filters/filtersSlice';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

const data = [
    {
        id: 1,
        label: 'ACTIVE',
        value: 'ACTIVE'
    },
    {
        id: 2,
        label: 'INACTIVE',
        value: 'INACTIVE'
    },
]

const DoctorStatusFilter = ({
    selectedOption
}) => {
    const dispatch = useDispatch();

    const memoizedData = useMemo(() => {
        return data || [];
    }, [data]);

    const handleSelection = (option) => {
        dispatch(setDoctorStatus(option));
    }

    return (
        <FilterC
            heading="Status"
            selectTitle="Select Status"
            options={memoizedData}
            handleSelection={handleSelection}
            selectedOption={selectedOption}
        />
    )
}

export default DoctorStatusFilter;