import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';
import { capitalizeFirstLetter } from '../../../utils';
import { graphColors } from '../../../theme/colors';

const ChartType = {
  PIE: 'pie',
  DONUT: 'donut',
};

const NivoPie = ({
  chartType = ChartType.PIE,
  data = [],
  height = 450,
  innerRadius = chartType === ChartType.PIE ? 0 : 0.6,
  showLabels = true,
  showLegend = false,
  legendAlign = 'center',
  legends = [],
  itemWidth = 100,
  xKey = 'label',
  yKey = 'value',
  onClick
}) => {
  const theme = useTheme();

  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const chartData = useMemo(() => {
    return data.map((item) => {
      return {
        id: item[xKey],
        label: item[xKey],
        value: item[yKey],
      };
    });
  }, [data, xKey, yKey]);

  const legend = showLegend
    ? [
      {
        anchor: 'bottom',
        direction: isSmallerScreen ? 'column' : 'row',
        justify: false,
        translateX: 0,
        translateY: isSmallerScreen ? -50 : 56,
        itemsSpacing: 0,
        itemWidth: itemWidth,
        itemHeight: 18,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
          },
        ],
      },
    ]
    : [];

  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplay(true);
    }, 1);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      id="chart"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: height,
        width: '100%',
      }}
    >
      {display ? (
        <ResponsivePie
          data={chartData}
          colors={graphColors}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: theme.palette.primary[700],
                },
              },
              ticks: {
                line: {
                  stroke: theme.palette.primary[700],
                  strokeWidth: 1,
                },
                text: {
                  fill: theme.palette.primary[700],
                },
              },
            },
            legends: {
              text: {
                fill: theme.palette.primary[700],
              },
            },
          }}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={innerRadius}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          enableArcLinkLabels={true}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor={theme.palette.primary[700]}
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          enableArcLabels={false}
          arcLabelsRadiusOffset={0.4}
          arcLabelsSkipAngle={7}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]],
          }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          enableSlicesLabels={showLabels}
          legends={legend}
          onClick={(node) => {
            let { id } = node;
            const modifiedId = capitalizeFirstLetter(id);
            onClick(modifiedId)
          }}
        />
      ) : (
        <Skeleton
          variant="circular"
          width={height * 0.8}
          height={height * 0.8}
          animation="wave"
        />
      )}
    </div>
  );
};

NivoPie.propTypes = {
  chartType: PropTypes.oneOf(['pie', 'donut']),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
  height: PropTypes.string,
  innerRadius: PropTypes.number,
  showLabels: PropTypes.bool,
  showLegend: PropTypes.bool,
  legendPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  legendAlign: PropTypes.oneOf(['start', 'center', 'end']),
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      anchor: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
      direction: PropTypes.oneOf(['row', 'column']),
      justify: PropTypes.bool,
      translateX: PropTypes.number,
      translateY: PropTypes.number,
      itemsSpacing: PropTypes.number,
      itemWidth: PropTypes.number,
      itemHeight: PropTypes.number,
      itemDirection: PropTypes.oneOf(['left-to-right', 'right-to-left']),
      itemOpacity: PropTypes.number,
      symbolSize: PropTypes.number,
      symbolShape: PropTypes.oneOf(['circle', 'square', 'diamond', 'triangle']),
      symbolBorderColor: PropTypes.string,
      effects: PropTypes.arrayOf(
        PropTypes.shape({
          on: PropTypes.oneOf(['hover', 'focus']),
          style: PropTypes.shape({
            itemTextColor: PropTypes.string,
            itemBackground: PropTypes.string,
            itemOpacity: PropTypes.number,
          }),
        }),
      ),
    }),
  ),
};

export default NivoPie;
