import FilterC from '../FilterC/FilterC';
import { setPatientType } from '../../features/filters/filtersSlice';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

const data = [
    {
        id: 1,
        label: 'Male',
        value: 'Male'
    },
    {
        id: 2,
        label: 'Female',
        value: 'Female'
    },
    {
        id: 3,
        label: 'Others',
        value: 'Others'
    },
    {
        id: 4,
        label: 'Adults',
        value: 'Adults'
    },
    {
        id: 5,
        label: 'Kids',
        value: 'Kids'
    }
]

const PatientTypeFilter = ({
    selectedOption
}) => {
    const dispatch = useDispatch();

    const memoizedData = useMemo(() => {
        return data || [];
    }, [data]);

    const handleSelection = (option) => {
        dispatch(
            setPatientType(option)
        );
    }

    return (
        <FilterC
            heading="Patient Type"
            selectTitle="Select Patient Type"
            options={memoizedData}
            handleSelection={handleSelection}
            selectedOption={selectedOption}
        />
    )
}

export default PatientTypeFilter;