import { API_URL } from '../config';

/**
 * API ENDPOINTS CONSTANTS STARTS HERE
 */

// FILTERS RELATED ENDPOINTS
const BRANCHES_LIST = `${API_URL}filters/branches`;
const DEPARTMENTS_LIST = `${API_URL}filters/departments`;
const DOCTORS_LIST = `${API_URL}filters/consultants`;
const STATUS_LIST = `${API_URL}filters/appointmentStatus`;
const USERS_LIST = `${API_URL}filters/users`;

// APPOINTMENTS ENDPOINTS
const APPOINTMENTS_STATS = `${API_URL}appointments/stats`;
const APPOINTMENTS_ACTIVITY = `${API_URL}appointments/activity`;
const APPOINTMENTS_DETAILS = `${API_URL}appointments/details`;

// FOOTFALL ENDPOINTS
const FOOTFALL_STATS = `${API_URL}footfall/stats`;
const FOOTFALL_ACTIVITY = `${API_URL}footfall/activity`;
const FOOTFALL_DETAILS = `${API_URL}footfall/details`;

// PATIENTS ENDPOINTS
const PATIENTS_STATS = `${API_URL}patients/stats`;
const PATIENTS_ACTIVITY = `${API_URL}patients/activity`;
const PATIENTS_DETAILS = `${API_URL}patients/details`;

// DOCTORS ENDPOINTS
const DOCTORS_STATS = `${API_URL}doctors/stats`;
const DOCTORS_ACTIVITY = `${API_URL}doctors/activity`;
const DOCTORS_DETAILS = `${API_URL}doctors/details`;

// REVENUE ENDPOINTS
const REVENUE_STATS = `${API_URL}revenue/stats`;
const REVENUE_ACTIVITY = `${API_URL}revenue/activity`;
const REVENUE_DETAILS = `${API_URL}revenue/details`;

// CLAIMS ENDPOINTS
const CLAIMS_STATS = `${API_URL}claim/stats`;
const CLAIMS_ACTIVITY = `${API_URL}claim/activity`;
const CLAIMS_DETAILS = `${API_URL}claim/details`;

// APPOINTMENTS STATS COUNT CONSTANTS
const APPOINTMENTS_STATS_COUNT = {
  TOTAL: 'Total Appointments',
  BOOKED: 'Booked Appointments',
  NO_SHOW: 'No Show Appointments',
  CANCELLED: 'Cancelled Appointments',
  PENDING: 'Pending Appointments',
  CONFIRMED: 'Confirmed Appointments',
  COMPLETED: 'Completed Appointments',
};

const TOTAL_APPOINTMENTS = 'Total Appointments';
const BOOKED_APPOINTMENTS = 'Booked';
const NO_SHOW_APPOINTMENTS = 'No Show';
const CANCELLED_APPOINTMENTS = 'Cancelled';
const PENDING_APPOINTMENTS = 'Pending Appointments';
const CONFIRMED_APPOINTMENTS = 'Confirmed';
const WAITING_APPOINTMENTS = 'Waiting';
const COMPLETED_APPOINTMENTS = 'Completed Appointments';

// FOOTFALL STATS COUNT CONSTANTS
const TOTAL_VISITS = 'Total Visits';
const TOTAL_FIRST_TIME_VISITS = 'Total First Visits';
const TOTAL_REVISITS = 'Total ReVisits';
const TOTAL_NO_SHOWS = 'Total No Shows';
const TOTAL_FOLLOWS_UP_VISITS = 'Total FollowUp Visits';

// APPOINTMENTS STATS COUNT CONSTANTS
const TOTAL_PATIENTS = 'totalPatients';
const TOTAL_MALE_PATIENTS = 'totalMalePatients';
const TOTAL_FEMALE_PATIENTS = 'totalFemalePatients';
const TOTAL_OTHER_PATIENTS = 'totalOtherPatients';
const TOTAL_ADULT_PATIENTS = 'totalAdultPatients';
const TOTAL_CHILD_PATIENTS = 'totalKidsPatient';

// DOCTORS STATS COUNT CONSTANTS
const TOTAL_DOCTORS = 'totalDoctors';
const TOTAL_MALE_DOCTORS = 'totalMaleDoctors';
const TOTAL_FEMALE_DOCTORS = 'totalFemaleDoctors';
const TOTAL_OTHER_DOCTORS = 'totalOtherDoctors';

// REVENUE STATS COUNT CONSTANTS
const TOTAL_REVENUE = 'totalRevenue';
const TOTAL_DISCOUNT = 'totalDiscount';
const TOTAL_NET_REVENUE = 'totalNetRevenue';

// CLAIMS STATS COUNT CONSTANTS
const TOTAL_CLAIMED = 'totalClaimed';
const TOTAL_APPROVED = 'totalApproved';
const TOTAL_RECONCILED = 'totalReconciled';
const TOTAL_REJECTED = 'totalRejected';
const TOTAL_PENDING = 'totalPending';

// APPOINTMENTS STATUS CONSTANTS
export const APPOINTMENTS_STATUS = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
};

// EXPORT ALL CONSTANTS
export {
  BRANCHES_LIST,
  DEPARTMENTS_LIST,
  DOCTORS_LIST,
  STATUS_LIST,
  USERS_LIST,
  APPOINTMENTS_STATS,
  APPOINTMENTS_ACTIVITY,
  APPOINTMENTS_DETAILS,
  FOOTFALL_STATS,
  FOOTFALL_ACTIVITY,
  FOOTFALL_DETAILS,
  APPOINTMENTS_STATS_COUNT,
  BOOKED_APPOINTMENTS,
  TOTAL_APPOINTMENTS,
  NO_SHOW_APPOINTMENTS,
  CANCELLED_APPOINTMENTS,
  PENDING_APPOINTMENTS,
  CONFIRMED_APPOINTMENTS,
  WAITING_APPOINTMENTS,
  COMPLETED_APPOINTMENTS,
  TOTAL_VISITS,
  TOTAL_FIRST_TIME_VISITS,
  TOTAL_REVISITS,
  TOTAL_NO_SHOWS,
  TOTAL_FOLLOWS_UP_VISITS,
  PATIENTS_STATS,
  PATIENTS_ACTIVITY,
  PATIENTS_DETAILS,
  DOCTORS_STATS,
  DOCTORS_ACTIVITY,
  DOCTORS_DETAILS,
  TOTAL_PATIENTS,
  TOTAL_MALE_PATIENTS,
  TOTAL_FEMALE_PATIENTS,
  TOTAL_OTHER_PATIENTS,
  TOTAL_ADULT_PATIENTS,
  TOTAL_CHILD_PATIENTS,
  TOTAL_DOCTORS,
  TOTAL_MALE_DOCTORS,
  TOTAL_FEMALE_DOCTORS,
  TOTAL_OTHER_DOCTORS,
  REVENUE_STATS,
  REVENUE_ACTIVITY,
  REVENUE_DETAILS,
  TOTAL_REVENUE,
  TOTAL_DISCOUNT,
  TOTAL_NET_REVENUE,
  CLAIMS_STATS,
  CLAIMS_ACTIVITY,
  CLAIMS_DETAILS,
  TOTAL_CLAIMED,
  TOTAL_APPROVED,
  TOTAL_RECONCILED,
  TOTAL_REJECTED,
  TOTAL_PENDING,
};
