import { Check, Close } from '@mui/icons-material';

import { graphColors } from '../theme/colors';

const formatVisitsByTypeData = (inputObj) => {
  let visitsByTypeArr = [];
  let visitsByType = inputObj ? inputObj : {};
  for (const [key, value] of Object.entries(visitsByType)) {
    visitsByTypeArr.push({
      type: key.toUpperCase(),
      visitsByType: value,
      color: `${
        graphColors[
          Math.floor(Math.random() * Math.floor(graphColors.length - 1))
        ]
      }`,
    });
  }
  return visitsByTypeArr;
};

const pieChartFormatter = (inputObj = {}, xKey, yKey) => {
  let formattedArr = [];
  for (const [key, value] of Object.entries(inputObj)) {
    formattedArr.push({
      [xKey]: key.toUpperCase(),
      [yKey]: value,
      color: `${
        graphColors[
          Math.floor(Math.random() * Math.floor(graphColors.length - 1))
        ]
      }`,
    });
  }
  return formattedArr;
};

const formatPieChartData = (inputData, xKey, yKey) => {
  const pieChartData = [];

  if (!inputData || inputData.length === 0) {
    return pieChartData;
  }

  inputData.forEach((item) => {
    for (const [key, value] of Object.entries(item)) {
      pieChartData.push({
        [xKey]: key,
        [yKey]: value,
      });
    }
  });

  return pieChartData;
};

const formatNumberToCurrency = (number) => {
  if (number === undefined || number === null) return 0;

  if (typeof number !== 'string') {
    const units = ['', 'K', 'M', 'B', 'T', 'Q'];
    let i = 0;
    while (number >= 1000 && i < units.length - 1) {
      number /= 1000;
      i++;
    }
    const formattedNumber =
      number >= 10 ? number.toFixed(0) : number.toFixed(2);
    return `${formattedNumber}${units[i]}`;
  } else {
    return number;
  }
};

const dateFilterParam = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('-');
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0]),
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  // minValidYear: 2000,
  // maxValidYear: 2021,
  inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
};

const CheckRenderer = (params) => {
  if (params.value === 1) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '10px 0px',
        }}
      >
        <Check style={{ color: 'green' }} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '10px 0px',
        }}
      >
        <Close style={{ color: 'red' }} />
      </div>
    );
  }
};

const extractObjectFromArray = (arr, key) => {
  let extractedObj = {};

  if (!arr || arr.length === 0) return extractedObj;

  extractedObj = arr[key];
  return extractedObj;
};

const nivoPieChartFormatterObj = (inputObj = {}, xKey, yKey) => {
  let formattedArr = [];

  for (const [key, value] of Object.entries(inputObj)) {
    formattedArr.push({
      id: key.toLowerCase(),
      label: key.toLowerCase(),
      value: value,
    });
  }
  return formattedArr;
};

const nivoPieChartFormatterArr = (inputArr = [], xKey, yKey) => {
  let formattedArr = [];

  if (!inputArr || inputArr.length === 0) return formattedArr;

  inputArr.forEach((item) => {
    const key = item[xKey];
    const value = item[yKey];

    formattedArr.push({
      id: key.toLowerCase(),
      label: key.toLowerCase(),
      value: value,
      color: `${Math.floor(
        Math.random() * Math.floor(graphColors.length - 1),
      )}`,
    });
  });

  return formattedArr;
};

const capitalizeFirstLetter = (input) => {
  const arr = input.toLowerCase().split(' ');
  const newArr = arr.map((item) => {
    return item.charAt(0).toUpperCase() + item.slice(1);
  });
  return newArr.join(' ');
};

const trimSpaces = (input) => {
  return input?.trim();
};

export {
  formatVisitsByTypeData,
  formatPieChartData,
  pieChartFormatter,
  formatNumberToCurrency,
  dateFilterParam,
  CheckRenderer,
  extractObjectFromArray,
  nivoPieChartFormatterObj,
  nivoPieChartFormatterArr,
  capitalizeFirstLetter,
  trimSpaces,
};
