import { Button, styled } from "@mui/material";

const ExportButton = styled(Button)({
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 36,
    padding: '0 30px',
    '&:hover': {
        background: 'linear-gradient(45deg, #ef7a3c 30%, #FE6B8B 90%)',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .5)',
        textShadow: '0 0 1px #ffe8e8',
    },
});

export default ExportButton;