import ContentCard from '../../../common/ContentCard/ContentCard';
import DataTable from '../../../common/DataTable/DataTable';
import DateRange from '../../../common/DateRange/DateRange';
import { Grid } from '@mui/material';
import { dateFilterParam } from '../../../utils';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { useGetDoctorsDetailsQuery } from '../../../api/doctorsApiSlice';
import { useState } from 'react';

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const Details = () => {
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, 'years').format('DD-MM-YYYY'),
    endDate: moment().format('DD-MM-YYYY'),
  });
  // eslint-disable-next-line no-unused-vars
  const { data, error, isLoading, isFetching } = useGetDoctorsDetailsQuery(dateRange)

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  return (
    <Grid container display="flex" flexDirection="column" spacing={5}>
      <Grid item container justifyContent="center">
        <DateRange
          onDateRangeChange={handleDateRangeChange}
        />
      </Grid>
      <Grid item container>
        <StyledContentCard>
          <Grid item xs={12} md={12}>
            <DataTable
              gridTitle="Doctors Details"
              columnDefs={[
                {
                  headerName: 'Doctor Name',
                  field: 'doctorName',
                  filter: true,
                },
                {
                  headerName: 'Nationality',
                  field: 'nationality',
                  filter: true,
                },
                {
                  headerName: 'Gender',
                  field: 'gender',
                  filter: true,
                },
                {
                  headerName: 'DOB',
                  field: 'dob',
                  filter: 'agDateColumnFilter',
                  filterParams: dateFilterParam,
                },
                {
                  headerName: 'Designation',
                  field: 'designation',
                  filter: true,
                },
                {
                  headerName: 'Branch',
                  field: 'branchName',
                  filter: true,
                },
                {
                  headerName: 'Department',
                  field: 'deptName',
                  filter: true,
                },
                {
                  headerName: 'Total Consultations',
                  field: 'noOfConsultations',
                  filter: true,
                },
              ]}
              data={data && data.length > 0 ? data : []}
              pageSize={15}
              height={500}
              isLoading={isFetching}
            />
          </Grid>
        </StyledContentCard>
      </Grid>
    </Grid>
  );
};

export default Details;
