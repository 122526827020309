import { CheckRenderer, dateFilterParam, extractObjectFromArray } from '../../../utils';
import { Grid, Typography } from '@mui/material';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { TOTAL_DOCTORS, TOTAL_FEMALE_DOCTORS, TOTAL_MALE_DOCTORS, TOTAL_OTHER_DOCTORS } from '../../../constants';
import { defaultEndDate, defaultStartDate, getStartAndEndDates } from '../../../utils/date-time';
import { filtersSelector, resetIsFilterApplied } from '../../../features/filters/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetDoctorsDetailsQuery, useLazyGetDoctorsStatsQuery } from '../../../api/doctorsApiSlice';

import ContentCard from '../../../common/ContentCard/ContentCard';
import CountsView from '../../../common/CountsView/CountsView';
import DataTable from '../../../common/DataTable/DataTable';
import DoctorsFilter from '../DoctorsFilter/DoctorsFilter';
import SkeletonLoader from '../../../common/SkeletonLoader/SkeletonLoader';
import { colors } from '../../../theme/colors';
import { styled } from '@mui/material/styles';
import { trimSpaces } from '../../../utils';

const StackChart = lazy(() =>
  import('../../../common/Charts/BarChart/StackChart'),
);

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const StyledInnerContentCard = styled(ContentCard)({
  borderRadius: '20px',
  margin: '15px 0px',
});

const Heading = styled(Typography)({
  fontWeight: 500,
  fontSize: 25,
  color: '#000000',
});

const Stats = () => {
  const dispatch = useDispatch();
  const {
    selectedBranch,
    selectedDepartment,
    selectedDoctorStatus,
    selectedStartDate,
    selectedEndDate
  } = useSelector(filtersSelector);

  const [showDetails, setShowDetails] = useState(false);

  const options = useMemo(() => {
    const {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    } = {
      branchName: selectedBranch ? trimSpaces(selectedBranch.label) : '',
      departmentName: selectedDepartment ? trimSpaces(selectedDepartment.label) : '',
      status: selectedDoctorStatus ? selectedDoctorStatus.label : '',
      startDate: selectedStartDate.format('DD-MM-YYYY'),
      endDate: selectedEndDate.format('DD-MM-YYYY'),
    };

    return {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    };
  }, [selectedBranch, selectedDepartment, selectedDoctorStatus, selectedStartDate, selectedEndDate]);

  const [getDoctorStats, { data }] = useLazyGetDoctorsStatsQuery();

  const [
    getDoctorsDetails,
    { data: doctorsDetails,
      error: doctorsDetailsError,
      isLoading: doctorsDetailsLoading,
      isFetching: doctorsDetailsFetching
    },
  ] = useLazyGetDoctorsDetailsQuery();

  useEffect(() => {
    getDoctorStats(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterApplied = () => {
    getDoctorStats(options);
    dispatch(resetIsFilterApplied());
  };

  const handleStatsClick = (selectedBar) => {
    setShowDetails(true);
    const selectedMonth = extractObjectFromArray(data?.doctorMonthStats, selectedBar);

    const {
      startDate: selectedBarStartDate,
      endDate: selectedBarEndDate
    } = getStartAndEndDates(selectedMonth?.month || '');

    getDoctorsDetails({
      ...options,
      startDate: selectedBarStartDate,
      endDate: selectedBarEndDate,
    });
  };

  const onResetFilter = () => {
    getDoctorStats({
      startDate: defaultStartDate.format('DD-MM-YYYY'),
      endDate: defaultEndDate.format('DD-MM-YYYY'),
    });
  };

  return (
    <StyledContentCard>
      <Grid item xs={12} md={10}>
        <StyledInnerContentCard>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Doctors Stats</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
                py: (theme) => theme.spacing(2),
              }}
            >
              <Grid container>
                <CountsView
                  data={[
                    {
                      title: 'Total',
                      count: data?.doctorStats[
                        TOTAL_DOCTORS
                      ] || 0,
                      color: colors.primary[500],
                    },
                    {
                      title: 'Male',
                      count: data?.doctorStats[
                        TOTAL_MALE_DOCTORS
                      ] || 0,
                      color: colors.error[500],
                    },
                    {
                      title: 'Female',
                      count: data?.doctorStats[
                        TOTAL_FEMALE_DOCTORS
                      ] || 0,
                      color: colors.secondary[500],
                    },
                    {
                      title: 'Others',
                      count: data?.doctorStats[
                        TOTAL_OTHER_DOCTORS
                      ] || 0,
                      color: colors.success[500],
                      textColor: '#fff',
                    },
                  ]}
                />
              </Grid>
              <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                sx={{
                  mt: (theme) => theme.spacing(3),
                }}
              >
                <Suspense fallback={
                  <SkeletonLoader
                    skeletons={[
                      {
                        variant: 'rectangular',
                        height: 400,
                        animation: 'wave',
                      },
                    ]}
                  />
                }>
                  <StackChart
                    data={
                      data?.doctorMonthStats || []
                    }
                    xKey={'month'}
                    yKeys={[
                      TOTAL_MALE_DOCTORS,
                      TOTAL_FEMALE_DOCTORS,
                      TOTAL_OTHER_DOCTORS,
                    ]}
                    chartColors={[
                      colors.error[500],
                      colors.secondary[500],
                      colors.success[500],
                    ]}
                    onClick={(selectedBar) => handleStatsClick(selectedBar)}
                  />
                </Suspense>
              </Grid>
            </Grid>
          </Grid>
          {
            showDetails &&
            (<Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <DataTable
                gridTitle="Doctors Details"
                columnDefs={[
                  {
                    headerName: 'Doctor Name',
                    field: 'doctorName',
                    filter: true,
                  },
                  {
                    headerName: 'Nationality',
                    field: 'nationality',
                    filter: true,
                  },
                  {
                    headerName: 'Gender',
                    field: 'gender',
                    filter: true,
                  },
                  {
                    headerName: 'DOB',
                    field: 'dob',
                    filter: 'agDateColumnFilter',
                    filterParams: dateFilterParam,
                  },
                  {
                    headerName: 'Designation',
                    field: 'designation',
                    filter: true,
                  },
                  {
                    headerName: 'Branch',
                    field: 'branchName',
                    filter: true,
                  },
                  {
                    headerName: 'Department',
                    field: 'deptName',
                    filter: true,
                  },
                  {
                    headerName: 'Total Consultations',
                    field: 'noOfConsultations',
                    filter: true,
                  },
                ]}
                data={doctorsDetails || []}
                pageSize={15}
                height={300}
                isLoading={doctorsDetailsLoading || doctorsDetailsFetching}
                origin={'doctors_stats'}
                handleClose={() => {
                  setShowDetails(false);
                }}
              />
            </Grid>)
          }
        </StyledInnerContentCard>
      </Grid>
      <Grid item xs={12} md={2}>
        <DoctorsFilter
          onFilterApplied={onFilterApplied}
          onResetFilter={onResetFilter}
        />
      </Grid>
    </StyledContentCard>
  );
};

export default Stats;
