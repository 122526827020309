import { Card } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ styles }) => ({
  width: '100%',
  border: '1px solid #015092',
  borderRadius: 10,
  overflow: 'hidden',
  boxShadow: 'none',
  backgroundColor: '#FFFFFF',
  ...styles,
}));

const ContentCard = ({ children, styles, ...props }) => {
  return (
    <StyledCard styles={styles} {...props}>
      {children}
    </StyledCard>
  );
};

export default ContentCard;
