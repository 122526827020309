import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './DataTable.css';

import { useEffect, useMemo, useRef, useState } from 'react';

import { Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import ExportButton from '../Buttons/ExportButton';

const DataTable = ({
  gridTitle = 'Details',
  columnDefs = [],
  pageSize = 10,
  data = [],
  height = 500,
  isLoading = false,
  origin = 'Details',
  handleClose
}) => {
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      filter: true,
      floatingFilter: true,
      sortable: true,
      resizable: true,
    };
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };

  useEffect(() => {
    if (gridApi) {
      if (!isLoading) {
        if (data.length === 0) {
          gridApi.showNoRowsOverlay();
        } else {
          gridApi.hideOverlay();
        }
      } else {
        gridApi.showLoadingOverlay();
      }
    }
  }, [isLoading, gridApi, data]);

  return (
    <div
      style={{
        height: '60vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 20px',
          height: '60px',
        }}
      >
        <h4>{gridTitle}</h4>
        <ExportButton onClick={exportData}>Export Data</ExportButton>
        {/* 
          If Origin is not Details then show a Close Button
        */}
        {origin !== 'Details' && (
          <Button
            variant="outlined"
            color="error"
            className="close-button"
            onClick={() => {
              handleClose();
            }}
          >
            X
          </Button>
        )}
      </div>
      <div
        className="ag-theme-alpine"
        style={{
          flex: 1,
          width: '100%',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          rowData={data || []}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={pageSize}
          suppressCellFocus={true}
          columnDefs={columnDefs}
          overlayLoadingTemplate={
            '<span className="ag-overlay-loading-center">Please wait, Data Loading...</span>'
          }
          overlayNoRowsTemplate={
            '<span className="ag-overlay-loading-center">No Rows Found..</span>'
          }
        />
      </div>
    </div>
  );
}

export default DataTable;
