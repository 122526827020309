import { defaultEndDate, defaultStartDate } from '../../utils/date-time';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedBranch: null,
  selectedDepartment: null,
  selectedStatus: null,
  selectedConsultant: null,
  selectedVisitType: null,
  selectedPatientType: null,
  selectedDoctorStatus: null,
  selectedRevenueStatus: null,
  selectedClaimStatus: null,
  selectedStartDate: defaultStartDate,
  selectedEndDate: defaultEndDate,
  selectedUser: null,
  isFilterApplied: false,
  isResetEnabled: false,
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.selectedBranch = null;
      state.selectedDepartment = null;
      state.selectedStatus = null;
      state.selectedConsultant = null;
      state.selectedVisitType = null;
      state.selectedPatientType = null;
      state.selectedDoctorStatus = null;
      state.selectedStartDate = defaultStartDate;
      state.selectedEndDate = defaultEndDate;
      state.selectedUser = null;
      state.isFilterApplied = false;
      state.isResetEnabled = false;
    },
    setBranch: (state, action) => {
      state.selectedBranch = action.payload;
      state.selectedDepartment = null;
      state.selectedConsultant = null;
      state.isFilterApplied = true;
    },
    setDepartment: (state, action) => {
      state.selectedDepartment = action.payload;
      state.selectedConsultant = null;
      state.isFilterApplied = true;
    },
    setConsultant: (state, action) => {
      state.selectedConsultant = action.payload;
      state.isFilterApplied = true;
    },
    setStartDate: (state, action) => {
      state.selectedStartDate = action.payload;
      state.isFilterApplied = true;
    },
    setEndDate: (state, action) => {
      state.selectedEndDate = action.payload;
      state.isFilterApplied = true;
    },
    setStatus: (state, action) => {
      state.selectedStatus = action.payload;
      state.isFilterApplied = true;
    },
    setVisitType: (state, action) => {
      state.selectedVisitType = action.payload;
      state.isFilterApplied = true;
    },
    setPatientType: (state, action) => {
      state.selectedPatientType = action.payload;
      state.isFilterApplied = true;
    },
    setDoctorStatus: (state, action) => {
      state.selectedDoctorStatus = action.payload;
      state.isFilterApplied = true;
    },
    setRevenueStatus: (state, action) => {
      state.selectedRevenueStatus = action.payload;
      state.isFilterApplied = true;
    },
    setClaimStatus: (state, action) => {
      state.selectedClaimStatus = action.payload;
      state.isFilterApplied = true;
    },
    setUser: (state, action) => {
      state.selectedUser = action.payload;
      state.isFilterApplied = true;
    },
    resetBranch: (state) => {
      state.selectedBranch = null;
      state.selectedDepartment = null;
      state.selectedConsultant = null;
      state.isFilterApplied = true;
    },
    resetDepartment: (state) => {
      state.selectedDepartment = null;
      state.selectedConsultant = null;
      state.isFilterApplied = true;
    },
    resetConsultant: (state) => {
      state.selectedConsultant = null;
      state.isFilterApplied = true;
    },
    resetStatus: (state) => {
      state.selectedStatus = null;
      state.isFilterApplied = true;
    },
    resetVisitType: (state) => {
      state.selectedVisitType = null;
      state.isFilterApplied = true;
    },
    resetPatientType: (state) => {
      state.selectedPatientType = null;
      state.isFilterApplied = true;
    },
    resetDoctorStatus: (state) => {
      state.selectedDoctorStatus = null;
      state.isFilterApplied = true;
    },
    resetRevenueStatus: (state) => {
      state.selectedRevenueStatus = null;
      state.isFilterApplied = true;
    },
    resetClaimStatus: (state) => {
      state.selectedClaimStatus = null;
      state.isFilterApplied = true;
    },
    resetIsFilterApplied: (state) => {
      state.isFilterApplied = false;
    },
    setResetEnabled: (state, action) => {
      state.isResetEnabled = action.payload;
    },
  },
});

export const {
  resetFilters,
  setBranch,
  setDepartment,
  setConsultant,
  setVisitType,
  setPatientType,
  setDoctorStatus,
  setRevenueStatus,
  setClaimStatus,
  setStartDate,
  setEndDate,
  setStatus,
  setUser,
  resetBranch,
  resetDepartment,
  resetConsultant,
  resetStatus,
  resetVisitType,
  resetPatientType,
  resetDoctorStatus,
  resetRevenueStatus,
  resetClaimStatus,
  resetIsFilterApplied,
  setResetEnabled,
} = filtersSlice.actions;

export const filtersSelector = (state) => state.filters;

export default filtersSlice.reducer;
