import FilterC from '../FilterC/FilterC';
import { setClaimStatus } from '../../features/filters/filtersSlice';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

const data = [
    {
        id: 1,
        label: 'Pending For Claim',
        value: 'Pending For Claim'
    },
    {
        id: 2,
        label: 'Processing',
        value: 'Processing'
    },
    {
        id: 3,
        label: 'Reconciled',
        value: 'Reconciled'
    },
    {
        id: 4,
        label: 'Rejected',
        value: 'Rejected'
    },
    {
        id: 5,
        label: 'Approved',
        value: 'Approved'
    },
    {
        id: 6,
        label: 'Pending',
        value: 'Pending'
    },
    {
        id: 7,
        label: 'Cancelled',
        value: 'Cancelled'
    },
    {
        id: 8,
        label: 'To Redispatch',
        value: 'To Redispatch'
    },
    {
        id: 9,
        label: 'WriteOff',
        value: 'WriteOff'
    },
    {
        id: 10,
        label: 'Dispatched',
        value: 'Dispatched'
    }
]

const ClaimStatusFilter = ({
    selectedOption
}) => {
    const dispatch = useDispatch();

    const memoizedData = useMemo(() => {
        return data || [];
    }, [data]);

    const handleSelection = (option) => {
        dispatch(setClaimStatus(option));
    }

    return (
        <FilterC
            heading="Status"
            selectTitle="Select Status"
            options={memoizedData}
            handleSelection={handleSelection}
            selectedOption={selectedOption}
        />
    )
}

export default ClaimStatusFilter;