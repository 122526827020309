import { Grid, Typography } from '@mui/material';

import ContentCard from '../../../common/ContentCard/ContentCard';
import { styled } from '@mui/material/styles';

const StyledContentCard = styled(ContentCard)({
    borderRadius: '20px',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
});

const StyledInnerContentCard = styled(ContentCard)({
    borderRadius: '20px',
    margin: '15px 0px',
});

const Heading = styled(Typography)({
    fontWeight: 500,
    fontSize: 25,
    color: '#000000',
});

const Forecast = () => {
    return (
        <StyledContentCard>
            <Grid item xs={12} md={10}>
                <StyledInnerContentCard>
                    <Grid item xs={12}>
                        <Grid
                            container
                            justifyContent="center"
                            sx={{
                                py: (theme) => theme.spacing(5),
                            }}
                        >
                            <Heading variant="h5">ForeCast Coming Soon..</Heading>
                        </Grid>
                    </Grid>
                </StyledInnerContentCard>
            </Grid>
        </StyledContentCard>
    );
};

export default Forecast;
