import { memo, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Skeleton } from '@mui/material';
import { graphColors } from '../../../theme/colors';

const LineChart = memo(
  ({
    chartType = 'line',
    options = {},
    data = [],
    height = 350,
    showToolbar = true,
    enableZoom = true,
    showLegend = true,
    xKey = 'x',
    yKeys = [],
    borderRadius = 10,
    strokeWidth = 2,
    xaxisType = 'text',
  }) => {
    const chartSeries = useMemo(() => {
      return yKeys.map((yKey) => {
        return {
          name: yKey,
          data: data.map((item) => item[yKey]),
        };
      });
    }, [data, yKeys]);

    const chartOptions = useMemo(() => {
      const baseOptions = {
        chart: {
          type: chartType, // set the chart type
          height,
          toolbar: {
            show: showToolbar,
          },
          zoom: {
            enabled: enableZoom,
          },
          ...options,
        },
        legend: {
          show: showLegend,
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'category',
          categories: data.map((item) => item[xKey]),
        },
        colors: graphColors,
      };

      if (chartType === 'line') {
        return {
          ...baseOptions,
          stroke: {
            width: strokeWidth,
            curve: 'smooth', // make the lines smooth
          },
        };
      }

      if (chartType === 'area') {
        return {
          ...baseOptions,
          stroke: {
            width: strokeWidth,
            curve: 'smooth',
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
            strokeColor: '#fff',
            strokeWidth: 3,
            strokeOpacity: 1,
            fillOpacity: 1,
            hover: {
              size: 6,
            },
          },
          xaxis: {
            type: xaxisType,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              offsetX: 14,
              offsetY: -5,
            },
            tooltip: {
              enabled: true,
            },
          },
          grid: {
            padding: {
              left: -5,
              right: 5,
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
          },
          fill: {
            type: 'solid',
            fillOpacity: 0.7,
          },
        };
      }

      return baseOptions;
    }, [
      chartType,
      options,
      height,
      showToolbar,
      enableZoom,
      data,
      xKey,
      strokeWidth,
      showLegend,
    ]);

    const [display, setDisplay] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDisplay(true);
      }, 1);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    return (
      <div style={{ width: '100%' }}>
        {display ? (
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type={chartType} // set the chart type
            height={height}
          />
        ) : (
          <Skeleton variant="rectangular" animation="wave" height={height} />
        )}
      </div>
    );
  },
);

LineChart.propTypes = {
  chartType: PropTypes.oneOf(['line', 'area']).isRequired,
  data: PropTypes.array.isRequired,
  xKey: PropTypes.string.isRequired,
  yKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.object,
  height: PropTypes.number,
  showToolbar: PropTypes.bool,
  enableZoom: PropTypes.bool,
  borderRadius: PropTypes.number,
  strokeWidth: PropTypes.number,
};

export default LineChart;
