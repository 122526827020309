import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { defaultEndDate, defaultStartDate } from '../../../utils/date-time';
import { filtersSelector, resetIsFilterApplied } from '../../../features/filters/filtersSlice';
import { nivoPieChartFormatterObj, trimSpaces } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetRevenueActivityQuery, useLazyGetRevenueDetailsQuery } from '../../../api/revenueApiSlice';

import BarChart from '../../../common/Charts/BarChart/BarChart';
import ContentCard from '../../../common/ContentCard/ContentCard';
import DataTable from '../../../common/DataTable/DataTable';
import LineChart from '../../../common/Charts/LineChart/LineChart';
import NivoPie from '../../../common/Charts/PieChart/NivoPie';
import PieChart from '../../../common/Charts/PieChart/PieChart';
import RevenueFilter from '../RevenueFilter/RevenueFilter';
import TreeMap from '../../../common/Charts/TreeMap/TreeMap';
import { styled } from '@mui/material/styles';

const StyledContentCard = styled(ContentCard)({
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const StyledInnerContentCard = styled(ContentCard)({
  borderRadius: '20px',
  margin: '15px 0px',
});

const Heading = styled(Typography)({
  fontWeight: 500,
  fontSize: 25,
  color: '#000000',
});

const StyledFilterWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(1)};
  margin-top: ${(props) => props.theme.spacing(6)};
`;

const Activity = () => {
  const dispatch = useDispatch();
  const {
    selectedBranch,
    selectedDepartment,
    selectedRevenueStatus,
    selectedStartDate,
    selectedEndDate
  } = useSelector(filtersSelector);

  const options = useMemo(() => {
    const {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    } = {
      branchName: selectedBranch ? trimSpaces(selectedBranch.label) : '',
      departmentName: selectedDepartment ? trimSpaces(selectedDepartment.label) : '',
      status: selectedRevenueStatus ? trimSpaces(selectedRevenueStatus.label) : '',
      startDate: selectedStartDate.format('DD-MM-YYYY'),
      endDate: selectedEndDate.format('DD-MM-YYYY'),
    };

    return {
      branchName,
      departmentName,
      consultantName,
      status,
      startDate,
      endDate,
    };
  }, [selectedBranch, selectedDepartment, selectedRevenueStatus, selectedStartDate, selectedEndDate]);

  const [showDepartmentDetails, setShowDepartmentDetails] = useState(false)
  const [showDetailsByService, setShowDetailsByService] = useState(false)
  const [showDetailsByPaymentMode, setShowDetailsByPaymentMode] = useState(false)
  const [showDetailsByInsuranceProvider, setShowDetailsByInsuranceProvider] = useState(false)

  /**
   * TODO: later handle error and isLoading..
   */
  const [getRevenueActivity, { data }] = useLazyGetRevenueActivityQuery();

  const [
    getRevenueDetails,
    { data: revenueDetails,
      error: revenueDetailsError,
      isLoading: revenueDetailsLoading,
      isFetching: revenueDetailsFetching
    },
  ] = useLazyGetRevenueDetailsQuery();

  useEffect(() => {
    getRevenueActivity(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterApplied = () => {
    getRevenueActivity(options);
    dispatch(resetIsFilterApplied());
  };

  const onResetFilter = () => {
    getRevenueActivity({
      startDate: defaultStartDate.format('DD-MM-YYYY'),
      endDate: defaultEndDate.format('DD-MM-YYYY'),
    });
  };

  const handleDepartmentBarClick = (selectedBar) => {
    setShowDepartmentDetails(true);
    setShowDetailsByService(false);
    setShowDetailsByPaymentMode(false);
    setShowDetailsByInsuranceProvider(false);
    const selectedDepartmentName = data?.revenueDeptActivities && data?.revenueDeptActivities[selectedBar]?.['deptName'];
    getRevenueDetails({
      ...options,
      departmentName: `${selectedDepartmentName}`
    });
  }

  return (
    <StyledContentCard>
      <Grid item xs={12} md={10}>
        {/* Branch Wise Revenue */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Branch Wise Revenue</Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense
                fallback={
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={400}
                  />
                }
              >
                <LineChart
                  data={
                    data?.revenueBranchWiseActivity?.branchHashMapList || []
                  }
                  xKey="x"
                  yKeys={
                    data?.revenueBranchWiseActivity?.branches || []
                  }
                  height={400}
                  curveType="smooth"
                  strokeWidth={2}
                  markerSize={6}
                  gridLineColor="#ccc"
                />
              </Suspense>
            </Grid>
          </StyledInnerContentCard>
        </Grid>

        {/* Department Wise Revenue */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Department Wise Revenue</Heading>
            </Grid>
            {/* 
              Chart cards here  we will show cards and charts
            */}
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={
                <Skeleton variant='rectangular' animation='wave' height={400} />
              }>
                <BarChart
                  data={
                    data?.revenueDeptActivities || []
                  }
                  isHorizontal={false}
                  height={400}
                  xKey="deptName"
                  yKey="totalRevenue"
                  onClick={(selectedBar) => handleDepartmentBarClick(selectedBar)}
                />
              </Suspense>
            </Grid>
            {
              showDepartmentDetails &&
              (<Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
                spacing={2}
                sx={{
                  px: (theme) => theme.spacing(10),
                }}
              >
                <DataTable
                  gridTitle="Revenue Details"
                  columnDefs={[
                    {
                      headerName: 'Invoice Id',
                      field: 'masterInvoiceId',
                      filter: true,
                    },
                    // {
                    //   headerName: 'Booking Date',
                    //   field: 'bookingDate',
                    //   filter: 'agDateColumnFilter',
                    //   filterParams: dateFilterParam,
                    // },
                    {
                      headerName: 'Branch Name',
                      field: 'branchName',
                      filter: true,
                    },
                    {
                      headerName: 'Department Name',
                      field: 'deptName',
                      filter: true,
                    },
                    {
                      headerName: 'Service Name',
                      field: 'serviceName',
                      filter: true,
                    },
                    {
                      headerName: 'Sponsor',
                      field: 'sponsorName',
                      filter: true,
                    },
                    { headerName: 'Invoice Amount', field: 'invoiceAmount', filter: true, },
                    { headerName: 'Discount', field: 'discount', filter: true, },
                    { headerName: 'Net Amount', field: 'netAmount', filter: true, },
                    { headerName: 'Invoice Status', field: 'invoiceStatus', filter: true, },
                    { headerName: 'Employee Name', field: 'employeeName', filter: true, },
                  ]}
                  data={revenueDetails || []}
                  pageSize={15}
                  height={300}
                  isLoading={revenueDetailsLoading || revenueDetailsFetching}
                  origin={'revenue_stats'}
                  handleClose={() => {
                    setShowDepartmentDetails(false);
                  }}
                />
              </Grid>)
            }
          </StyledInnerContentCard>
        </Grid>

        {/* Consultant Wise Revenue */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center" // add to center align the child cards
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Consultant Wise Revenue</Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense
                fallback={
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={400}
                  />
                }
              >
                <TreeMap
                  data={
                    data?.revenueConsultantActivities || []
                  }
                  height={'400px'}
                  xKey="consultantName"
                  yKey="totalRevenue"
                />
              </Suspense>
            </Grid>
          </StyledInnerContentCard>
        </Grid>

        {/* Service Wise Revenue */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center"
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Service Wise Revenue</Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={400}
                />
              }>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {/* <NivoPie
                    data={
                      nivoPieChartFormatterArr(
                        data?.revenueServiceActivities || [],
                        'serviceName',
                        'totalRevenue'
                      )
                    }
                    height={400}
                    xKey="serviceName"
                    yKey="totalRevenue"
                  /> */}
                  <PieChart
                    data={data?.revenueServiceActivities || []}
                    height={400}
                    xKey="serviceName"
                    yKey="totalRevenue"
                    showLegend={false}
                  />
                </div>
              </Suspense>
            </Grid>
          </StyledInnerContentCard>
        </Grid>

        {/* Revenue By Payment Mode */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center"
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Revenue By Payment Mode</Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={400}
                />
              }>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <NivoPie
                    data={nivoPieChartFormatterObj(
                      data?.revenueInsuranceActivity.revenuePaymentModemap || {}
                    )}
                    height={400}
                  />
                </div>
              </Suspense>
            </Grid>
          </StyledInnerContentCard>
        </Grid>

        {/* Insurance Providers Wise Revenue */}
        <Grid container justifyContent="center">
          <StyledInnerContentCard>
            <Grid
              container
              justifyContent="center"
              sx={{
                py: (theme) => theme.spacing(5),
              }}
            >
              <Heading variant="h5">Insurance Providers Wise Revenue</Heading>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              sx={{
                px: (theme) => theme.spacing(10),
              }}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {/* <NivoPie
                    data={pieChartFormatter(
                      {
                        Bupa: 13053948.620000001,
                        MedGulf: 12865495.659,
                        Tawuniya: 3478374.48,
                        AXA: 1619441.75,
                        TCS: 1480422.47,
                      },
                      'sponsorName',
                      'totalRevenue',
                    )}
                    height={400}
                    xKey="sponsorName"
                    yKey="totalRevenue"
                  /> */}
                  <PieChart
                    data={data?.revenueInsuranceActivity.revenueSponsorActivityList || []}
                    height={400}
                    xKey="sponsorName"
                    yKey="totalRevenue"
                    showLegend={false}
                  />
                </div>
              </Suspense>
            </Grid>
          </StyledInnerContentCard>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <RevenueFilter
          onFilterApplied={onFilterApplied}
          onResetFilter={onResetFilter}
        />
      </Grid>
    </StyledContentCard>
  );
};

export default Activity;
