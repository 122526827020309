import { memo, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';
import { graphColors } from '../../../theme/colors';

const StackChart = memo(
  ({
    options = {},
    data = [],
    height = 350,
    isGrouped = false,
    isHorizontal = false,
    showToolbar = true,
    enableZoom = true,
    totalEnabled = true,
    showLegend = true,
    legendPosition = 'bottom',
    xKey = 'x',
    yKeys = [],
    borderRadius = 10,
    chartColors = graphColors,
    onClick
  }) => {
    const chartSeries = useMemo(() => {
      return yKeys.map((yKey) => {
        return {
          name: yKey,
          data: data.map((item) =>
            item[yKey] === null || item[yKey] === undefined ? 0 : item[yKey],
          ),
        };
      });
    }, [data, yKeys]);

    const chartOptions = useMemo(() => {
      return {
        chart: {
          type: 'bar',
          height,
          stacked: isGrouped ? false : true,
          toolbar: {
            show: showToolbar,
          },
          events: {
            dataPointSelection: function (event, chartContext, config) {
              const selectedBar = config.dataPointIndex;
              onClick(selectedBar)
            }
          },
          zoom: {
            enabled: enableZoom,
          },
          ...options,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: isHorizontal,
            // borderRadius,
            dataLabels: {
              total: {
                enabled: totalEnabled,
                style: {
                  fontSize: '13px',
                  fontWeight: 600,
                },
              },
            },
          },
        },
        legend: {
          show: showLegend,
          position: legendPosition,
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          type: 'category',
          categories: data.map((item) => item[xKey]),
          tickPlacement: 'on',
        },
        fill: {
          opacity: 1,
        },
        colors: chartColors,
      };
    }, [
      options,
      height,
      isGrouped,
      isHorizontal,
      showToolbar,
      enableZoom,
      totalEnabled,
      data,
      showLegend,
      legendPosition,
      xKey,
      borderRadius,
      chartColors,
    ]);

    const [display, setDisplay] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDisplay(true);
      }, 1);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    return (
      <div style={{ width: '100%' }}>
        {display ? (
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={height}
          />
        ) : (
          <SkeletonLoader
            skeletons={[
              {
                height: height,
                variant: 'rectangular',
              },
            ]}
          />
        )}
      </div>
    );
  },
);

StackChart.propTypes = {
  data: PropTypes.array.isRequired,
  xKey: PropTypes.string.isRequired,
  yKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.object,
  height: PropTypes.number,
  showToolbar: PropTypes.bool,
  enableZoom: PropTypes.bool,
  borderRadius: PropTypes.number,
};

export default StackChart;
