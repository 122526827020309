import FilterC from '../FilterC/FilterC'
import { setBranch } from '../../features/filters/filtersSlice';
import { useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { useGetBranchesQuery } from '../../api/filterApiSlice'

const BranchFilter = ({ handleSelectedBranch, selectedOption }) => {

    const dispatch = useDispatch();

    const {
        data
    } = useGetBranchesQuery();

    const handleSelection = useCallback((option) => {
        handleSelectedBranch(option);
        dispatch(setBranch(option));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <FilterC
            heading="Branch"
            selectTitle="Select Branch"
            options={data && data.length > 0 ? data : []}
            handleSelection={handleSelection}
            selectedOption={selectedOption}
        />
    )
}

export default BranchFilter