import { Box, Button, Grid, styled } from '@mui/material';
import { filtersSelector, resetFilters, setEndDate, setResetEnabled, setStartDate } from '../../../features/filters/filtersSlice';
import { useDispatch, useSelector } from 'react-redux';

import BranchFilter from '../../../common/BranchFilter/BranchFilter';
import ConsultantsFilter from '../../../common/ConsultantsFilter/ConsultantsFilter';
import CustomDatePicker from '../../../common/CustomDatePicker/CustomDatePicker';
import DepartmentsFilter from '../../../common/DepartmentsFilter/DepartmentsFilter';
import StatusFilter from '../../../common/StatusFilter/StatusFilter';
import { useEffect } from 'react';

const StyledFilterWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(1)};
  margin-top: ${(props) => props.theme.spacing(6)};
`;

const AppointmentsFilter = ({
    onFilterApplied,
    onResetFilter,
}) => {
    const dispatch = useDispatch();

    const {
        selectedBranch,
        selectedDepartment,
        selectedConsultant,
        selectedStatus,
        selectedStartDate,
        selectedEndDate,
        isFilterApplied,
        isResetEnabled,
    } = useSelector(filtersSelector);

    const handleApplyFilter = () => {
        dispatch(setResetEnabled(true));
        onFilterApplied();
    };
    const handleSelectedBranch = (option) => {
        console.log('');
    };

    const handleStartDateChange = (date) => {
        dispatch(setStartDate(date));
    };

    const handleEndDateChange = (date) => {
        dispatch(setEndDate(date));
    };

    const handleResetFilter = () => {
        dispatch(resetFilters());
        dispatch(setResetEnabled(false));
        onResetFilter();
    };

    // Reset filters on unmount
    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledFilterWrapper>
            <CustomDatePicker
                heading="Start Date"
                currentSelectedDate={
                    selectedStartDate
                }
                handleSelection={
                    handleStartDateChange
                }
            />
            <CustomDatePicker
                heading="End Date"
                currentSelectedDate={
                    selectedEndDate
                }
                handleSelection={
                    handleEndDateChange
                }
            />
            <BranchFilter
                handleSelectedBranch={handleSelectedBranch}
                selectedOption={selectedBranch}
            />
            <DepartmentsFilter
                selectedOption={selectedDepartment}
            />
            <ConsultantsFilter
                selectedOption={selectedConsultant}
            />
            <StatusFilter
                selectedOption={selectedStatus}
            />
            <Grid container spacing={2} sx={{
                marginTop: '5px',
                mx: theme => theme.spacing(2),
            }}>
                <Grid item xs={12} sm={12} md={6}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleApplyFilter}
                        disabled={!isFilterApplied}
                        size="medium"
                        fullWidth
                    >
                        Apply
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleResetFilter}
                        disabled={!isResetEnabled}
                        size="medium"
                        fullWidth
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
        </StyledFilterWrapper>
    );
};

export default AppointmentsFilter;
