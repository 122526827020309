import { Grid, Typography, styled } from '@mui/material';
import { Suspense, lazy } from 'react';

import BarChart from '../common/Charts/BarChart/BarChart';
import { Box } from '@mui/system';
import ContentCard from '../common/ContentCard/ContentCard';
import LineChart from '../common/Charts/LineChart/LineChart';
import NivoPie from '../common/Charts/PieChart/NivoPie';
// import NivoTreeMap from '../common/Charts/TreeMap/NivoTreeMap';
import PieChart from '../common/Charts/PieChart/PieChart';
import ScatterChart from '../common/Charts/ScatterChart/ScatterChart';
// import NivoBarChart from '../common/Charts/BarChart/NivoBarChart';
import StackChart from '../common/Charts/BarChart/StackChart';
import StackedColumn from '../common/Charts/BarChart/StackedColumn';
// import TreeMap from '../common/Charts/TreeMap/TreeMap';
import { colors } from '../theme/colors';
import dashboardReport from '../Data/dashboardReport';

const TreeMap = lazy(() => import('../common/Charts/TreeMap/TreeMap'));
const NivoTreeMap = lazy(() => import('../common/Charts/TreeMap/NivoTreeMap'));

const StyledContentCard = styled(ContentCard)({
  width: '100%',
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
});

const StyledInnerContentCard = styled(ContentCard)({
  borderRadius: '20px',
  margin: '15px 0px',
});

const Heading = styled(Typography)({
  fontWeight: 500,
  fontSize: 25,
  color: '#000000',
});

const ChartsUsage = () => {
  return (
    <StyledContentCard>
      <Grid container spacing={2}>
        {/* Bar Chart - Apex */}
        <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>React Apex - Bar Chart</Heading>
            </Box>
            <BarChart
              data={[
                {
                  countByDepartment: 22,
                  department: 'department A',
                },
                {
                  countByDepartment: 74,
                  department: 'department B',
                },
                {
                  countByDepartment: 123,
                  department: 'department C',
                },
                {
                  countByDepartment: 81,
                  department: 'Class D',
                },
                {
                  countByDepartment: 45,
                  department: 'Class E',
                },
                {
                  countByDepartment: 23,
                  department: 'Class F',
                },
                {
                  countByDepartment: 12,
                  department: 'Class G',
                },
                {
                  countByDepartment: 22,
                  department: 'department A',
                },
                {
                  countByDepartment: 74,
                  department: 'department B',
                },
                {
                  countByDepartment: 123,
                  department: 'department C',
                },
                {
                  countByDepartment: 81,
                  department: 'Class D',
                },
                {
                  countByDepartment: 45,
                  department: 'Class E',
                },
                {
                  countByDepartment: 23,
                  department: 'Class F',
                },
                {
                  countByDepartment: 12,
                  department: 'Class G',
                },
              ]}
              isHorizontal={false}
              height={400}
              xKey="department"
              yKey="countByDepartment"
            />
          </StyledInnerContentCard>
        </Grid>

        {/* Horizontal Bar Chart - Apex */}
        <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>React Apex - Horizontal Bar Chart</Heading>
            </Box>
            <BarChart
              data={[
                {
                  countByDepartment: 22,
                  department: 'department A',
                },
                {
                  countByDepartment: 74,
                  department: 'department B',
                },
                {
                  countByDepartment: 123,
                  department: 'department C',
                },
                {
                  countByDepartment: 81,
                  department: 'Class D',
                },
                {
                  countByDepartment: 45,
                  department: 'Class E',
                },
                {
                  countByDepartment: 23,
                  department: 'Class F',
                },
                {
                  countByDepartment: 12,
                  department: 'Class G',
                },
                {
                  countByDepartment: 22,
                  department: 'department A',
                },
                {
                  countByDepartment: 74,
                  department: 'department B',
                },
                {
                  countByDepartment: 123,
                  department: 'department C',
                },
                {
                  countByDepartment: 81,
                  department: 'Class D',
                },
                {
                  countByDepartment: 45,
                  department: 'Class E',
                },
                {
                  countByDepartment: 23,
                  department: 'Class F',
                },
                {
                  countByDepartment: 12,
                  department: 'Class G',
                },
              ]}
              isHorizontal={true}
              height={400}
              xKey="department"
              yKey="countByDepartment"
            />
          </StyledInnerContentCard>
        </Grid>

        {/* Stacked Column - Apex */}
        <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Apex - Stacked Column</Heading>
            </Box>
            <StackedColumn
              series={[
                {
                  name: 'Total',
                  data: [44, 55, 41, 67, 22, 43, 21],
                },
                {
                  name: 'Cancelled',
                  data: [13, 23, 20, 8, 13, 27, 33],
                },
                {
                  name: 'No Show',
                  data: [11, 17, 15, 15, 21, 14, 15],
                },
              ]}
              categories={['Total', 'Cancelled', 'No  Show']}
              //   borderRadius={20}
            />
          </StyledInnerContentCard>
        </Grid>

        {/* Stack Column - Apex */}
        <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Apex - Stacked Column</Heading>
            </Box>
            <StackChart
              data={[
                { x: 'Jan', Total: 10, Cancelled: 20, 'No  Show': 30 },
                { x: 'Feb', Total: 15, Cancelled: 25, 'No  Show': 35 },
                { x: 'Mar', Total: 20, Cancelled: 30, 'No  Show': 40 },
                { x: 'Apr', Total: 25, Cancelled: 35, 'No  Show': 45 },
                { x: 'May', Total: 65, Cancelled: 75, 'No  Show': 85 },
                { x: 'Jun', Total: 35, Cancelled: 45, 'No  Show': 55 },
                { x: 'Jul', Total: 40, Cancelled: 50, 'No  Show': 60 },
                { x: 'Aug', Total: 45, Cancelled: 55, 'No  Show': 65 },
                { x: 'Sep', Total: 50, Cancelled: 60, 'No  Show': 70 },
                { x: 'Oct', Total: 55, Cancelled: 65, 'No  Show': 75 },
                { x: 'Nov', Total: 60, Cancelled: 70, 'No  Show': 80 },
                { x: 'Dec', Total: 30, Cancelled: 40, 'No  Show': 50 },
              ]}
              xKey={'x'}
              yKeys={['Total', 'Cancelled', 'No  Show']}
              chartColors={[
                colors.primary[500],
                colors.error[500],
                colors.secondary[500],
              ]}
              borderRadius={20}
            />
          </StyledInnerContentCard>
        </Grid>

        {/* Grouped Bar Column - Apex */}
        <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Apex - Grouped Bar Chart</Heading>
            </Box>
            <StackChart
              data={[
                { x: 'Jan', Total: 10, Cancelled: 20, 'No  Show': 30 },
                { x: 'Feb', Total: 15, Cancelled: 25, 'No  Show': 35 },
                { x: 'Mar', Total: 20, Cancelled: 30, 'No  Show': 40 },
                { x: 'Apr', Total: 25, Cancelled: 35, 'No  Show': 45 },
                { x: 'May', Total: 65, Cancelled: 75, 'No  Show': 85 },
                { x: 'Jun', Total: 35, Cancelled: 45, 'No  Show': 55 },
                { x: 'Jul', Total: 40, Cancelled: 50, 'No  Show': 60 },
                { x: 'Aug', Total: 45, Cancelled: 55, 'No  Show': 65 },
                { x: 'Sep', Total: 50, Cancelled: 60, 'No  Show': 70 },
                { x: 'Oct', Total: 55, Cancelled: 65, 'No  Show': 75 },
                { x: 'Nov', Total: 60, Cancelled: 70, 'No  Show': 80 },
                { x: 'Dec', Total: 30, Cancelled: 40, 'No  Show': 50 },
              ]}
              xKey={'x'}
              yKeys={['Total', 'Cancelled', 'No  Show']}
              chartColors={[
                colors.primary[500],
                colors.error[500],
                colors.secondary[500],
              ]}
              borderRadius={5}
              isGrouped={true}
            />
          </StyledInnerContentCard>
        </Grid>

        {/* Horizontal Grouped Bar Column - Apex */}
        <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Apex - Horizontal Grouped Bar Chart</Heading>
            </Box>
            <StackChart
              data={[
                { x: 'Jan', Total: 10, Cancelled: 20, 'No  Show': 30 },
                { x: 'Feb', Total: 15, Cancelled: 25, 'No  Show': 35 },
                { x: 'Mar', Total: 20, Cancelled: 30, 'No  Show': 40 },
                { x: 'Apr', Total: 25, Cancelled: 35, 'No  Show': 45 },
                { x: 'May', Total: 65, Cancelled: 75, 'No  Show': 85 },
                { x: 'Jun', Total: 35, Cancelled: 45, 'No  Show': 55 },
                { x: 'Jul', Total: 40, Cancelled: 50, 'No  Show': 60 },
                { x: 'Aug', Total: 45, Cancelled: 55, 'No  Show': 65 },
                { x: 'Sep', Total: 50, Cancelled: 60, 'No  Show': 70 },
                { x: 'Oct', Total: 55, Cancelled: 65, 'No  Show': 75 },
                { x: 'Nov', Total: 60, Cancelled: 70, 'No  Show': 80 },
                { x: 'Dec', Total: 30, Cancelled: 40, 'No  Show': 50 },
              ]}
              xKey={'x'}
              yKeys={['Total', 'Cancelled', 'No  Show']}
              chartColors={[
                colors.primary[500],
                colors.error[500],
                colors.secondary[500],
              ]}
              borderRadius={5}
              isGrouped={true}
              isHorizontal={true}
            />
          </StyledInnerContentCard>
        </Grid>

        {/* Line Chart - Apex */}
        <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Apex - Line Chart</Heading>
            </Box>
            <LineChart
              data={[
                { x: 'Jan', y: 10, z: 20, a: 30, b: 14 },
                { x: 'Feb', y: 15, z: 25, a: 35, b: 46 },
                { x: 'Mar', y: 20, z: 30, a: 40, b: 32 },
                { x: 'Apr', y: 25, z: 35, a: 45, b: 46 },
                { x: 'May', y: 65, z: 75, a: 85, b: 87 },
                { x: 'Jun', y: 35, z: 45, a: 55, b: 46 },
                { x: 'Jul', y: 40, z: 50, a: 60, b: 47 },
                { x: 'Aug', y: 45, z: 55, a: 65, b: 8 },
                { x: 'Sep', y: 50, z: 60, a: 70, b: 46 },
                { x: 'Oct', y: 55, z: 65, a: 75, b: 13 },
                { x: 'Nov', y: 60, z: 70, a: 80, b: 41 },
                { x: 'Dec', y: 30, z: 40, a: 50, b: 46 },
              ]}
              xKey="x"
              yKeys={['y', 'z', 'a', 'b']}
              height={400}
              curveType="smooth"
              strokeWidth={2}
              markerSize={6}
              gridLineColor="#ccc"
              tooltip={{
                enabled: true,
                style: { fontSize: '14px' },
                marker: { show: true },
              }}
            />
          </StyledInnerContentCard>
        </Grid>

        {/* Area Chart - Apex */}
        <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Apex - Area Chart</Heading>
            </Box>
            <LineChart
              data={[
                { x: 'Jan', y: 10, z: 20, a: 30, b: 14 },
                { x: 'Feb', y: 15, z: 25, a: 35, b: 46 },
                { x: 'Mar', y: 20, z: 30, a: 40, b: 32 },
                { x: 'Apr', y: 25, z: 35, a: 45, b: 46 },
                { x: 'May', y: 65, z: 75, a: 85, b: 87 },
                { x: 'Jun', y: 35, z: 45, a: 55, b: 46 },
                { x: 'Jul', y: 40, z: 50, a: 60, b: 47 },
                { x: 'Aug', y: 45, z: 55, a: 65, b: 8 },
                { x: 'Sep', y: 50, z: 60, a: 70, b: 46 },
                { x: 'Oct', y: 55, z: 65, a: 75, b: 13 },
                { x: 'Nov', y: 60, z: 70, a: 80, b: 41 },
                { x: 'Dec', y: 30, z: 40, a: 50, b: 46 },
              ]}
              xKey="x"
              yKeys={['y', 'z', 'a', 'b']}
              height={400}
              curveType="smooth"
              strokeWidth={2}
              markerSize={6}
              gridLineColor="#ccc"
              tooltip={{
                enabled: true,
                style: { fontSize: '14px' },
                marker: { show: true },
              }}
              chartType="area"
            />
          </StyledInnerContentCard>
        </Grid>

        {/* Pie Chart - Apex */}
        <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Apex - Pie Chart</Heading>
            </Box>
            <PieChart
              data={[
                {
                  x: 'Department A',
                  y: 10,
                },
                {
                  x: 'Department B',
                  y: 20,
                },
                {
                  x: 'Department C',
                  y: 30,
                },
                {
                  x: 'Department D',
                  y: 40,
                },
                {
                  x: 'E',
                  y: 50,
                },
              ]}
              height={450}
              borderRadius={10}
            />
          </StyledInnerContentCard>
        </Grid>

        {/* Pie Chart - Nivo */}
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Nivo - Pie Chart</Heading>
            </Box>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NivoPie
                data={[
                  {
                    id: 'c',
                    label: 'c',
                    value: 586,
                    color: 'hsl(232, 70%, 50%)',
                  },
                  {
                    id: 'ruby',
                    label: 'ruby',
                    value: 180,
                    color: 'hsl(257, 70%, 50%)',
                  },
                  {
                    id: 'sass',
                    label: 'sass',
                    value: 523,
                    color: 'hsl(217, 70%, 50%)',
                  },
                  {
                    id: 'java',
                    label: 'java',
                    value: 75,
                    color: 'hsl(46, 70%, 50%)',
                  },
                  {
                    id: 'scala',
                    label: 'scala',
                    value: 505,
                    color: 'hsl(166, 70%, 50%)',
                  },
                ]}
                chartType="pie"
                showLegend={false}
                height={500}
              />
            </div>
          </StyledInnerContentCard>
        </Grid>

        {/* Donut Chart - Apex */}
        <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Apex - Donut Chart</Heading>
            </Box>
            <PieChart
              data={[
                {
                  x: 'Department A',
                  y: 10,
                },
                {
                  x: 'Department B',
                  y: 20,
                },
                {
                  x: 'Department C',
                  y: 30,
                },
                {
                  x: 'Department D',
                  y: 40,
                },
                {
                  x: 'E',
                  y: 50,
                },
              ]}
              height={450}
              borderRadius={10}
              chartType="donut"
            />
          </StyledInnerContentCard>
        </Grid>

        {/* Donut Chart - Nivo */}
        <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Nivo - Donut Chart</Heading>
            </Box>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NivoPie
                data={[
                  {
                    id: 'c',
                    label: 'c',
                    value: 586,
                    color: 'hsl(232, 70%, 50%)',
                  },
                  {
                    id: 'ruby',
                    label: 'ruby',
                    value: 180,
                    color: 'hsl(257, 70%, 50%)',
                  },
                  {
                    id: 'sass',
                    label: 'sass',
                    value: 523,
                    color: 'hsl(217, 70%, 50%)',
                  },
                  {
                    id: 'java',
                    label: 'java',
                    value: 75,
                    color: 'hsl(46, 70%, 50%)',
                  },
                  {
                    id: 'scala',
                    label: 'scala',
                    value: 505,
                    color: 'hsl(166, 70%, 50%)',
                  },
                ]}
                chartType="donut"
                showLegend={false}
                height={500}
              />
            </div>
          </StyledInnerContentCard>
        </Grid>

        {/* Tree Map- Apex*/}
        <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Apex - Tree Map</Heading>
            </Box>
            <Suspense fallback={<div>Loading...</div>}>
              <TreeMap
                data={dashboardReport.consultationsByDoctor}
                height={'400px'}
                xKey="doctor"
                yKey="visitsByDoctor"
              />
            </Suspense>
          </StyledInnerContentCard>
        </Grid>

        {/* Tree Map - Nivo */}
        {/* <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Nivo - Tree Map</Heading>
            </Box>
            <Suspense fallback={<div>Loading...</div>}>
              <NivoTreeMap
                data={{
                  name: 'nivo',
                  color: 'hsl(315, 70%, 50%)',
                  children: dashboardReport.consultationsByDoctor,
                }}
                height={'400px'}
                identity="doctor"
                value="visitsByDoctor"
              />
            </Suspense>
          </StyledInnerContentCard>
        </Grid> */}

        {/* Tree Map - Nivo */}
        {/* <Grid item xs={12} md={12}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Apex - Scatter Plot</Heading>
            </Box>
            <ScatterChart
              data={dashboardReport.consultationsByDoctor}
              height={450}
              chartType="scatter"
              xKey="doctor"
              yKey="visitsByDoctor"
            />
          </StyledInnerContentCard>
        </Grid> */}

        {/* Bar Chart - Nivo */}
        {/* <Grid item xs={12} md={6}>
          <StyledInnerContentCard>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Heading>Nivo - Bar Chart</Heading>
            </Box>
            <NivoBarChart
              data={[
                {
                  countByDepartment: 22,
                  department: 'department A',
                },
                {
                  countByDepartment: 74,
                  department: 'department B',
                },
                {
                  countByDepartment: 123,
                  department: 'department C',
                },
                {
                  countByDepartment: 81,
                  department: 'Class D',
                },
                {
                  countByDepartment: 45,
                  department: 'Class E',
                },
                {
                  countByDepartment: 23,
                  department: 'Class F',
                },
                {
                  countByDepartment: 12,
                  department: 'Class G',
                },
                {
                  countByDepartment: 22,
                  department: 'department A',
                },
                {
                  countByDepartment: 74,
                  department: 'department B',
                },
                {
                  countByDepartment: 123,
                  department: 'department C',
                },
                {
                  countByDepartment: 81,
                  department: 'Class D',
                },
                {
                  countByDepartment: 45,
                  department: 'Class E',
                },
                {
                  countByDepartment: 23,
                  department: 'Class F',
                },
                {
                  countByDepartment: 12,
                  department: 'Class G',
                },
              ]}
              isHorizontal={false}
              height={400}
              xKey="department"
              yKey="countByDepartment"
            />
          </StyledInnerContentCard>
        </Grid> */}
      </Grid>
    </StyledContentCard>
  );
};

export default ChartsUsage;
